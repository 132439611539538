import Sidebar from "../sidebar";
import Header from "../header";
import styles from "./layout.module.scss";
import { VariantEnum } from "@/types/enum";

interface ILayoutProps {
  children: JSX.Element | JSX.Element[];
  variant?: VariantEnum;
}

const Layout = ({ children, variant }: ILayoutProps) => (
  <div className={styles.layout}>
    <Header variant={variant} />
    <div className={styles.wrapper}>
      <aside>
        <Sidebar />
      </aside>
      <div>{children}</div>
    </div>
  </div>
);
export { Layout };
