import { Tooltip, TooltipProps } from "@mui/material";

import { Icon } from "../icon";
import TooltipIcon from "../../assets/icons/tooltip.svg";

const LabelTooltip = (props: Omit<TooltipProps, "children">) => (
  <Tooltip {...props}>
    <Icon path={TooltipIcon} />
  </Tooltip>
);

export { LabelTooltip };
