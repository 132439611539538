import { UsefulContacts } from "../../../types/contacts";
import { IApiModule } from "../types";

export default {
  createCompanyContact: (data: UsefulContacts.CreateContactBody) => ({
    method: "POST",
    url: "api/user/useful-company-contact",
    data
  }),

  getCompanyContacts: () => ({
    method: "GET",
    url: "api/user/useful-company-contact",
  }),

  updateCompanyContacts: (data: UsefulContacts.UpdateContactBody) => ({
    method: "PATCH",
    url: "api/user/useful-company-contact",
    data
  }),
  
  updateMyContacts: (data: UsefulContacts.UpdateMyContactsBody) => ({
    method: "PATCH",
    url: "api/user/company/update-data",
    data
  }),

  deleteCompanyContacts: (Id: number) => ({
    method: "DELETE",
    url: "api/user/useful-company-contact",
    data: { Id }
  }),

} as IApiModule;
