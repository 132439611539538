import { Content } from "../../components/content";
import { Layout } from "../../components/layout";
import { CreateOccComponent } from "../../features/createOcc";

const CreateOcc = () => {
  return (
    <>
      <Layout>
        <Content>
          <CreateOccComponent />
        </Content>
      </Layout>
    </>
  );
};

export default CreateOcc;
