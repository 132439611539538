import { Box, Grid, IconButton, Link, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { api } from "../../../services/api";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { cutFileName } from "../../../helpers/common";
import { createOccMode } from "../../../consts/state";
import { useCurrentMode } from "../hooks/useCurrentMode";
import { useAppDispatch } from "../../../app/store";
import { deleteCheckQuestion } from "../../../app/store/slices/createOccOld";

interface IQuestionItem {
  data: any;
  index: number;
  onEdit?: (id: number) => void;
  deleteCallback?: () => void;
  quorums?: any;
  setValueStorage?: any;
  questionsLocalStorage?: any;
}

const QuestionItem = ({
  data,
  index,
  onEdit,
  deleteCallback,
  quorums,
  setValueStorage,
  questionsLocalStorage,
}: IQuestionItem) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState<number>(0);
  const dispatch = useAppDispatch();

  const mode = useCurrentMode();
  const isEditMode = mode === createOccMode.edit;

  const getQuorum = (id: number) => {
    // @ts-ignore
    const quorum: any = quorums?.data?.find((el) => el.id === id);
    return quorum ? quorum.name : "";
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(data.Id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(0);
  };

  const editQuestion = () => {
    setOpen(0);
    console.log("onEdit", data);
    // Если data.Id нету, то передаем data.Order - у новых вопросов нету своего Id
    if (isEditMode && data.Id) {
      // @ts-ignore
      onEdit(data.Id, "Id");
    }

    if (isEditMode && data.Order) {
      // @ts-ignore
      onEdit(data.Order, "Order");
    }

    if (!isEditMode) {
      // @ts-ignore
      onEdit(data.Id);
    }
    setAnchorEl(null);
  };

  // Рефакторинг
  const deleteQuestion = async () => {
    try {
      if (isEditMode) {
        setValueStorage(questionsLocalStorage, data.Id ?? data.Order);

        deleteCallback && deleteCallback();
        return;
      }

      await api.occ.deleteQuestions(data.Id);
      dispatch(deleteCheckQuestion(data.Title));
      deleteCallback && deleteCallback();
    } catch (err: any) {
      console.error(err);
    }
  };

  const showEditBlock = onEdit || deleteCallback;

  return (
    <Grid item xs={12} key={data.Id}>
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "16px",
          padding: "16px",
          position: "relative",
        }}
      >
        {showEditBlock && (
          <>
            <IconButton
              aria-label={`edit_${data.Id}`}
              id={`edit_${data.Id}`}
              aria-haspopup="true"
              onClick={(e) => handleClick(e)}
              sx={{
                position: "absolute",
                right: "12px",
                top: "12px",
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id={`submenu_edit_${data.Id}`}
              anchorEl={anchorEl}
              open={open === data.Id}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {!!onEdit && <MenuItem onClick={() => editQuestion()}>Редактировать</MenuItem>}
              {!!deleteCallback && <MenuItem onClick={() => deleteQuestion()}>Удалить</MenuItem>}
            </Menu>
          </>
        )}
        <Typography style={{ marginBottom: "8px" }} fontFamily="GolosMedium">
          Вопрос {index + 1}.
        </Typography>
        <Typography fontSize={"14px"} style={{ marginBottom: "16px" }} color={"rgba(0, 0, 0, 0.54)"}>
          Кворум по вопросу повестки: {getQuorum(data.QuorumId)}
        </Typography>
        <Typography mb={1} fontFamily="GolosMedium" overflow="hidden">
          {data.Title}
        </Typography>
        <Typography mb={2} overflow="hidden">
          {data.Value}
        </Typography>
        <Grid rowSpacing={2} columnSpacing={2} mt={2}>
          {!!data?.Files?.length &&
            data.Files.map((file: any) => (
              <Grid item xs={4} mt={1}>
                <Link href={file?.URL} target={"_blank"} underline="none" color="inherit">
                  <Box
                    sx={{
                      minWidth: "230px",
                      border: "1px solid rgba(0, 0, 0, 0.1)",
                      borderRadius: "10px",
                      padding: "12px",
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      fontSize: "14px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minWidth: "32px",
                        height: "32px",
                        borderRadius: "90px",
                        backgroundColor: "#EFEDEA",
                      }}
                    >
                      <Typography variant="subtitle2" fontSize="10px" sx={{ textTransform: "uppercase" }}>
                        {file?.Extension}
                      </Typography>
                    </Box>
                    <Box width="100%" pl="12px">
                      <Box>{cutFileName(file?.Name + "." + file?.Extension, 17, 7)}</Box>
                      <Box color={"rgba(0, 0, 0, 0.54)"}>{(file?.Size / 1048576).toFixed(2)} MB</Box>
                    </Box>
                  </Box>
                </Link>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Grid>
  );
};

export { QuestionItem };
