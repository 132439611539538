import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { statuses } from "../../../consts/statuses";
import { api } from "../../../services/api";
import { DetailedViewType } from "../../../types/registry";

type ModeType = "view" | "delete" | "edit";

interface IModalState {
  changeArea: {
    open: boolean;
  };
  detailedView: {
    open: boolean;
    data: null | DetailedViewType;
    loading: boolean;
    error: "";
    fetchStatus: typeof statuses[keyof typeof statuses] | "";
    type: ModeType;
    deleteApartment: {
      loading: boolean;
      error: string;
    };
  };
  editAddView: {
    open: boolean;
    data: null | DetailedViewType;
    newData: any;
    included: any;
    loading: boolean;
    type: string;
    error: "";
    fetchStatus: typeof statuses[keyof typeof statuses] | "";
  };
  addView: {
    open: boolean;
    loading: boolean;
    data: any;
    type: string;
    error: "";
    fetchStatus: typeof statuses[keyof typeof statuses] | "";
  };
  noticeModal: {
    open: boolean;
  };
  createOcc: {
    open: boolean;
  };
  selectAddress: {
    open: boolean;
  };
  errorsModal: {
    open: boolean;
  };
  addTemplateGroup: {
    open: boolean;
  };
  editTemplateGroup: {
    open: boolean;
  };
  deleteTemplateGroup: {
    open: boolean;
  };
  addTemplateQuestion: {
    open: boolean;
  };
  editTemplateQuestion: {
    open: boolean;
  };
  deleteTemplateQuestion: {
    open: boolean;
  };
}

const initialState: IModalState = {
  changeArea: {
    open: false,
  },
  detailedView: {
    open: false,
    data: null,
    loading: false,
    error: "",
    fetchStatus: "",
    type: "view",
    deleteApartment: {
      loading: false,
      error: "",
    },
  },
  editAddView: {
    open: false,
    data: null,
    newData: [],
    included: [],
    loading: false,
    type: "edit",
    error: "",
    fetchStatus: "",
  },
  addView: {
    open: false,
    loading: false,
    data: [],
    type: "add",
    error: "",
    fetchStatus: "",
  },
  noticeModal: {
    open: false,
  },
  createOcc: {
    open: false,
  },
  selectAddress: {
    open: false,
  },
  errorsModal: {
    open: false,
  },
  addTemplateGroup: {
    open: false,
  },
  editTemplateGroup: {
    open: false,
  },
  deleteTemplateGroup: {
    open: false,
  },
  addTemplateQuestion: {
    open: false,
  },
  editTemplateQuestion: {
    open: false,
  },
  deleteTemplateQuestion: {
    open: false,
  },
};

const fetchGetDetailedView = createAsyncThunk(
  "house/apartment/get-by-id",
  async (
    { Id, type, HouseFiasId }: { Id: number; type: ModeType; HouseFiasId: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setDetailedViewModal(true));

      const response = await api.house.getApartmentById({
        Id,
        HouseFiasId,
      });

      dispatch(setDetailedViewType(type));

      return response.data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchDeleteApartment = createAsyncThunk(
  "house/apartment:delete",
  async (
    {
      Id,
      HouseFiasId,
      Number,
      HouseId,
      callback,
    }: {
      HouseFiasId: string;
      Id: number | undefined;
      Number: string;
      HouseId: number;
      callback: () => void;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setDetailedViewModal(true));

      const response = await api.house.deleteHouseApartment({
        HouseFiasId,
        Id,
        Number,
        HouseId,
      });

      callback();

      return response.data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchUpdateApartment = createAsyncThunk(
  "house/apartment/update",
  async (
    { HouseFiasId, HouseId, Id, Number, Area, Type, CadastralNumber, CreatedOwners, UpdatedOwners, DeletedOwners }: any,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await api.house.updateHouseApartment({
        HouseFiasId,
        HouseId,
        Id,
        Number,
        Area,
        Type,
        CadastralNumber,
        CreatedOwners,
        UpdatedOwners,
        DeletedOwners,
      });

      return response.data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchGetEditView = createAsyncThunk(
  "house/apartment/get-by-id:edit",
  async ({ Id, HouseFiasId }: { Id: number; HouseFiasId: any }, { rejectWithValue }) => {
    try {
      const response = await api.house.getApartmentById({
        Id,
        HouseFiasId,
      });

      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchAddApartment = createAsyncThunk("house/apartment/add", async (data: any, { rejectWithValue }) => {
  try {
    const response = await api.house.addHouseApartment(data);

    return response.data.Data;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data?.Data?.Message);
  }
});

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setChangeAreaModal: (state, action) => {
      state.changeArea.open = action.payload;
    },
    setDetailedViewModal: (state, action) => {
      state.detailedView.open = action.payload;
    },
    setEditAddViewModal: (state, action) => {
      state.editAddView.open = action.payload;
    },
    setAddViewModal: (state, action) => {
      state.addView.open = action.payload;

      if (state.addView.open) {
        state.addView.error = "";
      }
    },
    setNoticeModal: (state, action) => {
      state.noticeModal.open = action.payload;

      // if (!state.noticeModal.open) {
      // state.addView.data = null;
      // state.editAddView.newData = [];
      // }
    },
    setErrorsModal: (state, action) => {
      state.errorsModal.open = action.payload;
    },
    setCreateOccModal: (state, action) => {
      state.createOcc.open = action.payload;
    },
    setDetailedViewData: (state, action) => {
      state.detailedView.data = action.payload;
    },
    setDetailedViewType: (state, action: { type: string; payload: ModeType }) => {
      state.detailedView.type = action.payload;
    },
    setSelectAddressModal: (state: IModalState, action) => {
      state.selectAddress.open = action.payload;
    },
    setAddTemplateGroupModal: (state: IModalState, action: PayloadAction<boolean>) => {
      state.addTemplateGroup.open = action.payload;
    },
    setEditTemplateGroupModal: (state: IModalState, action: PayloadAction<boolean>) => {
      state.editTemplateGroup.open = action.payload;
    },
    setDeleteGroupModal: (state: IModalState, action: PayloadAction<boolean>) => {
      state.deleteTemplateGroup.open = action.payload;
    },
    setAddTemplateQuestionModal: (state: IModalState, action: PayloadAction<boolean>) => {
      state.addTemplateQuestion.open = action.payload;
    },
    setEditTemplateQuestionModal: (state: IModalState, action: PayloadAction<boolean>) => {
      state.editTemplateQuestion.open = action.payload;
    },
    setDeleteTemplateQuestionModal: (state: IModalState, action: PayloadAction<boolean>) => {
      state.deleteTemplateQuestion.open = action.payload;
    },
    resetError: (state) => {
      state.detailedView.deleteApartment.error = "";
    },
    resetModalState: (state) => {
      state.editAddView = initialState.editAddView;
      state.addView = initialState.addView;
      state.detailedView = initialState.detailedView;
    },
  },
  extraReducers: (builder) => {
    // @fetchGetDetailedView - request
    builder.addCase(fetchGetDetailedView.pending, (state: any) => {
      state.detailedView.loading = true;
      state.detailedView.fetchStatus = statuses.pending;
    });
    builder.addCase(fetchGetDetailedView.fulfilled, (state: any, action) => {
      state.detailedView.loading = false;
      state.detailedView.fetchStatus = statuses.success;
      state.detailedView.data = action.payload;
    });
    builder.addCase(fetchGetDetailedView.rejected, (state: any, action) => {
      state.detailedView.loading = false;
      state.detailedView.fetchStatus = statuses.reject;

      if (action.payload) {
        state.detailedView.error = action.payload;
      }
    });

    // @fetchGetEditView - request
    builder.addCase(fetchGetEditView.pending, (state: any) => {
      state.editAddView.loading = true;
      state.editAddView.fetchStatus = statuses.pending;
      state.editAddView.error = "";
    });
    builder.addCase(fetchGetEditView.fulfilled, (state: any, action) => {
      state.editAddView.loading = false;
      state.editAddView.fetchStatus = statuses.success;
      state.editAddView.data = action.payload.Data;
      state.editAddView.included = action.payload.Included;

      if (action.payload) {
        state.editAddView.open = true;
      }
    });
    builder.addCase(fetchGetEditView.rejected, (state: any, action) => {
      state.editAddView.loading = false;
      state.editAddView.fetchStatus = statuses.reject;

      if (action.payload) {
        state.editAddView.error = action.payload;
      }
    });

    // @fetchDeleteApartment - request
    builder.addCase(fetchDeleteApartment.pending, (state: any) => {
      state.detailedView.deleteApartment.loading = true;
    });
    builder.addCase(fetchDeleteApartment.fulfilled, (state: any, action) => {
      state.detailedView.deleteApartment.loading = false;
    });
    builder.addCase(fetchDeleteApartment.rejected, (state: any, action) => {
      state.detailedView.deleteApartment.loading = false;

      if (action.payload) {
        state.detailedView.deleteApartment.error = action.payload;
      }
    });

    // @fetchUpdateApartment - request
    builder.addCase(fetchUpdateApartment.pending, (state: any) => {
      state.editAddView.loading = true;
    });
    builder.addCase(fetchUpdateApartment.fulfilled, (state: any, action) => {
      state.editAddView.loading = false;
      state.editAddView.newData = action.payload;

      if (action.payload) {
        state.editAddView.open = false;
        state.noticeModal.open = true;
      }
    });
    builder.addCase(fetchUpdateApartment.rejected, (state: any, action) => {
      state.editAddView.loading = false;

      if (action.payload) {
        state.editAddView.error = action.payload;
      }
    });

    // @fetchAddApartment - request
    builder.addCase(fetchAddApartment.pending, (state: any) => {
      state.addView.loading = true;
    });
    builder.addCase(fetchAddApartment.fulfilled, (state: any, action) => {
      state.addView.loading = false;
      state.addView.data = action.payload;

      if (action.payload) {
        state.addView.open = false;
        state.noticeModal.open = true;
      }
    });
    builder.addCase(fetchAddApartment.rejected, (state: any, action) => {
      state.addView.loading = false;

      if (action.payload) {
        state.addView.error = action.payload;
      }
    });
  },
});

export { fetchGetDetailedView, fetchDeleteApartment, fetchUpdateApartment, fetchGetEditView, fetchAddApartment };
export default modalSlice.reducer;
export const {
  setChangeAreaModal,
  setDetailedViewModal,
  setEditAddViewModal,
  setAddViewModal,
  setNoticeModal,
  setCreateOccModal,
  setSelectAddressModal,
  setDetailedViewData,
  setDetailedViewType,
  setErrorsModal,
  setAddTemplateGroupModal,
  setEditTemplateGroupModal,
  setDeleteGroupModal,
  setAddTemplateQuestionModal,
  setEditTemplateQuestionModal,
  setDeleteTemplateQuestionModal,
  resetError,
  resetModalState,
} = modalSlice.actions;
