import { Middleware } from "redux";
import { setModeOcc } from "../slices/createOccOld";

const occModeMiddleware: Middleware = (store) => (next: any) => (action: any) => {
  if (setModeOcc.match(action)) {
    const mode = store.getState().createOcc.mode;

    window.localStorage.setItem("modeOcc", action.payload);
  }

  return next(action);
};

export { occModeMiddleware };
