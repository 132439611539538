import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Alert, OutlinedInput, InputLabel, Grid, Box, FormHelperText, Tooltip, Typography } from "@mui/material";
import ru from "date-fns/locale/ru";
import { useForm, Controller } from "react-hook-form";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { MeettingType } from "../components/meetingType";
import { MeettingFroms } from "../components/meetingForms";
import { Location } from "../components/location";
import { HouseAddress } from "../components/houseAddress";
import { api } from "../../../services/api";
import dayjs from "dayjs";
import { Spinner } from "../../../components/spinner";
import { LabelTooltip } from "../../../components/tooltip";
import styles from "../create.module.scss";
import {
  fetchLocationDictionary,
  fetchMeetingFormDictionary,
  fetchTypeDictionary,
  setOccId,
} from "../../../app/store/slices/createOccOld";
import { RootState, useAppDispatch } from "../../../app/store";
import { createOccMode } from "../../../consts/state";
import { useCurrentMode } from "../hooks/useCurrentMode";

registerLocale("ru", ru);

interface Props {
  refForm: any;
  toNextStep: (id?: number) => void;
}

type CreateOccFieldsType = {
  Number?: number;
  TypeId?: number;
  FormId?: number;
  StartTimestamp?: number;
  EndTimestamp?: number;
  ProcedureForMakingWrittenDecisions?: string;
  ProcedureForFamiliarizationWithMaterialsOfOCC?: string;
  FiasId?: string;
  PublicationTimestamp?: number;
  OccId?: unknown;
  LocationId?: number;
};

const renderDayContents = (
  day: number,
  date: Date | undefined,
  range: Array<Date | undefined> | undefined,
  tooltipText: string
) => {
  let isTooltip = false;

  if (date && range && range[0] && date < range[0]) {
    isTooltip = true;
  }

  if (date && range && range[1] && date > range[1]) {
    isTooltip = true;
  }

  if (isTooltip) {
    return (
      <Tooltip title={tooltipText}>
        <span title={tooltipText}>{day}</span>
      </Tooltip>
    );
  }

  return day;
};

// TODO: Отрефакторить компонент и оптимизировать
// Проблема с рендерингом select components
// Нету оптимизации
const Step1 = ({ refForm, toNextStep }: Props) => {
  const { state: occId } = useLocation();
  const [load, setLoad] = useState(occId);
  const [dataOcc, setDataOcc] = useState<CreateOccFieldsType>({});
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [startMinDate, setStartMinDate] = useState<Date | undefined>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [endMaxMinDate, setEndMaxMinDate] = useState<Array<Date | undefined>>([
    new Date(dayjs().add(17, "day").toString()),
    new Date(dayjs().add(70, "day").toString()),
  ]);
  const [publishDate, setPublishDate] = useState<Date | null>(null);
  const [dateFormat, setDateFormat] = useState<string>("dd.MM.yyyy, HH:mm");
  const [error, setError] = useState("");
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    clearErrors,
  } = useForm();

  const { loading: loadingTypes, data: typeDictionary } = useSelector(
    (state: RootState) => state.createOccOld.typeDictionary
  );
  const { loading: loadingLocation, data: locationDictionary } = useSelector(
    (state: RootState) => state.createOccOld.locationDictionary
  );
  const { loading: loadingMeeting, data: meetingDictionary } = useSelector(
    (state: RootState) => state.createOccOld.meetingDictionary
  );

  const dispatch = useAppDispatch();
  const mode = useCurrentMode();

  const address = localStorage.getItem("address");
  const statusId = localStorage.getItem("statusId");

  const storageData = localStorage.getItem("step1") !== null ? localStorage.getItem("step1") : null;
  let FiasId = "";

  if (address) {
    FiasId = JSON.parse(address).FiasId;
  }

  const requiredError = Object.keys(errors).some(
    /* @ts-ignore */
    (key: string) => errors[key].type === "required"
  );

  setValue("FiasId", FiasId);

  const setOccData = (data: any) => {
    const formField: { [key: string]: string } = {
      Number: "Number",
      TypeId: "TypeId",
      FormId: "FormId",
      LocationId: "LocationId",
      LocationName: "LocationName",
      StartTimestamp: "StartTime",
      EndTimestamp: "EndTime",
      ProcedureForMakingWrittenDecisions: "ProcedureForMakingWrittenDecisions",
      ProcedureForFamiliarizationWithMaterialsOfOCC: "ProcedureForFamiliarizationWithMaterialsOfOCC",
      FiasId: "FiasId",
      PublicationTimestamp: "PublicationTime",
    };

    if (data) {
      for (const field in formField) {
        setValue(field, data[formField[field]]);
      }
      setDataOcc(data);

      if (mode === createOccMode.edit && storageData) {
        setStartDate(new Date(data.StartTimestamp * 1000));
        setEndDate(new Date(data.EndTimestamp * 1000));
        setPublishDate(new Date(data.PublicationTimestamp * 1000));
        setValue("StartTimestamp", data.StartTimestamp);
        setValue("EndTimestamp", data.EndTimestamp);
        setValue("PublicationTimestamp", data.PublicationTimestamp);
      } else {
        setStartDate(new Date(data.StartTime * 1000));
        setEndDate(new Date(data.EndTime * 1000));
        setPublishDate(new Date(data.PublicationTime * 1000));
      }
    }
  };

  const createOcc = async (data: CreateOccFieldsType) => {
    setError("");

    if (mode === createOccMode.edit) {
      toNextStep();
      localStorage.setItem("step1", JSON.stringify({ OccId: occId, ...data }));
      return;
    }

    try {
      if (occId) {
        data.OccId = occId;
      }
      const response = await api.occ.createOcc(data);
      dispatch(setOccId(response.data.Data));
      toNextStep(response.data.Data);
    } catch (err: any) {
      setError(err.response.data.Data.Message);
      return false;
    }
  };

  const onCloseError = () => {
    setError("");
  };

  const handlePublishDate = (date: Date) => {
    clearErrors("StartTimestamp");
    clearErrors("EndTimestamp");

    const minStartDate = new Date(dayjs(date).add(10, "day").toString());
    let _startDate = startDate;

    if ((startDate && startDate < minStartDate) || !startDate) {
      setStartDate(minStartDate);
      setValue("StartTimestamp", minStartDate.getTime() / 1000);
      _startDate = minStartDate;
    }

    const minEndDate = new Date(dayjs(minStartDate).add(7, "day").toString());

    if (_startDate && ((endDate && endDate < minEndDate) || !endDate)) {
      setEndDate(minEndDate);
      setValue("EndTimestamp", minEndDate.getTime() / 1000);
    }

    setStartMinDate(minStartDate);
    setEndMaxMinDate([minEndDate, new Date(dayjs(minEndDate).add(53, "day").toString())]);

    setPublishDate(date);
  };

  const handleStartDate = (date: Date) => {
    let minEndDate = new Date(dayjs(date).add(7, "day").toString());

    if (endDate && endDate < minEndDate) {
      setEndDate(minEndDate);
      setValue("EndTimestamp", minEndDate.getTime() / 1000);
    }

    setEndMaxMinDate([minEndDate, new Date(dayjs(date).add(60, "day").toString())]);
    setStartDate(date);
  };

  const getOccData = async () => {
    try {
      const response = await api.occ.getOccData({ OccId: occId });

      mode === createOccMode.edit && storageData ? setOccData(JSON.parse(storageData)) : setOccData(response.data.Data);

      setLoad(false);
    } catch (err: any) {
      setLoad(false);
    }
  };

  useEffect(() => {
    if (occId) {
      getOccData();
    }

    dispatch(fetchLocationDictionary());
    dispatch(fetchMeetingFormDictionary());
    dispatch(fetchTypeDictionary());
  }, []);

  if (load || loadingTypes || loadingLocation || loadingMeeting) return <Spinner alt={"Загрузка..."} />;

  return (
    <section className={styles.root}>
      <form ref={refForm} onSubmit={handleSubmit(createOcc)}>
        <Grid container rowSpacing={2} columnSpacing={4}>
          <Grid item xs={12} order={0}>
            {error && (
              <Alert severity="error" onClose={onCloseError}>
                {error}
              </Alert>
            )}
            {requiredError && (
              <Alert severity="error" onClose={onCloseError}>
                Все поля обязательны для заполнения
              </Alert>
            )}
          </Grid>
          <Grid item xs={12} order={1}>
            <Typography variant="subtitle1" sx={{ color: "#000", opacity: 1 }}>
              Заполните общие сведения о собрании
            </Typography>
          </Grid>
          <Grid item xs={12} order={mode !== createOccMode.create ? 2 : 11}>
            <InputLabel sx={{ display: "flex", gap: "4px" }}>
              Номер собрания
              <LabelTooltip
                title="Например: ЛО-2022/1-2481. Данный номер будет присвоен итоговому протоколу собрания"
                placement="right"
                arrow
                enterDelay={700}
              />
            </InputLabel>
            <OutlinedInput
              fullWidth
              autoFocus={mode !== createOccMode.create}
              placeholder="Ввести"
              startAdornment={<Typography variant="subtitle2">№</Typography>}
              {...register("Number", {
                required: "Поле не заполнено",
              })}
              error={(errors["Number"]?.type as unknown as string) === "required"}
            />
            {(errors["Number"]?.type as unknown as string) === "required" && (
              <FormHelperText error>{errors["Number"]?.message as unknown as string}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} order={3}>
            <Location
              {...register("LocationId", {
                required: "Поле не заполнено",
              })}
              data={locationDictionary}
              loading={loadingLocation}
              defaultValue={dataOcc?.LocationId}
              error={(errors["LocationId"]?.type as unknown as string) === "required"}
            />
            {(errors["LocationId"]?.type as unknown as string) === "required" && (
              <FormHelperText error>{errors["LocationId"]?.message as unknown as string}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6} order={4}>
            <MeettingType
              {...register("TypeId", {
                required: "Поле не заполнено",
              })}
              data={typeDictionary}
              loading={loadingTypes}
              defaultValue={dataOcc?.TypeId}
              error={(errors["TypeId"]?.type as unknown as string) === "required"}
            />
            {(errors["TypeId"]?.type as unknown as string) === "required" && (
              <FormHelperText error>{errors["TypeId"]?.message as unknown as string}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6} order={5}>
            <MeettingFroms
              {...register("FormId", {
                required: "Поле не заполнено",
              })}
              defaultValue={dataOcc?.FormId}
              data={meetingDictionary}
              loading={loadingMeeting}
              error={(errors["FormId"]?.type as unknown as string) === "required"}
            />
            {(errors["FormId"]?.type as unknown as string) === "required" && (
              <FormHelperText error>{errors["FormId"]?.message as unknown as string}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} order={6}>
            <InputLabel htmlFor="meeting_type" sx={{ display: "flex", gap: "4px" }}>
              Дата публикации сообщения
              <LabelTooltip
                title="Выберите в календаре дату отправки уведомления собственникам для информирования о проведении собрания"
                placement="right"
                arrow
                enterDelay={700}
              />
            </InputLabel>
            <Box>
              <Controller
                control={control}
                name="PublicationTimestamp"
                rules={{ required: "Поле не заполнено" }}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="Выбрать"
                    onChange={(date: Date) => {
                      field.onChange(dayjs(date).unix());
                      handlePublishDate(date);

                      setDateFormat("dd.MM.yyyy, HH:mm");
                    }}
                    onBlur={() => {
                      setDateFormat("dd.MM.yyyy, HH:mm");
                    }}
                    selected={publishDate}
                    showTimeInput
                    locale={ru}
                    dateFormat={dateFormat}
                    customInput={
                      <OutlinedInput
                        fullWidth
                        error={(errors["PublicationTimestamp"]?.type as unknown as string) === "required"}
                      />
                    }
                    minDate={new Date(dayjs().add(1, "day").toString())}
                    disabled={mode === createOccMode.edit && Number(statusId) === 2}
                  />
                )}
              />
            </Box>
            {(errors["PublicationTimestamp"]?.type as unknown as string) === "required" && (
              <FormHelperText error>{errors["PublicationTimestamp"]?.message as unknown as string}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6} order={7}>
            <InputLabel htmlFor="meeting_type" sx={{ display: "flex", gap: "4px" }}>
              Дата старта приема решений
              <LabelTooltip
                title={`Выберите в календаре дату старта голосования. Напоминаем, что с даты публикации сообщения до начала собрания должно пройти 10 полных дней`}
                placement="right"
                arrow
                enterDelay={700}
              />
            </InputLabel>
            <Box>
              <Controller
                control={control}
                name="StartTimestamp"
                rules={{ required: "Поле не заполнено" }}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="Выбрать"
                    onChange={(date: Date) => {
                      field.onChange(dayjs(date).unix());
                      handleStartDate(date);
                      setDateFormat("dd.MM.yyyy, HH:mm");
                    }}
                    selected={startDate}
                    showTimeInput
                    locale="ru"
                    dateFormat={dateFormat}
                    customInput={
                      <OutlinedInput
                        fullWidth
                        error={(errors["StartTimestamp"]?.type as unknown as string) === "required"}
                      />
                    }
                    minDate={publishDate && new Date(dayjs(publishDate).add(10, "day").toString())}
                    disabled={(!publishDate || mode === createOccMode.edit) && Number(statusId) === 2}
                    calendarClassName="datepicker-lokolo"
                    renderDayContents={(day, date) =>
                      renderDayContents(
                        day,
                        date,
                        [startMinDate, undefined],
                        "Должно пройти 10 дней с даты публикации сообщения"
                      )
                    }
                  />
                )}
              />
            </Box>
            {(errors["StartTimestamp"]?.type as unknown as string) === "required" && (
              <FormHelperText error>{errors["StartTimestamp"]?.message as unknown as string}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6} order={8}>
            <InputLabel htmlFor="select" sx={{ display: "flex", gap: "4px" }}>
              Дата окончания приема решений
              <LabelTooltip
                title="Выберите в календаре дату окончания голосования.
Напоминаем, что продолжительность голосования не менее 7 и не более 60 дней"
                placement="right"
                arrow
                enterDelay={700}
              />
            </InputLabel>
            <Box>
              <Controller
                control={control}
                name="EndTimestamp"
                rules={{ required: "Поле не заполнено" }}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="Выбрать"
                    onChange={(date: Date) => {
                      field.onChange(dayjs(date).unix());
                      setEndDate(date);
                      setDateFormat("dd.MM.yyyy, HH:mm");
                    }}
                    selected={endDate}
                    showTimeInput
                    locale="ru"
                    dateFormat={dateFormat}
                    customInput={
                      <OutlinedInput
                        fullWidth
                        error={(errors["EndTimestamp"]?.type as unknown as string) === "required"}
                      />
                    }
                    minDate={new Date(dayjs(startDate).add(7, "day").toString())}
                    maxDate={new Date(dayjs(startDate).add(60, "day").toString())}
                    disabled={(!publishDate || mode === createOccMode.edit) && Number(statusId) === 2}
                    renderDayContents={(day, date) =>
                      renderDayContents(
                        day,
                        date,
                        endMaxMinDate,
                        "Продолжительность голосования не менее 7 и не более 60 дней с даты и времени начала проведения голосования"
                      )
                    }
                  />
                )}
              />
            </Box>
            {(errors["EndTimestamp"]?.type as unknown as string) === "required" && (
              <FormHelperText error>{errors["EndTimestamp"]?.message as unknown as string}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} order={9}>
            <InputLabel htmlFor="select" sx={{ display: "flex", gap: "4px" }}>
              Порядок приема письменных решений
              <LabelTooltip
                title="Укажите адрес и время приема собственников, по которому они смогут проголосовать, если нет возможности проголосовать в электронной форме"
                placement="right"
                arrow
                enterDelay={700}
              />
            </InputLabel>
            <OutlinedInput
              fullWidth
              multiline
              placeholder="Ввести..."
              rows={3}
              {...register("ProcedureForMakingWrittenDecisions", {
                required: "Поле не заполнено",
              })}
              error={(errors["ProcedureForMakingWrittenDecisions"]?.type as unknown as string) === "required"}
            />
            {(errors["ProcedureForMakingWrittenDecisions"]?.type as unknown as string) === "required" && (
              <FormHelperText error>
                {errors["ProcedureForMakingWrittenDecisions"]?.message as unknown as string}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} order={10}>
            <InputLabel htmlFor="select" sx={{ display: "flex", gap: "4px" }}>
              Порядок ознакомления с материалами собрания
              <LabelTooltip
                title="Укажите адрес и время приема собственников, по которому они смогут ознакомиться с материалами собрания, если нет возможности ознакомиться в электронной форме"
                placement="right"
                arrow
                enterDelay={700}
              />
            </InputLabel>
            <OutlinedInput
              fullWidth
              multiline
              placeholder="Ввести..."
              defaultValue={`Порядок ознакомления с информацией и (или) материалами, которые будут представлены на собрании, и место (адрес), где с ними можно ознакомиться лично по адресу _____(укажите адрес). Период ознакомления с _ часов до _ часов ___ (укажите дни недели или календарные дни) номер телефона __(укажите номер) . По звонку информация предоставляется в устной форме, по сообщению информация предоставляется посредством мессенджеров WhatsApp, Viber, Telegram.  По адресу электронной почты ______@______.___(укажите адрес эл. почты) в ответном письме.`}
              rows={7}
              {...register("ProcedureForFamiliarizationWithMaterialsOfOCC", {
                required: "Поле не заполнено",
              })}
              error={
                (errors["ProcedureForFamiliarizationWithMaterialsOfOCC"]?.type as unknown as string) === "required"
              }
            />
            {(errors["ProcedureForFamiliarizationWithMaterialsOfOCC"]?.type as unknown as string) === "required" && (
              <FormHelperText error>
                {errors["ProcedureForFamiliarizationWithMaterialsOfOCC"]?.message as unknown as string}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} order={mode === createOccMode.create ? 2 : 11}>
            <HouseAddress
              {...register("FiasId", {
                required: "Поле не заполнено",
              })}
              defaultValue={dataOcc?.FiasId || FiasId}
              error={(errors["FiasId"]?.type as unknown as string) === "required"}
              disabled={mode === createOccMode.create}
            />
            {(errors["FiasId"]?.type as unknown as string) === "required" && (
              <FormHelperText error>{errors["FiasId"]?.message as unknown as string}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </form>
    </section>
  );
};

export { Step1 };
