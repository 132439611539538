export const formRules = {
  question: {
    title: {
      maxLength: 1000,
    },
    value: {
      maxLength: 5000,
    },
  },
};

export const customErrors = {
  createRegistry:
    "Создание собрания недоступно, так как не добавлен реестр собственников. После добавления реестра собственников функция будет доступна",
};
