import { Middleware } from "redux";
import { setUser } from "../slices/app";
import { setAddress } from "../slices/registry";

const addressMiddleware: Middleware =
  (store: any) => (next: any) => (action: any) => {
    if (setUser.match(action)) {
      const address = window.localStorage.getItem("address");
      const parseAddress = address !== null ? JSON.parse(address) : null;

      store.dispatch(setAddress(parseAddress));
    }

    if (setAddress.match(action)) {
      window.localStorage.setItem("address", JSON.stringify(action.payload));
    }

    return next(action);
  };

export { addressMiddleware };
