import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import CreateOccItemFinishStep from "@/features/CreateOccNew/OccItem/Steps/Preview";
import { OCCV2Author } from "@/types";

const UKCreateOCCPreviewStep: React.FC = () => {
  const { occId, order, author, administrator } = useSelector((state: RootState) => state.createOcc);

  const boxes = useMemo(() => {
    return [
      {
        title: "Порядок собрания",
        content: [
          {
            name: "Номер собрания",
            value: order?.Number ?? "",
          },
          {
            name: "Вид собрания",
            value: order && "TypeName" in order ? order?.TypeName : "",
          },
          {
            name: "Форма собрания",
            value: order && "FormName" in order ? order?.FormName : "",
          },
          {
            name: "Место проведения собрания",
            value: order && "LocationName" in order ? order?.LocationName : "",
          },
          {
            name: "Дата публикации сообщения",
            value:
              order && "PublicationTime" in order
                ? order?.PublicationTime
                  ? new Date(order?.PublicationTime?.parseFromEpochSeconds()).formatByPattern("dd.MM.yyyy")
                  : order?.PublicationDate
                : "",
          },
          {
            name: "Дата старта приема решений",
            value:
              order && "StartTime" in order
                ? order?.StartTime
                  ? new Date(order?.StartTime?.parseFromEpochSeconds()).formatByPattern("dd.MM.yyyy")
                  : order?.StartDate
                : "",
          },
          {
            name: "Дата окончания приема решений",
            value:
              order && "EndTime" in order
                ? order?.EndTime
                  ? new Date(order?.EndTime?.parseFromEpochSeconds()).formatByPattern("dd.MM.yyyy")
                  : order?.EndDate
                : "",
          },
          {
            name: "Адрес дома",
            value: order?.Address ?? "",
          },
          {
            name: "Порядок ознакомления с материалами собрания",
            value:
              order && "ProcedureForFamiliarizationWithMaterialsOfOCC" in order
                ? order.ProcedureForFamiliarizationWithMaterialsOfOCC
                : "",
          },
          {
            name: "Порядок приема письменных решений",
            value:
              order && "ProcedureForMakingWrittenDecisions" in order ? order.ProcedureForMakingWrittenDecisions : "",
          },
        ],
      },
      {
        title: "Инициатор",
        content:
          author?.Type === OCCV2Author.PersonType.ManagementCompany
            ? [
                {
                  name: "Управляющая организация",
                  value: author.Name,
                },
                {
                  name: "Организационно - правовая форма",
                  value: author.UkLegalForm,
                },
                {
                  name: "Реквизиты договора основания на наличие права инициировать общее собрание собственников",
                  value: author.FoundationAgreementDetails ?? "",
                },
                {
                  name: "ОГРН",
                  value: author.UkOGRN ?? "",
                },
                {
                  name: "Контактный телефон",
                  value: author.Phone?.toString().formatPhone() ?? "",
                },
              ]
            : [],
      },
      {
        title: "Администратор",
        content:
          administrator?.Type === OCCV2Author.PersonType.ManagementCompany
            ? [
                {
                  name: "Управляющая организация",
                  value: administrator.Name,
                },
                {
                  name: "Организационно - правовая форма",
                  value: administrator.UkLegalForm ?? administrator.LegalForm ?? "",
                },
                {
                  name: "Реквизиты договора основания на наличие права инициировать общее собрание собственников",
                  value: administrator.FoundationAgreementDetails ?? "",
                },
                {
                  name: "ОГРН",
                  value: administrator.UkOGRN ?? administrator.OGRN ?? "",
                },
                {
                  name: "Контактный телефон",
                  value: administrator.Phone?.toString().formatPhone() ?? "",
                },
              ]
            : [],
      },
    ];
  }, [order, author, administrator]);

  return (
    <div>
      <CreateOccItemFinishStep
        boxes={boxes}
        prevLink={`/create-occ/${occId}/agenda`}
        nextLink={`/create-occ/${occId}/finish`}
      />
    </div>
  );
};

export default UKCreateOCCPreviewStep;
