import Qs from "qs";
import { TemplateQuestions } from "../../../types/template-questions";
import { IApiModule } from "../types";

export default {
  deleteQuestion: ({ id }: { id: number }) => ({
    method: "DELETE",
    url: `api/occ/templates/questions/${id}`,
  }),
  addQuestion: (data: TemplateQuestions.RequestBodyCreate) => ({
    method: "POST",
    url: "api/occ/templates/questions",
    data,
  }),
  getQuestions: ({ GroupId, Title }: TemplateQuestions.RequestListQuestions) => {
    if (GroupId && Title) {
      return {
        method: "GET",
        url: `api/occ/templates/questions?${Qs.stringify({ GroupId, Title })}`,
      };
    }

    return {
      method: "GET",
      url: `api/occ/templates/questions?${Qs.stringify({ GroupId })}`,
    };
  },
  updateQuestions: ({ id, data }: { id: number; data: TemplateQuestions.QuestionBody }) => ({
    method: "PATCH",
    url: `api/occ/templates/questions/${id}`,
    data,
  }),
} as IApiModule;
