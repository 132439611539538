import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { chevronRight24Icon } from "@/consts/icons";
import { Access, Profile } from "@/types";
import { RootState, useAppDispatch } from "@/app/store";
import { activeAddress } from "@/app/store/slices/user";
import { toggleUserDetailsModal } from "@/app/store/slices/houseUsers";
import BaseButton from "@/components/base-button";
import { BaseModal, BaseModalHeader, BaseModalActions } from "@/components/BaseModal";
import UserStatus from "../../UserStatus";
import UserCard from "../../UserCard";
import SetAccessModal from "../SetAccessModal";
import styles from "../styles.module.scss";

const UserDetailsModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const address = useSelector(activeAddress);
  const roles = useSelector((state: RootState) => state.access.roles);
  const company = useSelector((state: RootState) => state.user.company);
  const { isOpen, user } = useSelector((state: RootState) => state.houseUsers.userDetailsModal);

  const containerRef = useRef<HTMLDivElement>(null);
  const [isSetAccessModalShown, setSetAccessModalShown] = useState<boolean>(false);

  const roleFormatted = useMemo(() => {
    if (user?.Role?.Type === Access.RoleKey.Chairman) {
      return "Председатель садоводства";
    }

    if (user?.Role?.Type === Access.RoleKey.Representative) {
      return "Представитель УО";
    }

    const role = roles.find((it) => it.Key === user?.Role?.Type || it.Value === user?.Role?.Type);
    return role?.Value;
  }, [roles, user]);

  const addressFormatted: string = useMemo(() => {
    if (company?.Type === Profile.CompanyType.SNT) {
      return [company?.Name, `участок №${user?.ObjectNumber}`].join(", ");
    } else {
      return [address?.Address, `кв.${user?.ObjectNumber}`].join(", ");
    }
  }, [company, address, user]);

  const subModaRect: { top: number; left: number } = useMemo(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      return { top: rect.top > 32 ? rect.top + 89 : 121, left: rect.left + 260 };
    }
    return { top: 0, left: 0 };
  }, [containerRef.current]);

  const closeModal = () => {
    setSetAccessModalShown(false);
    dispatch(toggleUserDetailsModal({ isOpen: false }));
  };

  return (
    <BaseModal isOpen={isOpen} maxWidth="520px" minWidth="520px">
      <div ref={containerRef}>
        <div className={styles.wrapper}>
          <BaseModalHeader title="Общие данные" subtitle="Расскажем вам про пользователя" onClose={closeModal} />

          <div className={styles.user}>
            {user && <UserCard user={user} />}
            <div>
              <h4 className="headline-h4 sf-text-bold">Адрес:</h4>
              <p className="text--primary sf-text-regular color--text-secondary">{addressFormatted}</p>
            </div>
          </div>

          <div className={styles.details}>
            <div>
              <h4 className="headline-h4 sf-text-bold">Вход через ЕСИА:</h4>
              <p className="text--primary sf-text-regular color--text-secondary">{user?.EsiaExists ? "Да" : "Нет"}</p>
            </div>
            <div>
              <h4 className="headline-h4 sf-text-bold">Есть в моем реестре:</h4>
              <p className="text--primary sf-text-regular color--text-secondary">{user?.InRegistry ? "Да" : "Нет"}</p>
            </div>
            <div>
              <h4 className="headline-h4 sf-text-bold">Статус в приложении:</h4>
              {user?.Ownership && <UserStatus status={user.Ownership} />}
            </div>
            <div>
              <h4 className="headline-h4 sf-text-bold">Дата регистрации в приложении:</h4>
              <p className="text--primary sf-text-regular color--text-secondary">{user?.RegisteredAt}</p>
            </div>
          </div>

          <div className={styles.access} role="button" onClick={() => setSetAccessModalShown(true)}>
            <div>
              <span className={styles.access_icon}>{roleIcon}</span>
              <div className={styles.access_base}>
                <h4 className="headline-h4 sf-text-bold">Настроить доступ</h4>
                <p className="text--primary sf-text-regular color--text-secondary">
                  {roleFormatted ?? "Расширенные права в мобильном приложении"}
                </p>
              </div>
              <span className={styles.access_chevron}>{chevronRight24Icon("#818C99")}</span>
            </div>
          </div>

          <BaseModalActions>
            <BaseButton variant="secondary" onClick={closeModal} style={{ width: "100%" }}>
              Закрыть
            </BaseButton>
          </BaseModalActions>
        </div>

        {isSetAccessModalShown && (
          <SetAccessModal top={subModaRect.top} left={subModaRect.left} onClose={() => setSetAccessModalShown(false)} />
        )}
      </div>
    </BaseModal>
  );
};

export default UserDetailsModal;

const roleIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C12.3788 3 12.7251 3.214 12.8945 3.55279L16.483 10.7298L20.6286 9.07152C21.0001 8.92296 21.4243 9.01003 21.7071 9.29289C21.99 9.57576 22.0771 9.99997 21.9285 10.3714L17.9285 20.3714C17.7766 20.751 17.4089 21 17 21H7.00003C6.59112 21 6.22342 20.751 6.07155 20.3714L2.07155 10.3714C1.92299 9.99997 2.01006 9.57576 2.29292 9.29289C2.57578 9.01003 3 8.92296 3.37142 9.07152L7.5171 10.7298L11.1056 3.55279C11.275 3.214 11.6213 3 12 3ZM12 6.23607L8.89446 12.4472C8.66187 12.9124 8.11153 13.1216 7.62864 12.9285L4.79508 11.7951L7.67706 19H16.323L19.205 11.7951L16.3714 12.9285C15.8885 13.1216 15.3382 12.9124 15.1056 12.4472L12 6.23607Z"
      fill="#2C2D2E"
    />
  </svg>
);
