import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseThunkAPI } from "@reduxjs/toolkit/dist/createAsyncThunk";
import { debounce } from "lodash";
import { HouseUsers } from "@/types/house-users";
import { RootState } from "@/app/store";
import {
  HouseUsersState,
  pushUsers,
  setFilters,
  setPagination,
  setQuery,
  setQueryType,
  setStatuses,
  setUsers,
  toggleLoading,
} from "./";
import { HouseUsersService, SntRegistryService } from "@/services/v2";
import { activeAddress } from "../user";
import { Pagination, Profile } from "@/types";
import { setDetails } from "../snt/registry";

const debouncedHandler = debounce(setSearchQueryDebounced, 300);

export const setSearchQuery = createAsyncThunk(
  "lokolo-users/set-search-query",
  async (query: string, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    if (rootState.houseUsers.query === query) {
      return;
    }

    dispatch(setQuery(query));
    debouncedHandler({ dispatch, getState } as BaseThunkAPI<unknown, unknown>);
  }
);

async function setSearchQueryDebounced({ dispatch, getState }: BaseThunkAPI<unknown, unknown>) {
  await dispatch(fetchUsers() as any).unwrap();
}

export const setSearchQueryType = createAsyncThunk(
  "lokolo-users/set-search-query",
  async (type: HouseUsers.QueryType, { rejectWithValue, getState, dispatch }) => {
    const rootState = getState() as RootState;
    if (rootState.houseUsers.queryType === type) {
      return;
    }

    await dispatch(setQueryType(type));
    if (rootState.houseUsers.query.length > 0) {
      await dispatch(fetchUsers()).unwrap();
    }
  }
);

export const fetchUsers = createAsyncThunk<HouseUsers.User[], undefined>(
  "lokolo-users/fetch-users",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    dispatch(toggleLoading(true));
    dispatch(setUsers([]));
    dispatch(setPagination(null));
    const rootState = getState() as RootState;
    const address = activeAddress(rootState);

    let LokoloAddressId;
    if (rootState.user.company?.Type === Profile.CompanyType.UK && address) {
      LokoloAddressId = address.FiasId;
    }

    if (rootState.user.company?.Type === Profile.CompanyType.SNT && address?.Id) {
      if (rootState.sntRegistry.details?.Id) {
        LokoloAddressId = rootState.sntRegistry.details?.Id;
      } else {
        const sntDetails = await SntRegistryService.getDetails(address?.Id);
        dispatch(setDetails(sntDetails.data.Data));
        LokoloAddressId = sntDetails.data.Data.Id;
      }
    }

    if (!LokoloAddressId || !address?.Id) {
      throw Error("no address");
    }

    try {
      const { data } = await HouseUsersService.getHouseUsersList({
        Query: {
          Type: rootState.houseUsers.queryType,
          Value: rootState.houseUsers.query,
        },
        LokoloAddressId: address?.Id,
        Address: LokoloAddressId,
        Esia: rootState.houseUsers.filters.Esia ?? undefined,
        Registry: rootState.houseUsers.filters.Registry ?? undefined,
        Ownership: rootState.houseUsers.filters.Ownership ?? undefined,
      });
      await dispatch(setUsers(data.Data));
      await dispatch(setPagination(data.Page));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    } finally {
      dispatch(toggleLoading(false));
    }
  }
);

export const loadMoreUsers = createAsyncThunk<Pagination, undefined>(
  "lokolo-users/load-more-users",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const rootState = getState() as RootState;
    const address = activeAddress(rootState);

    let LokoloAddressId;
    if (rootState.user.company?.Type === Profile.CompanyType.UK && address) {
      LokoloAddressId = address.FiasId;
    }

    if (rootState.user.company?.Type === Profile.CompanyType.SNT && address?.Id) {
      if (rootState.sntRegistry.details?.Id) {
        LokoloAddressId = rootState.sntRegistry.details?.Id;
      } else {
        const sntDetails = await SntRegistryService.getDetails(address?.Id);
        dispatch(setDetails(sntDetails.data.Data));
        LokoloAddressId = sntDetails.data.Data.Id;
      }
    }

    if (!LokoloAddressId || !address?.Id) {
      throw Error("no address");
    }

    try {
      const { data } = await HouseUsersService.getHouseUsersList({
        Query: {
          Type: rootState.houseUsers.queryType,
          Value: rootState.houseUsers.query,
        },
        LokoloAddressId: address?.Id,
        Address: LokoloAddressId,
        Esia: rootState.houseUsers.filters.Esia ?? undefined,
        Registry: rootState.houseUsers.filters.Registry ?? undefined,
        Ownership: rootState.houseUsers.filters.Ownership ?? undefined,
        Page: (rootState.houseUsers.pagination?.Index ?? 0) + 1,
      });
      await dispatch(pushUsers(data.Data));
      await dispatch(setPagination(data.Page));
      return data.Page;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    } finally {
      dispatch(toggleLoading(false));
    }
  }
);

export const getStatuses = createAsyncThunk<HouseUsers.Status[], undefined>(
  "lokolo-users/get-statuses",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const rootState = getState() as RootState;
    if (rootState.houseUsers.statuses.length > 0) {
      return rootState.houseUsers.statuses;
    }

    try {
      const { data } = await HouseUsersService.getOwnershipStatuses();
      await dispatch(setStatuses(data.Data));
      return data.Data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const applySearchFilters = createAsyncThunk<any, HouseUsersState["filters"]>(
  "lokolo-users/apply-search-filters",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    await dispatch(
      setFilters({
        Esia: payload.Esia,
        Registry: payload.Registry,
        Ownership: payload.Ownership,
      })
    );

    await dispatch(fetchUsers()).unwrap();
  }
);

export const resetSearchFilters = createAsyncThunk(
  "lokolo-users/reset-search-filters",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const rootState = getState() as RootState;
    if (
      rootState.houseUsers.filters.Esia === null &&
      rootState.houseUsers.filters.Registry === null &&
      rootState.houseUsers.filters.Ownership === null
    ) {
      return;
    }

    await dispatch(
      setFilters({
        Esia: null,
        Registry: null,
        Ownership: null,
      })
    );

    await dispatch(fetchUsers()).unwrap();
  }
);
