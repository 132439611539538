import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { OCC, Profile } from "@/types";
import { paths } from "@/consts/routes";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import { RootState, useAppDispatch } from "@/app/store";
import {
  fetchMoreOccList,
  fetchOccList,
  setOccListFilter,
  setOccListSearchQuery,
} from "@/app/store/slices/snt/myOcc/actions";
import { setQuery, toggleIsActual, SntMyOccState } from "@/app/store/slices/snt/myOcc";
import { addIcon24, chevronRight24Icon, dropdownSecondaryChevron } from "@/consts/icons";
import { ContentContext } from "@/components/content";
import {
  BaseTable,
  BaseTableHead,
  BaseTableBody,
  BaseTableRow,
  BaseTableHeadCell,
  BaseTableCell,
  BaseTableToolbar,
  BaseContainer,
} from "@/components/base-table";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import BaseButton from "@/components/base-button";
import BaseQuerySearch from "@/components/base-query-search";
import OccStatus from "@/components/occ-status";
import { Spinner } from "@/components/spinner";
import CreateOCCCheckHouseModal from "@/features/CreateOccNew/CheckHouseModal";
import styles from "./styles.module.scss";

const SntMyOCC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const mainEl = useContext(ContentContext);
  const { measureRef, isIntersecting, observer } = useInfiniteScroll();
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);

  const [emptyTableHeight, setEmptyTableHeight] = useState<number>(0);
  const [isSelectAddressModalShown, showSelectAddressModal] = useState<boolean>(false);

  const company = useSelector((state: RootState) => state.user.company);
  const { list, query, IsActual, isLoading, page } = useSelector((state: RootState) => state.sntMyOcc);

  const hasMore = useMemo(() => page && page.Index < page.Count, [page]);

  const createOcc = () => {
    if (company?.Type === Profile.CompanyType.SNT) {
      navigate("/snt/create-occ/new/create");
    } else if (company?.Type === Profile.CompanyType.UK) {
      showSelectAddressModal(true);
    }
  };

  const handleOCCSelect = (occId: OCC.OccOrderBase["Id"]) => {
    navigate(paths.myOccById(occId).details);
  };

  const updateFilter = (isActual: SntMyOccState["IsActual"]) => {
    dispatch(setOccListFilter(isActual));
  };

  const handleQueryChange = (value: string) => {
    if (value !== query) {
      dispatch(setOccListSearchQuery(value));
    }
  };

  const getEmptyTableHeight = () => {
    if (mainEl.current && tableBodyRef.current) {
      const rect = tableBodyRef.current.getBoundingClientRect();
      const pageHeight = (mainEl.current as HTMLElement).getBoundingClientRect();
      setEmptyTableHeight(pageHeight.height - rect.top + 48); // 48 - top & bottom paddings by 24
    }
  };

  useEffect(() => {
    dispatch(setQuery(""));
    dispatch(toggleIsActual(null));
    dispatch(fetchOccList()).unwrap();
    getEmptyTableHeight();

    window.addEventListener("resize", getEmptyTableHeight, true);

    return () => {
      window.removeEventListener("resize", getEmptyTableHeight, true);
    };
  }, []);

  const fetchMoreData = async () => {
    try {
      const { Index, Count } = await dispatch(fetchMoreOccList()).unwrap();
      if (observer && isIntersecting && Index === Count) {
        observer.disconnect();
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (isIntersecting) {
      fetchMoreData();
    }
  }, [isIntersecting]);

  return (
    <div>
      <div className={styles.header}>
        <h1 className="title-h1">Мои собрания</h1>
        <div className={styles.header_actions}>
          <BaseButton startIcon={addIcon24()} onClick={createOcc}>
            Создать собрание
          </BaseButton>
          <div>
            <BaseQuerySearch
              style={{ width: "340px" }}
              value={query}
              onChange={handleQueryChange}
              placeholder="Поиск по номеру собрания"
            />
          </div>
        </div>
      </div>

      <BaseContainer className={styles.table}>
        <BaseTableToolbar>
          <BaseDropdown
            className={styles.filters}
            placeholder="Фильтр"
            value={IsActual}
            isSelectable={true}
            onSelect={(value) => updateFilter(value)}
            customIcon={dropdownSecondaryChevron}
          >
            <div className={styles.filters_options}>
              <BaseDropdownMenuItem value={null} defaultHover>
                <span className="sf-text-medium">Все собрания</span>
              </BaseDropdownMenuItem>
              <BaseDropdownMenuItem value={true} defaultHover>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    opacity="0.3"
                    d="M8 0C9.58225 1.88681e-08 11.129 0.469191 12.4446 1.34824C13.7602 2.22729 14.7855 3.47672 15.391 4.93853C15.9965 6.40034 16.155 8.00887 15.8463 9.56072C15.5376 11.1126 14.7757 12.538 13.6569 13.6568L8 8V0Z"
                    fill="#12B212"
                  />
                  <circle cx="8" cy="8" r="7.25" stroke="#12B212" strokeWidth="1.5" />
                  <path d="M8 4V8L11 11" stroke="#12B212" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
                <span className="sf-text-regular">Только актуальные</span>
              </BaseDropdownMenuItem>
              <BaseDropdownMenuItem value={false} defaultHover>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    opacity="0.3"
                    d="M2.34315 13.6569C1.22433 12.538 0.4624 11.1126 0.153718 9.56072C-0.154964 8.00887 0.00346269 6.40034 0.608964 4.93853C1.21446 3.47672 2.23985 2.22729 3.55544 1.34824C4.87103 0.469192 6.41775 -1.88681e-08 8 0V8L2.34315 13.6569Z"
                    fill="#F43535"
                  />
                  <circle cx="8" cy="8" r="7.25" stroke="#F43535" strokeWidth="1.5" />
                  <path d="M8 4V8L5 11" stroke="#F43535" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
                <span className="sf-text-regular">Неактуальные</span>
              </BaseDropdownMenuItem>
            </div>
          </BaseDropdown>
        </BaseTableToolbar>

        <div className={styles.table_container}>
          <BaseTable>
            <BaseTableHead>
              <BaseTableRow className={styles.table_row}>
                <BaseTableHeadCell>Номер собрания</BaseTableHeadCell>
                {company?.Type === Profile.CompanyType.UK && <BaseTableHeadCell>Адрес</BaseTableHeadCell>}
                <BaseTableHeadCell>Дата начала</BaseTableHeadCell>
                <BaseTableHeadCell>Дата окончания</BaseTableHeadCell>
                <BaseTableHeadCell>Тип собрания</BaseTableHeadCell>
                <BaseTableHeadCell className={styles.table_row_status}>Статус</BaseTableHeadCell>
                <BaseTableHeadCell></BaseTableHeadCell>
              </BaseTableRow>
            </BaseTableHead>

            {isLoading ? (
              <BaseTableBody className={styles.empty} refProp={tableBodyRef}>
                <BaseTableRow>
                  <BaseTableCell colspan={7} style={{ height: `${emptyTableHeight}px` }}>
                    <div className="flex center">
                      <Spinner color="#226dff" size={36} />
                    </div>
                  </BaseTableCell>
                </BaseTableRow>
              </BaseTableBody>
            ) : list.length ? (
              <>
                <BaseTableBody className={styles.table_body}>
                  {list.map((it, index) => (
                    <BaseTableRow className={styles.table_row} key={it.Id} onClick={() => handleOCCSelect(it.Id)}>
                      <BaseTableHeadCell>
                        <span className="sf-text-semibold">{it?.Number}</span>
                      </BaseTableHeadCell>
                      {company?.Type === Profile.CompanyType.UK && (
                        <BaseTableCell>
                          <span className="sf-text-semibold">{it?.Address}</span>
                        </BaseTableCell>
                      )}
                      <BaseTableCell>
                        <span className="sf-text-semibold">{it?.StartDate}</span>
                      </BaseTableCell>
                      <BaseTableCell>
                        <span className="sf-text-semibold">{it?.EndDate}</span>
                      </BaseTableCell>
                      <BaseTableCell>{it?.Type}</BaseTableCell>
                      <BaseTableCell className={styles.table_row_status}>
                        <OccStatus statusId={it?.StatusId} statusName={it.Status} />
                      </BaseTableCell>
                      <BaseTableCell>
                        <span className={styles.table_row_icon}>{chevronRight24Icon("#818C99")}</span>
                      </BaseTableCell>
                    </BaseTableRow>
                  ))}
                </BaseTableBody>
                {hasMore && (
                  <tbody className={styles.loading_more} ref={measureRef}>
                    <tr>
                      <td colSpan={7}>
                        <div className="flex center">
                          <Spinner color="#226dff" size={36} />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </>
            ) : (
              <BaseTableBody className={styles.empty} refProp={tableBodyRef}>
                <BaseTableRow>
                  <BaseTableCell colspan={7} style={{ height: `${emptyTableHeight}px` }}>
                    <div className="center">
                      {query.length > 0 || IsActual !== null ? (
                        <p className="text--primary sf-pro-regular color--text-primary">Поиск не дал результатов</p>
                      ) : (
                        <p className="text--primary sf-pro-regular color--text-primary">Собраний пока нет</p>
                      )}
                    </div>
                  </BaseTableCell>
                </BaseTableRow>
              </BaseTableBody>
            )}
          </BaseTable>
        </div>
      </BaseContainer>

      <CreateOCCCheckHouseModal isOpen={isSelectAddressModalShown} onClose={() => showSelectAddressModal(false)} />
    </div>
  );
};

export default SntMyOCC;
