interface IBaseContainerProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

const BaseContainer: React.FC<IBaseContainerProps> = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

export default BaseContainer;
