import { Access } from "./access";

export namespace HouseUsers {
  export enum QueryType {
    Fio = "fio",
    Number = "number",
  }

  export enum StatusKey {
    ConfirmedOwnership = "CONFIRMED_OWNERSHIP",
    UnconfirmedOwnership = "UNCONFIRMED_OWNERSHIP",
    ConfirmingOwnership = "CONFIRMING_OWNERSHIP",
    Resident = "RESIDENT",
    Tenant = "TENANT",
    NotOwner = "NOT_OWNER",
  }

  export type Status = {
    key: StatusKey;
    name: string;
  };

  export type User = {
    UserId: number;
    InRegistry: boolean;
    Fio: string;
    Phone: string;
    ObjectNumber: string;
    EsiaExists: boolean;
    Ownership: StatusKey;
    Avatar: string;
    RegisteredAt: string;
    Role: {
      Id: Access.User["Id"];
      Type: Access.Role["Key"];
    } | null;
  };
}
