import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { paths } from "@/consts/routes";
import { successIcon } from "@/consts/icons";
import { FormValidator, Profile } from "@/types";
import { RootState, useAppDispatch } from "@/app/store";
import { SnackbarType, showSnackbar } from "@/app/store/slices/snackbar";
import { activeAddress, getCadastralNumber, saveCadastralNumber } from "@/app/store/slices/user";
import BaseModal, { BaseModalActions, BaseModalContent, BaseModalHeader } from "@/components/BaseModal";
import PageHeader from "@/components/PageHeader";
import BaseButton from "@/components/base-button";
import SelectAddressDropdown from "@/components/SelectAddressDropdown";
import BaseHeadlineAlert from "@/components/BaseHeadlineAlert";
import MaskedInput from "@/components/base-input/MaskedInput";
import styles from "./styles.module.scss";

const CadastralNumber: React.FC = () => {
  const dispatch = useAppDispatch();

  const address = useSelector(activeAddress);
  const cadastralNumber = useSelector((state: RootState) => state.user.cadastralNumber);

  const [field, setField] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSuccessModalShown, setSuccessModalShown] = useState<boolean>(false);

  const isReadonly: boolean = useMemo(() => {
    return (
      cadastralNumber?.Status.Key === Profile.CadastralNumberStatus.Confirmed ||
      cadastralNumber?.Status.Key === Profile.CadastralNumberStatus.New
    );
  }, [cadastralNumber]);

  const validation: string = useMemo(() => {
    const message = FormValidator.getFieldErrorMessage(field.trim(), true);

    if (!message && field.trim().length < 11) {
      return "Кадастровый номер должен быть не менее 11 символов";
    }

    if (!message && field.trim().length > 20) {
      return "Кадастровый номер должен быть не более 20 символов";
    }

    return message;
  }, [field]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMessage("");

    if (validation.length > 0) {
      setErrorMessage(validation);
      return;
    }

    setLoading(true);
    try {
      await dispatch(saveCadastralNumber(field)).unwrap();
      setSuccessModalShown(true);
    } catch (error: any) {
      dispatch(
        showSnackbar({
          key: "submit-cadastral-number",
          body: error?.response?.data?.Data?.Message ?? "Неизвестная ошибка. Попробуйте позже еще раз",
          type: SnackbarType.ERROR,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const fetch = async () => {
    setField("");
    setErrorMessage("");
    try {
      const response = await dispatch(getCadastralNumber()).unwrap();
      if (response.Status.Key === Profile.CadastralNumberStatus.Canceled) {
        setErrorMessage("Отклонена. Попробуйте еще раз");
      } else {
        setField(response.CadastralNumber ?? "");
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetch();
  }, [address]);

  return (
    <>
      <PageHeader title="Кадастровые номера домов" defaultLink={paths.settings} />

      <div>
        <BaseHeadlineAlert
          name="cadastral-number"
          title="Для чего это нужно?"
          verticalMargin="0"
          icon={
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M12 11C12.5523 11 13 11.4477 13 12V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V12C11 11.4477 11.4477 11 12 11Z"
                fill="#C290E3"
              />
              <path
                d="M12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z"
                fill="#C290E3"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4Z"
                fill="#C290E3"
              />
            </svg>
          }
        >
          <p className="text--primary">
            Для того чтобы сформировать реестр собственников с помощью данных из Росреестра, необходимо указать
            корректный кадастровый номер дома и отправить заявку в тех.поддержку на подтверждение правильности указанных
            данных
          </p>
        </BaseHeadlineAlert>
      </div>

      <div className={styles.content}>
        <div>
          <SelectAddressDropdown padding="4px 0" />
        </div>

        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.field_wrapper}>
            <div className={styles.field}>
              <MaskedInput
                mask={/^[0-9:]+$/}
                value={field}
                disabled={isReadonly}
                label="Кадастровый номер дома"
                placeholder="Введите кадастровый номер"
                onChange={(value) => setField(value)}
              />
              {isReadonly && (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.8 12H6.2C5.538 12 5 11.462 5 10.8V7.2C5 6.606 5.433 6.112 6 6.017V5.5C6 4.12 7.12 3 8.5 3C9.88 3 11 4.12 11 5.5V6.017C11.567 6.112 12 6.606 12 7.2V10.8C12 11.462 11.462 12 10.8 12ZM9.77 6V5.476C9.77 4.775 9.201 4.206 8.5 4.206C7.799 4.206 7.23 4.775 7.23 5.476V6H9.77Z"
                    fill="#818C99"
                  />
                </svg>
              )}
            </div>
            {errorMessage ? (
              <p className="sf-text-regular text--primary color--text-error">{errorMessage}</p>
            ) : cadastralNumber?.Status.Key === Profile.CadastralNumberStatus.New ? (
              <p className="sf-text-regular text--primary" style={{ color: "#FC9F1C" }}>
                Заявка отправлена.
              </p>
            ) : (
              isReadonly && (
                <p className="sf-text-regular text--primary color--text-success">
                  Подтвержден. Редактирование недоступно
                </p>
              )
            )}
          </div>

          {!isReadonly && (
            <div>
              <BaseButton disabled={!!validation} isLoading={isLoading} onClick={handleSubmit}>
                Отправить заявку на подтверждение
              </BaseButton>
            </div>
          )}
        </form>
      </div>

      <BaseModal isOpen={isSuccessModalShown} minWidth="520px">
        <BaseModalHeader
          variant="info-iconed"
          iconBgColor="#CFF3E1"
          icon={successIcon}
          title="Заявка отправлена"
          onClose={() => setSuccessModalShown(false)}
        />

        <BaseModalContent>
          <p className="sf-text-regular text--primary color--text-primary">
            Мы уже проверяем правильность введеного кадастрового номера. Подтвердим его в течение 1 рабочего дня. Статус
            отобразится у поля с номером
          </p>
        </BaseModalContent>

        <BaseModalActions>
          <BaseButton onClick={() => setSuccessModalShown(false)}>Понятно</BaseButton>
        </BaseModalActions>
      </BaseModal>
    </>
  );
};

export default CadastralNumber;
