import axios, { AxiosRequestConfig } from "axios";
import store from "@/app/store";
import { authLogout } from "@/app/store/slices/user";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";

export module Config {
  export module DefaultRequestConfig {
    export const responseType = "json";
  }

  export function createClient(axiosConfig?: AxiosRequestConfig) {
    const baseURL = process.env.REACT_APP_HOST;
    const instance = axios.create({
      ...Config.DefaultRequestConfig,
      ...axiosConfig,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      xsrfCookieName: "",
      withCredentials: false,
      baseURL,
    });

    instance.interceptors.request.use(
      (config) => {
        const token = store.getState().user.token;
        if (config.headers && token) config.headers["Authorization"] = `Bearer ${token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      (res) => {
        return res;
      },
      async (error) => {
        if (error?.response?.status === 401) {
          if (error.config.url.endsWith("/auth/logout")) {
            return Promise.resolve();
          }

          store.dispatch(authLogout());
          return;
        } else if (error?.response?.status === 429) {
          store.dispatch(showSnackbar({ key: "network-error", body: "Неизвестная ошибка", type: SnackbarType.ERROR }));
        }
        return Promise.reject(error);
      }
    );
    return instance;
  }
}

const client = Config.createClient();
export const formDataClient = Config.createClient({ headers: { "Content-Type": "multipart/form-data" } });
export const blobClient = Config.createClient({ responseType: "blob" });
export default client;
