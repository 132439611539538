import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AdminFields } from "../../../../consts/labels";

interface IAdministratorView {
  data: any;
  isEditMode: boolean;
}

const AdministratorView = ({ data, isEditMode }: IAdministratorView) => (
  <>
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon color="secondary" />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography fontFamily="GolosMedium">Администратор</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          {isEditMode
            ? AdminFields[data?.Administrator?.TypeId] &&
              Object.entries(AdminFields[data?.Administrator?.TypeId]).map(
                ([key, value]: any) => (
                  <Box fontSize="14px" display="flex">
                    <Box
                      component="span"
                      color="rgba(0, 0, 0, 0.54)"
                      width="30%"
                    >
                      {value}{" "}
                    </Box>
                    <Box component="span" paddingLeft={2}>
                      {data.Administrator.Object[key]}
                    </Box>
                  </Box>
                )
              )
            : AdminFields[data?.Administrator?.Type] &&
              Object.entries(AdminFields[data?.Administrator?.Type]).map(
                ([key, value]: any) => (
                  <Box fontSize="14px" display="flex">
                    <Box
                      component="span"
                      color="rgba(0, 0, 0, 0.54)"
                      width="30%"
                    >
                      {value}{" "}
                    </Box>
                    <Box component="span" paddingLeft={2}>
                      {data.Administrator[key]}
                    </Box>
                  </Box>
                )
              )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  </>
);

export { AdministratorView };
