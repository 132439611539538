import { useCallback, useState } from "react";
import classNames from "classnames/bind";
import styles from "./questionReadyList.module.scss";

const cx = classNames.bind(styles);

interface ITemplateItemProps {
  question: any;
  isSelected?: any;
  onSelect?: () => void;
}

const TemplateItem: React.FC<ITemplateItemProps> = ({ question, isSelected, onSelect }) => {
  const [isContentMultiline, setContentMultiline] = useState<boolean>(false);

  const onRefChange = useCallback((node: HTMLElement) => {
    setContentMultiline(node && node.offsetHeight > 20);
  }, []);

  return (
    <div className={cx({ template_question: true, multiline: isContentMultiline })} role="button" onClick={onSelect}>
      <span className="golosMedium">
        <span ref={onRefChange} className={styles.template_question_title}>
          {question.Title}
        </span>
      </span>
      {isSelected && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.7071 5.29289C21.0976 5.68342 21.0976 6.31658 20.7071 6.70711L9.70711 17.7071C9.31658 18.0976 8.68342 18.0976 8.29289 17.7071L3.29289 12.7071C2.90237 12.3166 2.90237 11.6834 3.29289 11.2929C3.68342 10.9024 4.31658 10.9024 4.70711 11.2929L9 15.5858L19.2929 5.29289C19.6834 4.90237 20.3166 4.90237 20.7071 5.29289Z"
            fill="#12B212"
          />
        </svg>
      )}
    </div>
  );
};

export default TemplateItem;
