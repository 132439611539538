import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { paths } from "@/consts/routes";
import { RootState, useAppDispatch } from "@/app/store";
import { activeAddress } from "@/app/store/slices/user";
import { fetchHouseDetails } from "@/app/store/slices/uk/registry/actions";
import { Content } from "@/components/content";
import { Layout } from "@/components/layout";
import Chart from "./Chart";
import styles from "./styles.module.scss";

const UKRegistryAnalytics = () => {
  const dispatch = useAppDispatch();
  const address = useSelector(activeAddress);

  const details = useSelector((state: RootState) => state.ukRegistry.details);

  useEffect(() => {
    dispatch(fetchHouseDetails());
  }, [address]);

  return (
    <Layout>
      <Content>
        <div className={styles.header}>
          <div className={styles.header_title}>
            <h1 className="title-h1">Аналитика по реестру</h1>
          </div>

          <div className="flex middle">
            <NavLink className={styles.back_link} to={paths.ukRegistry().fullPath().main}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.20711 2.79289C7.59763 3.18342 7.59763 3.81658 7.20711 4.20711L4.41421 7H14C14.5523 7 15 7.44772 15 8C15 8.55228 14.5523 9 14 9H4.41421L7.20711 11.7929C7.59763 12.1834 7.59763 12.8166 7.20711 13.2071C6.81658 13.5976 6.18342 13.5976 5.79289 13.2071L1.29289 8.70711C0.902369 8.31658 0.902369 7.68342 1.29289 7.29289L5.79289 2.79289C6.18342 2.40237 6.81658 2.40237 7.20711 2.79289Z"
                  fill="#226DFF"
                />
              </svg>
              <span className="sf-text-regular text--primary color--primary">Вернуться назад</span>
            </NavLink>
          </div>
        </div>

        <div className={styles.charts}>
          <Chart
            name="Площадь (м²)"
            data={[
              details?.ApartmentsNonresidentialArea
                ? parseFloat(Number(details?.ApartmentsNonresidentialArea).toFixed(1))
                : 0,
              details?.ApartmentsResidentialArea
                ? parseFloat(Number(details?.ApartmentsResidentialArea).toFixed(1))
                : 0,
            ]}
            colors={["#0AC398", "#EB1F38"]}
            legendBgColors={["#CCF8E8", "#F8CCE1"]}
            labels={["Нежилых помещений", "Жилых помещений"]}
            fractionDigits={1}
            tooltipMessage="Для изменения общей площади помещений в собственности обратитесь в техническую поддержку"
          />

          <Chart
            name="Помещения"
            data={[details?.ApartmentsNonresidentialCount ?? 0, details?.ApartmentsResidentialCount ?? 0]}
            colors={["#466970", "#76D814"]}
            legendBgColors={["#B5D0D5", "#DFF1CC"]}
            labels={["Нежилых помещений", "Жилых помещений"]}
          />

          <Chart
            name="Собственники"
            data={[
              details?.LegalRelationsSubjectCount.IndividualCount ?? 0,
              details?.LegalRelationsSubjectCount.LegalEntityCount ?? 0,
            ]}
            colors={["#2E71F4", "#09C3DD"]}
            legendBgColors={["#CCDBF8", "#CCF5F8"]}
            labels={["Физические лица", "Юридические лица"]}
          />
        </div>
      </Content>
    </Layout>
  );
};

export default UKRegistryAnalytics;
