import { IApiModule } from "../types";

export default {
  getGroups: () => ({
    method: "GET",
    url: "api/default-data/occ-questions/get-groups",
  }),
  getQuestionsByGrpup: (groupId: number) => ({
    method: "GET",
    url: `api/default-data/occ-questions/get-questions-by-group?GroupId=${groupId}`,
  }),
} as IApiModule;
