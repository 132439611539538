import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import { paths } from "@/consts/routes";
import { Profile } from "@/types";

const RootRedirect: React.FC = () => {
  const { profile } = useSelector((state: RootState) => state.user);

  if (profile?.role === Profile.UserRole.Institution) {
    return <Navigate to={paths.institution().root} replace={true} />;
  } else if (profile?.company_type === Profile.CompanyType.SNT) {
    return <Navigate to={"/snt/registry"} replace={true} />;
  }

  return <Navigate to={paths.ukRegistry().fullPath().main} replace={true} />;
};

export default RootRedirect;
