import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  OutlinedInput,
  InputLabel,
  Grid,
  FormHelperText,
  ToggleButtonGroup,
  ToggleButton,
  Select,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { api } from "../../../services/api";
import { Spinner } from "../../../components/spinner";
import { isObject } from "../../../helpers/validation";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import { LEGAL_FROMS } from "../constant";
import { setCommonData } from "../../../app/store/slices/app";
import { RootState } from "../../../app/store";
import { ErrorsType } from "../../../types/common";

import styles from "../create.module.scss";
import { createOccMode } from "../../../consts/state";
import { useCurrentMode } from "../hooks/useCurrentMode";

interface IProps {
  refForm: any;
  toNextStep: (id?: number) => void;
}

// @TODO повторения вынести отдельно
// @TODO дописать типы
// @TODO вынести в кастомные хуки
const Step3 = ({ refForm, toNextStep }: IProps) => {
  const { state: occId } = useLocation();
  const [load, setLoad] = useState(occId);
  const [type, setType] = useState(2);
  const [error, setErrorResponse] = useState<ErrorsType>(null);
  // TODO: поресерчить с маской и выводом ошибок
  const [errorPhone, setErrorPhone] = useState<string>("");
  const [dateOfIssue, setDateOfIssue] = useState<Date | null>(null);
  const [legalFormValue, setLegalFormValue] = useState<string>("");
  const [form, setForm] = useState<{
    UkLegalForm: string;
    UkOGRN: string;
    Phone: string;
    FoundationAgreementDetails: string;
  }>({
    UkLegalForm: "",
    UkOGRN: "",
    Phone: "",
    FoundationAgreementDetails: "",
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    getValues,
    control,
  } = useForm();
  const dispatch = useDispatch();
  const userData = useSelector((state: RootState) => state.app.user.common);

  const mode = useCurrentMode();

  const storageData = localStorage.getItem("step3") !== null ? localStorage.getItem("step3") : null;

  const setOccData = (data: any) => {
    if (isObject(data)) {
      setLegalFormValue(data.UkLegalForm);
      setForm({
        UkLegalForm: data.UkLegalForm,
        UkOGRN: data.UkOGRN,
        Phone: data.Phone,
        FoundationAgreementDetails: data.FoundationAgreementDetails,
      });
      // const formField: string[] = [
      //   "Type",
      //   "Surname",
      //   "Patronymic",
      //   "PermanentResidencePlace",
      //   "FoundationAgreementDetails",
      //   "Email",
      //   "Series",
      //   "Number",
      //   "IssuedBy",
      //   "IssueDate",
      //   "DepartmentCode",
      //   "Phone",
      //   "Location",
      //   "MailingAddress",
      //   "Site",
      // ];
      // // @TODO потом надо будет убрать этот колхоз
      // for (const field of formField) {
      //   setValue(field, data[field]);
      // }

      // if (data.Type === 1) {
      //   setValue("CompanyName", data["Name"]);
      //   setValue("OGRN", data["OGRN"]);
      //   setValue("LegalForm", data["LegalForm"]);
      // }

      // if (data.Type === 0) {
      //   setValue("Name", data["Name"]);
      // }

      // if (data.Type === 2) {
      //   setValue("UkName", data["Name"]);
      //   setValue("UkOGRN", data["OGRN"]);
      //   setValue("UkLegalForm", data["LegalForm"]);
      //   setValue("LegalForm", data["LegalForm"]);
      // }

      // if (mode === createOccMode.edit) {
      //   setLegalFormValue(data.LegalForm);
      // }

      setType(data.Type);
    }
  };

  const getOccData = async () => {
    try {
      const response = await api.occ.getOccAdministrator({ OccId: occId });
      if (Array.isArray(response.data.Data) && response.data.Data.length === 0) {
        if (userData) {
          setOccData({
            Name: userData.Name,
            UkId: userData.Id,
            LegalForm: userData.LegalForm,
            UkLegalForm: userData.LegalForm,
            OGRN: userData.OGRN,
            UkOGRN: userData.OGRN,
            FoundationAgreementDetails: userData.FoundationAgreementDetails,
            Phone: userData.Phone,
            Type: 2,
          });
        } else {
          const { data } = await api.user.getData();
          setOccData({
            Name: data.Data.Name,
            UkId: data.Data.Id,
            LegalForm: data.Data.LegalForm,
            UkLegalForm: data.Data.LegalForm,
            OGRN: data.Data.OGRN,
            UkOGRN: data.Data.OGRN,
            FoundationAgreementDetails: data.Data.FoundationAgreementDetails,
            Phone: data.Data.Phone,
            Type: 2,
          });
        }

        setLoad(false);
        return;
      }

      if (mode === createOccMode.edit && storageData) {
        let data = JSON.parse(storageData).Administrator;
        data = { ...data, ...data.Object };
        delete data.Object;

        setOccData({ ...data, ...data.Object, Type: data.TypeId });
      } else {
        setOccData(response.data.Data);
      }
      setLoad(false);
    } catch (err: any) {
      setLoad(false);
    }
  };

  const getUserCommonData = async () => {
    try {
      const response = await api.user.getData();

      dispatch(setCommonData(response.data.Data));
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUserCommonData();

    if (occId) {
      getOccData();
    }
  }, []);

  const createOcc = async () => {
    setErrorResponse(null);

    let validation: any = {};
    if (form.Phone.length === 0) {
      validation.Phone = "Поле обязательно для заполнения";
    }
    if (form.FoundationAgreementDetails.length === 0) {
      validation.FoundationAgreementDetails = "Поле обязательно для заполнения";
    }
    if (form.UkLegalForm.length === 0) {
      validation.UkLegalForm = "Поле обязательно для заполнения";
    }
    if (form.UkOGRN.length === 0) {
      validation.UkOGRN = "Поле обязательно для заполнения";
    }

    if (Object.values(validation).some((it) => !!it) || !!errorPhone) {
      setErrorResponse(validation);
      return;
    }

    try {
      const _data = {
        TypeId: type,
        OccId: occId,
        Object: {
          Name: userData?.Name,
          // Surname: data.Surname,
          // Patronymic: data.Patronymic,
          // RoomAddress: data.RoomAddress,
          // OwnershipDocumentDetails: data.OwnershipDocumentDetails,
          Phone: form.Phone,
          // PermanentResidencePlace: data.PermanentResidencePlace,
          // Email: data.Email,
          // Series: data.Series,
          // Number: data.Number,
          // IssuedBy: data.IssuedBy,
          // IssueDate: data.IssueDate,
          // DepartmentCode: data.DepartmentCode,
          // Location: data.Location,
          // MailingAddress: data.MailingAddress,
          // Site: data.Site,
          FoundationAgreementDetails: form.FoundationAgreementDetails,
          LegalForm: form.UkLegalForm,
          OGRN: form.UkOGRN,
        },
      };

      // if (type === 1) {
      //   _data.Object.Name = data.CompanyName;
      //   _data.Object.OGRN = data.OGRN;
      // }

      // if (type === 0) {
      //   _data.Object.Name = data.Name;
      // }

      // if (type === 2) {
      //   _data.Object.Name = data.UkName;
      //   _data.Object.LegalForm = data.UkLegalForm;
      //   _data.Object.OGRN = data.UkOGRN;
      // }

      if (mode === createOccMode.edit) {
        toNextStep();
        localStorage.setItem("step3", JSON.stringify({ Administrator: _data }));
        return;
      }

      await api.occ.createOccAdministrator(_data);
      toNextStep();
    } catch (err: any) {
      setErrorResponse(err.response.data.Data);
      return false;
    }
  };

  const onCloseError = () => {
    setErrorResponse(null);
  };

  const handleChange = (event: any, newAlignment: any) => {
    if (newAlignment !== null) {
      setType(newAlignment);
    }
  };

  useEffect(() => {
    if (userData?.LegalForm && !legalFormValue) {
      setLegalFormValue(userData.LegalForm);
    }

    if (userData?.LegalForm === null) {
      setLegalFormValue(" ");
    }
  }, [userData]);

  let ukName = localStorage.getItem("ukName");

  if (ukName) {
    ukName = JSON.parse(ukName).Name;
    setValue("UkName", ukName);
  }

  if (load) return <Spinner alt={"Загрузка..."} />;

  return (
    <section className={styles.root}>
      {/* {legalFormValue && ( */}
      <form ref={refForm} onSubmit={createOcc}>
        <Grid container rowSpacing={2} columnSpacing={4}>
          <Grid item xs={12}>
            {error && (
              <Alert severity="error" onClose={onCloseError}>
                <Box>
                  {/* {Object.keys(error?.Errors)?.map((field) => (
                    <Typography variant="caption" color="error">
                      {error.Errors[field]}
                    </Typography>
                  ))} */}
                </Box>
              </Alert>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ paddingBottom: "24px", color: "#000", opacity: 1 }}>
              Администратор собрания - лицо, ответственное за организацию собрания.
            </Typography>
            <ToggleButtonGroup color="primary" value={type} exclusive onChange={handleChange}>
              <ToggleButton value={1} sx={{ minWidth: "240px" }} disabled>
                Юридическое лицо
              </ToggleButton>
              <ToggleButton value={0} sx={{ minWidth: "240px" }} disabled>
                Физическое лицо
              </ToggleButton>
              <ToggleButton value={2} sx={{ minWidth: "240px", padding: 0, textTransform: "none" }}>
                Управляющая организация
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {/* {type === 1 && (
              <>
                <Grid item xs={12}>
                  <InputLabel>Компания</InputLabel>
                  <OutlinedInput
                    fullWidth
                    autoFocus
                    placeholder="Ввести"
                    {...register("CompanyName", {
                      required: "Поле обязательно для заполнения",
                    })}
                    error={
                      (errors["CompanyName"]?.type as unknown as string) ===
                      "required"
                    }
                  />
                  {(errors["CompanyName"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["CompanyName"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="LegalForm">
                    Организационно - правовая форма
                  </InputLabel>
                  <Select
                    placeholder="Выбрать"
                    id="LegalForm"
                    fullWidth
                    defaultValue={getValues("LegalForm")}
                    {...register("LegalForm", {
                      required: "Поле обязательно для заполнения",
                    })}
                    error={
                      (errors["LegalForm"]?.type as unknown as string) ===
                      "required"
                    }
                  >
                    {LEGAL_FROMS.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  {(errors["LegalForm"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["LegalForm"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Место нахождения</InputLabel>
                  <OutlinedInput
                    fullWidth
                    placeholder="Ввести"
                    {...register("Location", {
                      required: "Поле обязательно для заполнения",
                    })}
                    error={
                      (errors["Location"]?.type as unknown as string) ===
                      "required"
                    }
                  />
                  {(errors["Location"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["Location"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Почтовый адрес</InputLabel>
                  <OutlinedInput
                    fullWidth
                    placeholder="Ввести"
                    {...register("MailingAddress", {
                      required: "Поле обязательно для заполнения",
                    })}
                    error={
                      (errors["MailingAddress"]?.type as unknown as string) ===
                      "required"
                    }
                  />
                  {(errors["MailingAddress"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["MailingAddress"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Контактный телефон</InputLabel>
                  <Controller
                    name="Phone"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Поле обязательно для заполнения" }}
                    render={({ field: { onChange, value } }) => (
                      <InputMask
                        mask="+7 (999) 999-99-99"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value.replace(/[^0-9+]/g, ""));
                        }}
                      >
                        <OutlinedInput
                          placeholder="+7 (XXX) XXX-XX-XX"
                          error={
                            (errors["Phone"]?.type as unknown as string) ===
                            "required"
                          }
                          type="tel"
                          fullWidth
                          required
                        />
                      </InputMask>
                    )}
                  />
                  {(errors["Phone"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["Phone"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Сайт</InputLabel>
                  <OutlinedInput
                    fullWidth
                    placeholder="Ввести"
                    {...register("Site", {
                      required: "Поле обязательно для заполнения",
                    })}
                    error={
                      (errors["Site"]?.type as unknown as string) === "required"
                    }
                  />
                  {(errors["Site"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["Site"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
              </>
            )}
            {type === 0 && (
              <>
                <Grid item xs={12}>
                  <Typography fontSize={"18px"}>Основная информация</Typography>
                </Grid>
                <Grid item xs={4}>
                  <InputLabel>Фамилия</InputLabel>
                  <OutlinedInput
                    fullWidth
                    placeholder="Иванов"
                    autoFocus
                    {...register("Surname", {
                      required: "Поле обязательно для заполнения",
                    })}
                    error={
                      (errors["Surname"]?.type as unknown as string) ===
                      "required"
                    }
                  />
                  {(errors["Surname"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["Surname"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <InputLabel>Имя</InputLabel>
                  <OutlinedInput
                    fullWidth
                    placeholder="Иван"
                    {...register("Name", {
                      required: "Поле обязательно для заполнения",
                    })}
                    error={
                      (errors["Name"]?.type as unknown as string) === "required"
                    }
                  />
                  {(errors["Name"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["Name"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <InputLabel>Отчество</InputLabel>
                  <OutlinedInput
                    fullWidth
                    placeholder="Иванович"
                    {...register("Patronymic", {
                      required: "Поле обязательно для заполнения",
                    })}
                    error={
                      (errors["Patronymic"]?.type as unknown as string) ===
                      "required"
                    }
                  />
                  {(errors["Patronymic"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["Patronymic"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Место постоянного проживания</InputLabel>
                  <OutlinedInput
                    fullWidth
                    placeholder="Адрес"
                    {...register("PermanentResidencePlace", {
                      required: "Поле обязательно для заполнения",
                    })}
                    error={
                      (errors["PermanentResidencePlace"]
                        ?.type as unknown as string) === "required"
                    }
                  />
                  {(errors["PermanentResidencePlace"]
                    ?.type as unknown as string) === "required" && (
                    <FormHelperText error>
                      {
                        errors["PermanentResidencePlace"]
                          ?.message as unknown as string
                      }
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>Контактный телефон</InputLabel>
                  <Controller
                    name="Phone"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Поле обязательно для заполнения" }}
                    render={({ field: { onChange, value } }) => (
                      <InputMask
                        mask="+7 (999) 999-99-99"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value.replace(/[^0-9+]/g, ""));
                        }}
                      >
                        <OutlinedInput
                          placeholder="+7 (XXX) XXX-XX-XX"
                          error={
                            (errors["Phone"]?.type as unknown as string) ===
                            "required"
                          }
                          type="tel"
                          fullWidth
                          required
                        />
                      </InputMask>
                    )}
                  />
                  {(errors["Phone"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["Phone"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>Электронная почта</InputLabel>
                  <OutlinedInput
                    fullWidth
                    placeholder="example@gmail.com"
                    {...register("Email", {
                      required: "Поле обязательно для заполнения",
                    })}
                    error={
                      (errors["Email"]?.type as unknown as string) ===
                      "required"
                    }
                  />
                  {(errors["Email"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["Email"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography fontSize={"18px"}>Паспотрные данные</Typography>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>Серия</InputLabel>
                  <Controller
                    name="Series"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Поле обязательно для заполнения" }}
                    render={({ field: { onChange, value } }) => (
                      <InputMask
                        mask="9999"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value.replace(/[^0-9]/g, ""));
                        }}
                      >
                        <OutlinedInput
                          placeholder="1233"
                          error={
                            (errors["Series"]?.type as unknown as string) ===
                            "required"
                          }
                          fullWidth
                          required
                        />
                      </InputMask>
                    )}
                  />
                  {(errors["Series"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["Series"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>Номер</InputLabel>
                  <Controller
                    name="Number"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Поле обязательно для заполнения" }}
                    render={({ field: { onChange, value } }) => (
                      <InputMask
                        mask="999 999"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value.replace(/[^0-9]/g, ""));
                        }}
                      >
                        <OutlinedInput
                          placeholder="231 312"
                          error={
                            (errors["Number"]?.type as unknown as string) ===
                            "required"
                          }
                          fullWidth
                          required
                        />
                      </InputMask>
                    )}
                  />
                  {(errors["Number"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["Number"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>Дата выдачи</InputLabel>
                  <Controller
                    control={control}
                    name="IssueDate"
                    rules={{ required: "Поле обязательно для заполнения" }}
                    render={({ field }) => (
                      <DatePicker
                        placeholderText="Выбрать"
                        onChange={(date: any) => {
                          field.onChange(date);
                          setDateOfIssue(date);
                        }}
                        selected={dateOfIssue}
                        locale="ru"
                        dateFormat="d MMMM yyyy"
                        customInput={
                          <OutlinedInput
                            fullWidth
                            error={
                              (errors["IssueDate"]
                                ?.type as unknown as string) === "required"
                            }
                          />
                        }
                        maxDate={new Date()}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    )}
                  />
                  {(errors["IssueDate"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["IssueDate"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>Код подразделения</InputLabel>
                  <Controller
                    name="DepartmentCode"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Поле обязательно для заполнения" }}
                    render={({ field: { onChange, value } }) => (
                      <InputMask
                        mask="999 999"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value.replace(/[^0-9]/g, ""));
                        }}
                      >
                        <OutlinedInput
                          placeholder="160-198"
                          error={
                            (errors["DepartmentCode"]
                              ?.type as unknown as string) === "required"
                          }
                          fullWidth
                          required
                        />
                      </InputMask>
                    )}
                  />
                  {(errors["DepartmentCode"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["DepartmentCode"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Кем выдан</InputLabel>
                  <OutlinedInput
                    fullWidth
                    placeholder="Кем выдан"
                    {...register("IssuedBy", {
                      required: "Поле обязательно для заполнения",
                    })}
                    error={
                      (errors["IssuedBy"]?.type as unknown as string) ===
                      "required"
                    }
                  />
                  {(errors["IssuedBy"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["IssuedBy"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
              </>
            )}
            {type === 2 && ( */}
          <>
            <Grid item xs={12}>
              <InputLabel>Компания</InputLabel>
              <OutlinedInput
                fullWidth
                autoFocus
                multiline
                placeholder="Ввести"
                disabled
                defaultValue={userData?.Name}
                // {...register("UkName", {
                //   required: "Поле обязательно для заполнения",
                // })}
              />
            </Grid>
            {
              <Grid item xs={12}>
                <InputLabel htmlFor="UkLegalForm">Организационно - правовая форма</InputLabel>
                <Select
                  placeholder="Выбрать"
                  id="UkLegalForm"
                  fullWidth
                  value={form.UkLegalForm}
                  onChange={(e) => setForm((prevState) => ({ ...prevState, UkLegalForm: e.target.value }))}
                  // {...register("UkLegalForm", {
                  //   required: "Поле обязательно для заполнения",
                  // })}
                  error={
                    (errors["UkLegalForm"]?.type as unknown as string) === "required" || !!(error as any)?.UkLegalForm
                  }
                >
                  {LEGAL_FROMS.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                {(errors["UkLegalForm"]?.type as unknown as string) === "required" && (
                  <FormHelperText error>{errors["UkLegalForm"]?.message as unknown as string}</FormHelperText>
                )}
                {!!(error as any)?.UkLegalForm && <FormHelperText error>{(error as any)?.UkLegalForm}</FormHelperText>}
              </Grid>
            }

            <Grid item xs={12}>
              <InputLabel>ОГРН</InputLabel>
              <OutlinedInput
                fullWidth
                placeholder="Введите номер ОГРН"
                value={form.UkOGRN}
                onChange={(e) => setForm((prevState) => ({ ...prevState, UkOGRN: e.target.value }))}
                error={(errors["UkOGRN"]?.type as unknown as string) === "required" || !!(error as any)?.UkOGRN}
              />
              {(errors["UkOGRN"]?.type as unknown as string) === "required" && (
                <FormHelperText error>{errors["UkOGRN"]?.message as unknown as string}</FormHelperText>
              )}
              {!!(error as any)?.UkOGRN && <FormHelperText error>{(error as any)?.UkOGRN}</FormHelperText>}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>
                Реквизиты договора основания на наличие права инициировать общее собрание собственников
              </InputLabel>
              <OutlinedInput
                fullWidth
                placeholder="Введите реквизиты договора основания..."
                value={form.FoundationAgreementDetails}
                onChange={(e) => setForm((prevState) => ({ ...prevState, FoundationAgreementDetails: e.target.value }))}
                error={
                  (errors["FoundationAgreementDetails"]?.type as unknown as string) === "required" ||
                  !!(error as any)?.FoundationAgreementDetails
                }
              />
              {(errors["FoundationAgreementDetails"]?.type as unknown as string) === "required" && (
                <FormHelperText error>
                  {errors["FoundationAgreementDetails"]?.message as unknown as string}
                </FormHelperText>
              )}
              {!!(error as any)?.FoundationAgreementDetails && (
                <FormHelperText error>{(error as any)?.FoundationAgreementDetails}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Контактный телефон</InputLabel>
              <InputMask
                mask="+7 (999) 999-99-99"
                value={String(form.Phone)}
                onChange={(e) => {
                  const phone = e.target.value.replace(/[^0-9+]/g, "");
                  if (phone.length > 11) {
                    setErrorPhone("");
                  }

                  setForm((prevState) => ({ ...prevState, Phone: phone }));

                  if (phone.length < 12) {
                    setErrorPhone("Некорректный формат телефона");
                  }
                }}
              >
                <OutlinedInput
                  placeholder="+7 (XXX) XXX-XX-XX"
                  error={
                    (errors["Phone"]?.type as unknown as string) === "required" ||
                    !!errorPhone ||
                    !!(error as any)?.Phone
                  }
                  type="tel"
                  fullWidth
                  required
                />
              </InputMask>
              {(errors["Phone"]?.type as unknown as string) === "required" && (
                <FormHelperText error>{errors["Phone"]?.message as unknown as string}</FormHelperText>
              )}
              {!!errorPhone && (errors["Phone"]?.type as unknown as string) !== "required" && (
                <FormHelperText error>{errorPhone}</FormHelperText>
              )}
              {!!(error as any)?.Phone && <FormHelperText error>{(error as any)?.Phone}</FormHelperText>}
            </Grid>
          </>
          {/* )} */}
        </Grid>
      </form>
      {/* // )} */}
    </section>
  );
};

export { Step3 };
