import { useContext, useEffect, useState } from "react";
import { Grid, Button, Alert, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { Spinner } from "../../../components/spinner";
import { QuestionForm } from "../components/questionForm/questionForm";
import { QuestionItem } from "../components/questionItem";
import { QuestionReadyList } from "../components/questionReadyList/questionReadyList";
import { ContentContext } from "../../../components/content";
import { RootState, useAppDispatch } from "../../../app/store";
import {
  clearQuestionsError,
  closeUpdateNotification,
  fetchDefaultGroups,
  fetchQuestions,
  fetchQuestionsByGroup,
  fetchTemplateGroups,
  resetCommonGroup,
} from "../../../app/store/slices/createOccOld";
import { useCurrentMode } from "../hooks/useCurrentMode";
import { fetchQuorums } from "../../../app/store/slices/app";
import { createOccMode } from "../../../consts/state";
import { useQuestionsLocalStorage } from "../hooks/useQuestionsLocalStorage";

// TODO: Отрефакторить этот компонент
const Step4 = () => {
  const { state: occId } = useLocation();

  const [activeEdit, setActiveEdit] = useState(null);
  const [activeAdd, setActiveAdd] = useState(false);
  const [activeGroupId, setActiveGroupId] = useState<number>(0);

  const { data: questions, loading, error } = useSelector((state: RootState) => state.createOccOld.questions);
  const quorums = useSelector((state: RootState) => state.app.quorums);
  const commonGroups = useSelector((state: RootState) => state.createOccOld.commonGroups);
  const questionsByGroup = useSelector((state: RootState) => state.createOccOld.questionsByGroup);

  const mode = useCurrentMode();
  const [questionsLocalStorage, setQuestionsLocalStorage, setValueStorage, isEmptyQuestionsStorage] =
    useQuestionsLocalStorage();

  const mainEl = useContext(ContentContext);

  const dispatch = useAppDispatch();

  const isEditMode = mode === createOccMode.edit;

  const handleAddQuestion = () => {
    setActiveAdd(true);
  };

  const handleLoadQuestionsByGroup = (groupId: number) => {
    if (!questionsByGroup.data[groupId]) {
      dispatch(fetchQuestionsByGroup(groupId));
    }

    setActiveGroupId(groupId);
  };

  const editQuestion = (id: number, key?: string) => {
    // @ts-ignore
    if (!isEditMode) {
      setActiveEdit(questions.find((question: any) => question.Id === id));
    }

    if (isEditMode && key === "Id") {
      setActiveEdit(questionsLocalStorage.find((question: any) => question.Id === id));
    }

    if (isEditMode && key === "Order") {
      setActiveEdit(questionsLocalStorage.find((question: any) => question.Order === id));
    }

    setTimeout(() => mainEl.current && scrollToTop(mainEl.current), 0);
  };

  const onReload = () => {
    setActiveAdd(false);
    setActiveEdit(null);

    if (!isEditMode) {
      dispatch(fetchQuestions(occId));
    }
  };

  const onSelectQuestion = (data: any) => {
    setActiveAdd(true);
    setActiveEdit({ ...data });

    mainEl.current && scrollToTop(mainEl.current);
  };

  const handleSelectQuestionFromGroup = (data: { QuorumId: number; Title: string; Value: string }) => {
    onSelectQuestion(data);
  };

  const onCloseError = () => {
    dispatch(clearQuestionsError());
  };

  const scrollToTop = (el: HTMLElement) => {
    el.scrollTo({
      top: 270,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (occId && isEmptyQuestionsStorage) {
      dispatch(fetchQuestions(occId));
    }

    // dispatch(fetchTemplateGroups());
    // dispatch(fetchDefaultGroups());

    return () => {
      dispatch(closeUpdateNotification("showNotification"));
      dispatch(closeUpdateNotification("showNotificationError"));
      dispatch(resetCommonGroup());
    };
  }, []);

  useEffect(() => {
    if (!quorums.data.length) {
      dispatch(fetchQuorums());
    }
  }, [quorums.data]);

  useEffect(() => {
    if (questions.length && isEditMode) {
      // @ts-ignore
      if (isEmptyQuestionsStorage) {
        setValueStorage(questions);
      }

      if (!questionsLocalStorage.length) {
        setQuestionsLocalStorage(questions);
      }
    }
  }, [questions]);

  return (
    <Grid item container xs={12} xl={12} alignItems="flex-start">
      <Typography variant="subtitle1" sx={{ width: "100%", paddingBottom: "24px", color: "#000", opacity: 1 }}>
        Сформулируйте и добавьте вопросы, которые необходимо решить
      </Typography>
      <Grid item container rowSpacing={2} columnSpacing={4} xs={7} pr={6}>
        <Grid item xs={12}>
          {error && (
            <Alert severity="error" onClose={onCloseError}>
              {error}
            </Alert>
          )}
        </Grid>
        {!(!questions?.length || activeEdit || activeAdd) && (
          <Grid item xs={12}>
            <Button sx={{ width: "100%" }} variant="outlined" color="secondary" onClick={handleAddQuestion}>
              + Добавить вопрос в повестку
            </Button>
          </Grid>
        )}
        {(!questions?.length || activeEdit || activeAdd) && (
          <Grid item xs={12}>
            {loading ? (
              <Spinner alt="" />
            ) : (
              <QuestionForm
                key={activeEdit}
                occId={occId}
                data={activeEdit}
                onReload={onReload}
                onActiveEdit={setActiveEdit}
                onActiveAdd={setActiveAdd}
                setValueStorage={setValueStorage}
                questionsLocalStorage={questionsLocalStorage}
                isEditMode={isEditMode}
              />
            )}
          </Grid>
        )}
        {!!questions?.length && !isEditMode ? (
          <>
            {questions.map((el: any, indx: number) => (
              <QuestionItem
                key={el.Id}
                data={el}
                index={indx}
                deleteCallback={onReload}
                onEdit={editQuestion}
                quorums={quorums}
              />
            ))}
          </>
        ) : (
          !!questionsLocalStorage?.length &&
          !isEmptyQuestionsStorage && (
            <>
              {questionsLocalStorage.map((el: any, indx: number) => (
                <QuestionItem
                  key={el.Id}
                  data={el}
                  index={indx}
                  deleteCallback={onReload}
                  onEdit={editQuestion}
                  quorums={quorums}
                  setValueStorage={setValueStorage}
                  questionsLocalStorage={questionsLocalStorage}
                />
              ))}
            </>
          )
        )}
      </Grid>
      <Grid item xs={5}>
        <QuestionReadyList
          onSelect={onSelectQuestion}
          onLoadQuestionsByGroup={(groupId: number) => handleLoadQuestionsByGroup(groupId)}
          onSelectQuestionFromGroup={handleSelectQuestionFromGroup}
          questionsGroups={commonGroups}
          questionsByGroup={questionsByGroup.data}
          questionsByGroupLoading={questionsByGroup.loading}
          activeGroupId={activeGroupId}
        />
      </Grid>
    </Grid>
  );
};

export { Step4 };
