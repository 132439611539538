import client, { formDataClient } from "./config";
import { ResponseAbstract, Dictionary } from "@/types";

export namespace DictionariesService {
  export function getApartmentTypes() {
    return client.get<ResponseAbstract<Dictionary.ApartmentDictionaryItem<Dictionary.ApartmentType>[]>>(
      "/api/reestr/mkd/apartment/get-type"
    );
  }

  export function getOwnershipTypes() {
    return client.get<ResponseAbstract<Dictionary.ApartmentDictionaryItem<Dictionary.ApartmentOwnership>[]>>(
      "/api/reestr/mkd/owner/get-ownership-type"
    );
  }

  export function getOCCStatus() {
    return client.get<ResponseAbstract<Dictionary.DictionaryItem<Dictionary.OCCStatus>[]>>("/api/data/occ/get-status");
  }

  export function getOCCType() {
    return client.get<ResponseAbstract<Dictionary.DictionaryItem<Dictionary.OCCType>[]>>("/api/data/occ/get-type");
  }

  export function getOCCForm() {
    return client.get<ResponseAbstract<Dictionary.DictionaryItem<Dictionary.OCCForm>[]>>("/api/data/occ/get-form");
  }

  export function getOCCLocation() {
    return client.get<ResponseAbstract<Dictionary.DictionaryItem<Dictionary.OCCLocation>[]>>(
      "/api/data/occ/get-location"
    );
  }

  export function getQuorum() {
    return client.get<ResponseAbstract<Dictionary.DictionaryItem<Dictionary.Quorum>[]>>("/api/data/occ/get-quorum");
  }

  export function saveDocument(file: File) {
    let formdata = new FormData();
    formdata.append("File", file);
    return formDataClient.post<ResponseAbstract<Dictionary.File>>("/api/document/save", formdata);
  }
}
