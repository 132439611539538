import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createOccMode } from "@/consts/state";
import { statuses } from "@/consts/statuses";
import { getLocalStorageData, objectToFormData } from "@/helpers/common";
import { api } from "@/services/api";
import { OccCommonData, OccType, OptionType } from "@/types/occ";
import { TemplateQuestions } from "@/types/template-questions";
import { activeAddress } from "@/app/store/slices/user";
import { RootState } from "..";

type QuestionsByGroupType = {
  Id: number;
  GroupId: number;
  GroupTitle: string;
  QuorumId: number;
  QuorumName: string;
  Title: string;
  Value: string;
  Order: number;
};

type CheckQuestionsType = {
  Title: string;
};
// TODO: потом нужно все создание осс перенести в единое хранилище
interface State {
  steps: any;
  errorStep: boolean;
  occId: null | number;
  mode: typeof createOccMode.create | typeof createOccMode.edit;
  updatedData: {};
  occCommonData: {
    data: OccCommonData | {};
    loading: boolean;
    fetchStatus: typeof statuses[keyof typeof statuses] | "";
    error: any;
  };
  draft: {
    data: Array<OccType>;
    loading: boolean;
    fetchStatus: typeof statuses[keyof typeof statuses] | "";
    error: any;
    loadingDelete: boolean;
    errorDelete: any;
  };
  updateOcc: {
    [key: string]: any;
    data: any;
    loading: boolean;
    fetchStatus: typeof statuses[keyof typeof statuses] | "";
    error: any;
    showNotification: boolean;
    showNotificationError: boolean;
  };
  locationDictionary: {
    data: Array<OptionType>;
    loading: boolean;
    fetchStatus: typeof statuses[keyof typeof statuses] | "";
    error: any;
  };
  meetingDictionary: {
    data: Array<OptionType>;
    loading: boolean;
    fetchStatus: typeof statuses[keyof typeof statuses] | "";
    error: any;
  };
  typeDictionary: {
    data: Array<OptionType>;
    loading: boolean;
    fetchStatus: typeof statuses[keyof typeof statuses] | "";
    error: any;
  };
  questions: {
    data: any;
    loading: boolean;
    fetchStatus: typeof statuses[keyof typeof statuses] | "";
    error: any;
  };
  groupsDefault: {
    data: any;
    loading: boolean;
    error: string;
  };
  groupsTemplate: {
    data: any;
    loading: boolean;
    error: string;
  };
  questionsByGroup: {
    data: Array<QuestionsByGroupType>;
    loading: boolean;
    error: any;
  };
  questionsTemplateByGroup: {
    loading: boolean;
    error: any;
  };
  commonGroups: Array<any>;
  checkQuestions: Array<CheckQuestionsType>;
}

const initialState: State = {
  steps: {},
  errorStep: false,
  occId: null,
  mode: createOccMode.create,
  updatedData: {},
  occCommonData: { data: {}, loading: false, fetchStatus: "", error: "" },
  draft: {
    data: [],
    loading: false,
    fetchStatus: "",
    error: "",
    loadingDelete: false,
    errorDelete: "",
  },
  updateOcc: {
    data: null,
    loading: false,
    fetchStatus: "",
    error: "",
    showNotification: false,
    showNotificationError: false,
  },
  locationDictionary: {
    data: [],
    loading: false,
    fetchStatus: "",
    error: "",
  },
  meetingDictionary: {
    data: [],
    loading: false,
    fetchStatus: "",
    error: "",
  },
  typeDictionary: {
    data: [],
    loading: false,
    fetchStatus: "",
    error: "",
  },
  questions: {
    data: [],
    loading: false,
    fetchStatus: "",
    error: "",
  },
  groupsDefault: {
    data: [],
    loading: false,
    error: "",
  },
  groupsTemplate: {
    data: [],
    loading: false,
    error: "",
  },
  questionsByGroup: {
    data: [],
    loading: false,
    error: "",
  },
  questionsTemplateByGroup: {
    loading: false,
    error: "",
  },
  commonGroups: [],
  checkQuestions: [],
};

const fetchDraft = createAsyncThunk("/occ/get-all", async (data: undefined, { getState, rejectWithValue }) => {
  try {
    const rootState = getState() as RootState;
    const address = activeAddress(rootState);

    const response = await api.occ.getOccAll({
      StatusId: 0,
      FiasId: address?.FiasId,
    });

    return response.data.Data;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data?.Data?.Message);
  }
});

const fetchDeleteDraft = createAsyncThunk(
  "/occ/delete",
  async ({ OccId, getDraft }: { OccId: number; getDraft: any }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.occ.deleteOcc({ OccId });
      dispatch(getDraft());

      return response.data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchCommonData = createAsyncThunk(
  "/occ/get-order",
  async ({ OccId, callback }: { OccId: any; callback: (data: any) => void }, { rejectWithValue }) => {
    try {
      const response = await api.occ.getOccData({ OccId });
      callback(response.data.Data);

      return response.data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchLocationDictionary = createAsyncThunk(
  "/data/occ/get-location",
  async (data: undefined, { rejectWithValue }) => {
    try {
      const response = await api.occ.getLocation();

      return response.data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchMeetingFormDictionary = createAsyncThunk(
  "/data/occ/get-form",
  async (data: undefined, { rejectWithValue }) => {
    try {
      const response = await api.occ.getOccFroms();

      return response.data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchTypeDictionary = createAsyncThunk("/data/occ/get-type", async (data: undefined, { rejectWithValue }) => {
  try {
    const response = await api.occ.getOccType();

    return response.data.Data;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data?.Data?.Message);
  }
});

const fetchQuestions = createAsyncThunk("/occ/get-questions", async (OccId: any, { rejectWithValue }) => {
  try {
    const response = await api.occ.getQuestions({ OccId });

    return response.data.Data;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data?.Data.Message);
  }
});

const fetchUpdateOcc = createAsyncThunk("/occ/update", async (data: undefined, { rejectWithValue }) => {
  try {
    const data = getLocalStorageData(["step1", "step2", "step3", "step4"]);

    const response = await api.occ.updateOcc(
      objectToFormData({
        ...data,
      })
    );

    return response.data.Data;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data?.Data.Message);
  }
});

const fetchDefaultGroups = createAsyncThunk(
  "/default-data/occ-questions/get-groups",
  async (data: undefined, { rejectWithValue }) => {
    try {
      const response = await api.questions.getGroups();

      const mappedGroups = response.data.Data.map((group: any) => ({ ...group, isDefault: true }));

      return mappedGroups;
    } catch (err: any) {
      rejectWithValue(err?.response?.data?.Data.Message);
    }
  }
);

const fetchTemplateGroups = createAsyncThunk(
  "/occ/templates/groups/get",
  async (data: undefined, { rejectWithValue }) => {
    try {
      const response = await api.templateGroups.getGroups();

      const mappedGroups = response.data.Data.map((group: any) => ({ ...group, isDefault: false }));

      return mappedGroups;
    } catch (err: any) {
      rejectWithValue(err?.response?.data?.Data.Message);
    }
  }
);

const fetchQuestionsByGroup = createAsyncThunk(
  "/default-data/occ-questions/get-questions-by-group",
  async (groupId: number, { rejectWithValue }) => {
    try {
      const response = await api.questions.getQuestionsByGrpup(groupId);

      return { data: response.data.Data, groupId };
    } catch (err: any) {
      rejectWithValue(err?.response?.data?.Data.Message);
    }
  }
);

const fetchTemplateQuestionsByGroup = createAsyncThunk(
  "template-questions/questions/get-by-group",
  async (data: TemplateQuestions.RequestListQuestions, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();

      if (
        state.templateQuestions.groupQuestions.data.some(
          (question: TemplateQuestions.QuestionResponse) => question.GroupId === data.GroupId
        )
      ) {
        return state.templateQuestions.groupQuestions.data;
      }

      const response = await api.templateQuestions.getQuestions({
        GroupId: data.GroupId,
        Title: data.Title,
      });

      return response.data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const createOccSlice = createSlice({
  name: "createOcc",
  initialState,
  reducers: {
    setErrorStep: (state) => {
      state.errorStep = true;
    },
    resetErrorStep: (state) => {
      state.errorStep = false;
    },
    setOccId: (state, action) => {
      state.occId = action.payload;
    },
    clearDraftError: (state) => {
      state.draft.error = "";
    },
    clearQuestionsError: (state) => {
      state.questions.error = "";
    },
    setModeOcc: (state, action) => {
      state.mode = action.payload;
    },
    closeUpdateNotification: (state, action) => {
      state.updateOcc[action.payload] = false;
      state.updateOcc.data = null;
    },
    clearQuestions: (state) => {
      state.questions = initialState.questions;
    },
    checkQuestion: (state, action) => {
      state.checkQuestions.push(action.payload);
    },
    deleteCheckQuestion: (state, action) => {
      state.checkQuestions = state.checkQuestions.filter((question) => question.Title !== action.payload);
    },
    resetCheckQuestion: (state) => {
      state.checkQuestions = initialState.checkQuestions;
    },
    resetCommonGroup: (state) => {
      state.commonGroups = initialState.commonGroups;
    },
  },
  extraReducers: (builder) => {
    // @fetchDraft - request
    builder.addCase(fetchDraft.pending, (state: State) => {
      state.draft.loading = true;
      state.draft.fetchStatus = statuses.pending;
      state.draft.error = "";
    });
    builder.addCase(fetchDraft.fulfilled, (state: State, action) => {
      state.draft.loading = false;
      state.draft.fetchStatus = statuses.success;
      state.draft.data = action.payload;
    });
    builder.addCase(fetchDraft.rejected, (state: State, action) => {
      state.draft.loading = false;
      state.draft.fetchStatus = statuses.reject;

      if (action.payload) {
        state.draft.error = action.payload;
      }
    });

    // @fetchDeleteDraft - request
    builder.addCase(fetchDeleteDraft.pending, (state: State) => {
      state.draft.loadingDelete = true;
      state.draft.errorDelete = "";
    });
    builder.addCase(fetchDeleteDraft.fulfilled, (state: State, action) => {
      state.draft.loadingDelete = false;
      state.draft.data = action.payload;
    });
    builder.addCase(fetchDeleteDraft.rejected, (state: State, action) => {
      state.draft.loadingDelete = false;

      if (action.payload) {
        state.draft.errorDelete = action.payload;
      }
    });

    // @fetchCommonData - request
    builder.addCase(fetchCommonData.pending, (state: State) => {
      state.occCommonData.loading = true;
      state.occCommonData.error = "";
    });
    builder.addCase(fetchCommonData.fulfilled, (state: State, action) => {
      state.occCommonData.loading = false;
      state.occCommonData.data = action.payload;
    });
    builder.addCase(fetchCommonData.rejected, (state: State, action) => {
      state.occCommonData.loading = false;

      if (action.payload) {
        state.occCommonData.error = action.payload;
      }
    });

    // @fetchLocationDictionary - request
    builder.addCase(fetchLocationDictionary.pending, (state: State) => {
      state.locationDictionary.loading = true;
      state.locationDictionary.error = "";
    });
    builder.addCase(fetchLocationDictionary.fulfilled, (state: State, action) => {
      state.locationDictionary.loading = false;
      state.locationDictionary.data = action.payload;
    });
    builder.addCase(fetchLocationDictionary.rejected, (state: State, action) => {
      state.locationDictionary.loading = false;

      if (action.payload) {
        state.locationDictionary.error = action.payload;
      }
    });

    // @fetchMeetingFormDictionary - request
    builder.addCase(fetchMeetingFormDictionary.pending, (state: State) => {
      state.meetingDictionary.loading = true;
      state.meetingDictionary.error = "";
    });
    builder.addCase(fetchMeetingFormDictionary.fulfilled, (state: State, action) => {
      state.meetingDictionary.loading = false;
      state.meetingDictionary.data = action.payload;
    });
    builder.addCase(fetchMeetingFormDictionary.rejected, (state: State, action) => {
      state.meetingDictionary.loading = false;

      if (action.payload) {
        state.meetingDictionary.error = action.payload;
      }
    });

    // @fetchTypeDictionary - request
    builder.addCase(fetchTypeDictionary.pending, (state: State) => {
      state.typeDictionary.loading = true;
      state.typeDictionary.error = "";
    });
    builder.addCase(fetchTypeDictionary.fulfilled, (state: State, action) => {
      state.typeDictionary.loading = false;
      state.typeDictionary.data = action.payload;
    });
    builder.addCase(fetchTypeDictionary.rejected, (state: State, action) => {
      state.typeDictionary.loading = false;

      if (action.payload) {
        state.typeDictionary.error = action.payload;
      }
    });

    // @fetchUpdateOcc - request
    builder.addCase(fetchUpdateOcc.pending, (state: State) => {
      state.updateOcc.loading = true;
      state.updateOcc.error = "";
    });
    builder.addCase(fetchUpdateOcc.fulfilled, (state: State, action) => {
      state.updateOcc.loading = false;
      state.updateOcc.data = action.payload;
      state.updateOcc.showNotification = true;
    });
    builder.addCase(fetchUpdateOcc.rejected, (state: State, action) => {
      state.updateOcc.loading = false;
      state.updateOcc.showNotificationError = true;

      if (action.payload) {
        state.updateOcc.error = action.payload;
      }
    });

    // @fetchQuestions - request
    builder.addCase(fetchQuestions.pending, (state: State) => {
      state.questions.loading = true;
      state.questions.error = "";
    });
    builder.addCase(fetchQuestions.fulfilled, (state: State, action) => {
      state.questions.loading = false;
      state.questions.data = action.payload;
    });
    builder.addCase(fetchQuestions.rejected, (state: State, action) => {
      state.questions.loading = false;

      if (action.payload) {
        state.questions.error = action.payload;
      }
    });

    // @fetchDefaultGroups - request
    builder.addCase(fetchDefaultGroups.pending, (state: State) => {
      state.groupsDefault.loading = true;
      state.groupsDefault.error = "";
    });
    builder.addCase(fetchDefaultGroups.fulfilled, (state: State, action) => {
      state.groupsDefault.loading = false;
      state.groupsDefault.data = action.payload;
      state.commonGroups = [...state.commonGroups, ...action.payload];
    });
    builder.addCase(fetchDefaultGroups.rejected, (state: State, action) => {
      state.groupsDefault.loading = false;

      if (action.payload) {
        (state.groupsDefault.error as any) = action.payload;
      }
    });

    // @fetchTemplateGroups - request
    builder.addCase(fetchTemplateGroups.pending, (state: State) => {
      state.groupsTemplate.loading = true;
      state.groupsTemplate.error = "";
    });
    builder.addCase(fetchTemplateGroups.fulfilled, (state: State, action: any) => {
      state.groupsTemplate.loading = false;
      state.groupsTemplate.data = action.payload;
      state.commonGroups = [...state.commonGroups, ...action.payload];
    });
    builder.addCase(fetchTemplateGroups.rejected, (state: State, action) => {
      state.groupsTemplate.loading = false;

      if (action.payload) {
        (state.groupsTemplate.error as any) = action.payload;
      }
    });

    // @fetchQuestionsByGroup - request
    builder.addCase(fetchQuestionsByGroup.pending, (state: State) => {
      state.questionsByGroup.loading = true;
      state.questionsByGroup.error = "";
      state.questionsByGroup.data = [];
    });
    builder.addCase(fetchQuestionsByGroup.fulfilled, (state: State, action: any) => {
      state.questionsByGroup.loading = false;
      state.questionsByGroup.data = action.payload.data;
    });
    builder.addCase(fetchQuestionsByGroup.rejected, (state: State, action) => {
      state.questionsByGroup.loading = false;

      if (action.payload) {
        state.questionsByGroup.error = action.payload;
      }
    });

    // @fetchTemplateQuestionsByGroup - request
    builder.addCase(fetchTemplateQuestionsByGroup.pending, (state: State) => {
      state.questionsTemplateByGroup.loading = true;
      state.questionsTemplateByGroup.error = "";
      state.questionsByGroup.data = [];
    });
    builder.addCase(fetchTemplateQuestionsByGroup.fulfilled, (state: State, action: any) => {
      state.questionsTemplateByGroup.loading = false;
      state.questionsByGroup.data = action.payload;
    });
    builder.addCase(fetchTemplateQuestionsByGroup.rejected, (state: State, action) => {
      state.questionsTemplateByGroup.loading = false;

      if (action.payload) {
        state.questionsTemplateByGroup.error = action.payload;
      }
    });
  },
});

export {
  fetchDraft,
  fetchDeleteDraft,
  fetchCommonData,
  fetchLocationDictionary,
  fetchMeetingFormDictionary,
  fetchTypeDictionary,
  fetchUpdateOcc,
  fetchQuestions,
  fetchQuestionsByGroup,
  fetchDefaultGroups,
  fetchTemplateGroups,
  fetchTemplateQuestionsByGroup,
};
export const {
  setErrorStep,
  resetErrorStep,
  setOccId,
  clearDraftError,
  clearQuestionsError,
  setModeOcc,
  closeUpdateNotification,
  clearQuestions,
  checkQuestion,
  deleteCheckQuestion,
  resetCheckQuestion,
  resetCommonGroup,
} = createOccSlice.actions;

export default createOccSlice.reducer;
