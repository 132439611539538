import { IApiModule } from "../types";

export default {
  saveDocument: (File: any) => ({
    method: "POST",
    url: "api/document/save",
    headers: {
      "content-type": "multipart/form-data",
    },
    data: {
      File,
    },
  }),
  getDocument: (Id: number) => ({
    method: "GET",
    url: `api/document/get?Id=${Id}`,
  }),
} as IApiModule;
