import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { RoutesManager } from "../../features/routes-manager";
import { Profile } from "../../types";
import { activeAddress } from "@/app/store/slices/user";
import { paths } from "@/consts/routes";
import styles from "./sidebar.module.scss";

const Sidebar = () => {
  const profile = useSelector((state: RootState) => state.user.profile);
  const company = useSelector((state: RootState) => state.user.company);
  const address = useSelector(activeAddress);

  const isDisabled: boolean = useMemo(() => company?.Type === Profile.CompanyType.UK && !address, [company, address]);

  return (
    <Box className={styles.menuContent}>
      <aside>
        <nav className={styles.menu}>
          <RoutesManager disabled={isDisabled} />
        </nav>
      </aside>
      <footer>
        <aside className={styles.documents}>
          {profile?.role === Profile.UserRole.Company && (
            <NavLink className={styles.link} to={paths.settings}>
              Настройки системы
            </NavLink>
          )}
          <a href="/documents/private-policy.pdf" download className={styles.link}>
            Политика конфиденциальности
          </a>
          <a href="/documents/about-system.pdf" download className={styles.link}>
            Положение сервиса
          </a>
          <a href="https://locolo.tatarstan.ru/" target="_blank" rel="noreferrer" className={styles.link}>
            Мобильное приложение
          </a>
        </aside>
        <aside className={styles.support}>
          <p>Техническая поддержка:</p>
          <a className={styles.mail} href="mailto: locolo.help@tatar.ru">
            locolo.help@tatar.ru
          </a>
          <a href="tel:8 843 525-70-63">8 (843) 525-70-63</a>
        </aside>
      </footer>
    </Box>
  );
};

export default Sidebar;
