import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { BaseModal, BaseModalHeader, BaseModalContent, BaseModalActions } from "@/components/BaseModal";

import { RootState, useAppDispatch } from "@/app/store";
import {
  createUsefulContacts,
  updateUsefulContacts,
  updateMyContacts,
  toggleEditModal,
} from "@/app/store/slices/contacts";
import { UsefulContacts } from "@/types/contacts";
import { Profile } from "@/types";

import styles from "./add.module.scss";
import { AddressLabel } from "./address";
import { useEffect, useMemo, useState } from "react";
import BaseInput from "@/components/base-input";
import PhoneInput from "@/components/base-input/phoneInput";
import BaseButton from "@/components/base-button";
import AddressesSearchDropdown from "@/features/address-search";
import { House } from "@/types/house";
import BaseLabel from "@/components/BaseLabel";
import { showSnackbar, SnackbarType } from "@/app/store/slices/snackbar";

const AddContact = () => {
  const dispatch = useAppDispatch();
  const { isLoading, isOpen, usefulContactId } = useSelector((state: RootState) => state.contacts.editModal);
  const usefulContacts = useSelector((state: RootState) => state.contacts.contactsAll.data);
  const userCommon = useSelector((state: RootState) => state.user.company);
  const [form, setForm] = useState<UsefulContacts.EditContactForm>(new UsefulContacts.EditContactForm({}));
  const addresses = useSelector((state: RootState) => state.user.company?.Addresses);

  const selectedContact: UsefulContacts.Item | null = useMemo(
    () => usefulContacts.find((it) => it.Id === usefulContactId) ?? null,
    [usefulContactId, usefulContacts]
  );

  const isUK: boolean = useMemo(
    () => usefulContactId != null && usefulContactId < 0 && Math.abs(usefulContactId) === userCommon?.Id,
    [usefulContactId, userCommon]
  );

  const isSNTProfile: boolean = useMemo(() => userCommon?.Type === Profile.CompanyType.SNT, [userCommon]);

  const addressesSelectable = useMemo(
    () =>
      addresses
        ? addresses.map((it) => {
            return { ...it, checked: form.Houses?.includes(it.Id) || false };
          })
        : [],
    [addresses, form.Houses]
  );

  const isAllChecked: boolean = useMemo(() => addressesSelectable.every((it) => it.checked), [addressesSelectable]);

  const onCloseModal = () => {
    dispatch(toggleEditModal({ isOpen: false }));
  };

  const handleError = (payload: any) => {
    if (payload.Errors) {
      Object.keys(payload.Errors).forEach((key: any) => {
        const message = Array.isArray(payload.Errors[key]) ? payload.Errors[key][0] : payload.Errors[key];
        switch (key) {
          case "Name": {
            setForm((prevState) => ({
              ...prevState,
              Name: { ...form.Name, error: message },
            }));
            break;
          }

          case "Phone":
          case "PhoneNum": {
            setForm((prevState) => ({
              ...prevState,
              PhoneNum: { ...form.PhoneNum, error: message },
            }));
            break;
          }

          case "Email": {
            setForm((prevState) => ({
              ...prevState,
              Email: { ...form.Email, error: message },
            }));
            break;
          }

          case "Address":
          case "Geopoint": {
            setForm((prevState) => ({
              ...prevState,
              Address: { ...form.Address, error: message },
            }));
            break;
          }

          case "Site": {
            if (form.Site) {
              setForm((prevState: any) => ({
                ...prevState,
                Site: { ...form.Site, error: message },
              }));
            }
            break;
          }

          case "Houses": {
            setForm((prevState) => ({
              ...prevState,
              HousesError: message,
            }));
            break;
          }

          default: {
            setForm((prevState) => ({ ...prevState, error: message }));
            dispatch(showSnackbar({ key: "add-contact-error", body: message, type: SnackbarType.ERROR }));
            break;
          }
        }
      });
    } else {
      setForm((prevState) => ({ ...prevState, error: payload.Message }));
      dispatch(showSnackbar({ key: "add-contact-error", body: payload.Message, type: SnackbarType.ERROR }));
    }
  };

  const isFormValid = () => {
    const validator = UsefulContacts.EditContactFormValidator.get().isInvalid(form, isUK);

    if (validator) {
      setForm((prevState) => ({
        ...prevState,
        Name: {
          ...form.Name,
          error: validator.Name,
        },
        PhoneNum: {
          ...form.PhoneNum,
          error: validator.PhoneNum,
        },
        Email: {
          ...form.Email,
          error: validator.Email,
        },
        Site:
          prevState.Site && validator.Site
            ? {
                ...prevState.Site,
                error: validator.Site,
              }
            : prevState.Site,
        Address: {
          ...form.Address,
          error: validator.Address,
        },
        HousesError: validator.Houses,
      }));
    }
    return !validator;
  };

  const saveUsefulContact = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    Object.keys(form).forEach((key: string) => {
      if (typeof (form as any)[key] === "object" && (form as any)[key]?.error) {
        setForm((prevState) => ({
          ...prevState,
          [key]: { ...(form as any)[key], error: "" },
        }));
      }
    });
    setForm((prevState) => ({ ...prevState, error: "", HousesError: "" }));

    if (!isFormValid()) {
      return;
    }

    try {
      if (isUK) {
        await dispatch(
          updateMyContacts({
            Phone: form.PhoneNum.value,
            Email: form.Email.value,
            Site: form.Site?.value ? form.Site?.value : null,
            Address: form.Address.value,
          })
        ).unwrap();
      } else if (selectedContact) {
        await dispatch(
          updateUsefulContacts({
            Id: selectedContact.Id,
            Name: form.Name.value,
            PhoneNum: form.PhoneNum.value,
            Email: form.Email.value,
            Houses: isSNTProfile || isAllChecked ? [] : form.Houses,
            AllHouses: isSNTProfile || isAllChecked,
            Address: form.Address.value,
            Geopoint: form.Geopoint,
          })
        ).unwrap();
      } else {
        await dispatch(
          createUsefulContacts({
            Name: form.Name.value,
            PhoneNum: form.PhoneNum.value,
            Email: form.Email.value,
            Houses: isSNTProfile || isAllChecked ? [] : form.Houses,
            AllHouses: isSNTProfile || isAllChecked,
            Address: form.Address.value,
            Geopoint: form.Geopoint,
          })
        ).unwrap();
      }

      onCloseModal();

      const successMessage = selectedContact ? "Изменения успешно внесены" : "Полезный контакт успешно добавлен";
      dispatch(
        showSnackbar({
          key: selectedContact ? `edit-contact-${selectedContact.Id}` : "add-contact",
          body: successMessage,
          type: SnackbarType.SUCCESS,
        })
      );
    } catch (error) {
      handleError(error);
    }
  };

  const handleCheckbox = (id: number, isCheck: boolean) => {
    const ids = new Set(form.Houses);
    if (isCheck) ids.add(id);
    else ids.delete(id);

    setForm((prevState) => ({ ...prevState, Houses: Array.from(ids) }));
  };

  const handleAllCheckBox = () => {
    if (isAllChecked) {
      setForm((prevState) => ({ ...prevState, Houses: [] }));
    } else {
      setForm((prevState) => ({
        ...prevState,
        Houses: addresses ? addresses.map((it) => it.Id) : [],
      }));
    }
  };

  const onAddressSelectHandle = (address: House.Address) => {
    setForm((prevState) => ({
      ...prevState,
      Geopoint: {
        Lat: address.Lat,
        Lon: address.Lon,
      },
      Address: {
        ...form.Address,
        value: address.Value,
      },
    }));
  };

  const handleAddressClear = () => {
    setForm((prevState) => ({
      ...prevState,
      Geopoint: null,
      Address: {
        ...form.Address,
        value: "",
      },
    }));
  };

  useEffect(() => {
    setForm(
      new UsefulContacts.EditContactForm({
        contacts: selectedContact ?? undefined,
        company: isUK && userCommon ? userCommon : undefined,
        housesIds: addresses ? addresses.map((it) => it.Id) : [],
      })
    );
  }, [isOpen]);

  const updateFormFieldValue = (key: "Name" | "PhoneNum" | "Email" | "Address" | "Site", value: string) => {
    setForm((prevState) => ({
      ...prevState,
      [key]: { ...form[key], value },
    }));
  };

  return (
    <BaseModal isOpen={isOpen} maxWidth={isSNTProfile ? "640px" : "866px"} minWidth={isSNTProfile ? "640px" : "866px"}>
      <form onSubmit={saveUsefulContact}>
        <BaseModalHeader
          variant="bordered"
          title={!!usefulContactId ? "Редактировать контакт" : "Добавить контакт"}
          subtitle="Полезные контакты помогут пользователям находить важные службы в приложении Локоло"
          onClose={onCloseModal}
        />

        <BaseModalContent sx={{ padding: "0" }}>
          <Grid container className={styles.content}>
            <Grid item xs={6} className={[styles.form, isSNTProfile ? styles.snt_form : undefined].join(" ")}>
              <BaseInput
                value={form.Name.value}
                label="Название"
                required={form.Name.isRequired}
                placeholder="Например: Пожарная служба"
                disabled={isUK}
                errorMessage={form.Name.error}
                onChange={(value) => updateFormFieldValue("Name", value)}
              />

              <PhoneInput
                type="tel"
                value={form.PhoneNum.value}
                label="Телефон"
                required={form.Name.isRequired}
                placeholder="+7 (999) 999-99-99"
                errorMessage={form.PhoneNum.error}
                onChange={(value) => updateFormFieldValue("PhoneNum", value)}
              />

              <BaseInput
                value={form.Email.value}
                label="Электронная почта"
                required={form.Email.isRequired}
                placeholder="Сompany@gmail.com"
                errorMessage={form.Email.error}
                onChange={(value) => updateFormFieldValue("Email", value)}
              />

              <Box>
                <BaseLabel required={form.Address.isRequired}>Адрес</BaseLabel>
                <AddressesSearchDropdown
                  address={form.Address.value}
                  errorMessage={form.Address.error}
                  placeholder="г.Казань, ул.Лермонтова, д.1"
                  onSelect={(add) => onAddressSelectHandle(add)}
                  onClear={handleAddressClear}
                />
              </Box>

              {!!form.Site && (
                <BaseInput
                  value={form.Site.value}
                  label="Сайт"
                  required={form.Site.isRequired}
                  placeholder="Сайт"
                  errorMessage={form.Site.error}
                  onChange={(value) => updateFormFieldValue("Site", value)}
                />
              )}
            </Grid>

            {!isSNTProfile && (
              <Grid item xs={6} className={`${styles.addresses_wrapper} ${isUK && styles.disabled}`}>
                <Box className={`${styles.addresses} ${!!form.HousesError && styles.has_error}`}>
                  {form.HousesError && <p className={styles.addresses_error}>{form.HousesError}</p>}
                  <BaseLabel className={styles.addresses_label}>Показывать полезный контакт для:</BaseLabel>
                  <Box className={styles.addresses_list}>
                    <>
                      <AddressLabel
                        onChange={handleAllCheckBox}
                        all
                        label="Выделить все"
                        checked={isAllChecked}
                        disabled={isUK}
                      />
                      {addressesSelectable.map((address) => (
                        <AddressLabel
                          key={address.Id}
                          onChange={(isCheck: boolean) => handleCheckbox(address.Id, isCheck)}
                          label={address.Address}
                          checked={address.checked}
                          disabled={isUK}
                        />
                      ))}
                    </>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </BaseModalContent>

        <BaseModalActions variant="bordered">
          <BaseButton variant="secondary" onClick={onCloseModal}>
            Отменить
          </BaseButton>
          <BaseButton isLoading={isLoading} type="submit">
            {!!usefulContactId ? "Сохранить" : "Добавить"}
          </BaseButton>
        </BaseModalActions>
      </form>
    </BaseModal>
  );
};

export default AddContact;
