import { forwardRef } from "react";
import { InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import { useFetchHouseAddress } from "../hooks/useFetchHouseAddress";
import { LabelTooltip } from "../../../components/tooltip";

export const HouseAddress = forwardRef(
  ({ onChange, name, onBlur, error, defaultValue, disabled }: any, ref) => {
    const { loading, data } = useFetchHouseAddress();

    return (
      <>
        <InputLabel htmlFor="HouseAddress" sx={{ display: "flex", gap: "4px" }}>
          Адрес дома
          <LabelTooltip
            title="Из списка выберите адрес дома, для которого хотите создать собрание."
            placement="right"
            arrow
            enterDelay={500}
          />
        </InputLabel>
        <Select
          id="HouseAddress"
          fullWidth
          disabled={!loading || disabled}
          ref={ref}
          onChange={onChange}
          name={name}
          onBlur={onBlur}
          error={error}
          defaultValue={defaultValue}
        >
          {data.map((item) => (
            <MenuItem key={item.FiasId} value={item.FiasId}>
              {item.Address}
            </MenuItem>
          ))}
        </Select>
      </>
    );
  }
);
