import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Snackbar,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation, useNavigate } from "react-router-dom";

import { api } from "../../../services/api";
import { Spinner } from "../../../components/spinner";
import { QuestionItem } from "../components/questionItem";
import { useFetchHouseAddress } from "../hooks/useFetchHouseAddress";
import { useSelector } from "react-redux";
import { setOccById } from "../../../app/store/slices/occ";
import styles from "../create.module.scss";
import { RootState, useAppDispatch } from "../../../app/store";
import { getLocalStorageData } from "../../../helpers/common";
import { createOccMode } from "../../../consts/state";
import {
  closeUpdateNotification,
  fetchLocationDictionary,
  fetchMeetingFormDictionary,
  fetchTypeDictionary,
} from "../../../app/store/slices/createOccOld";
import { paths } from "../../../consts/routes";
import { useCurrentMode } from "../hooks/useCurrentMode";
import { Instruction } from "../components/step5/instruction";
import { AuthorView } from "../components/step5/authorView";
import { fetchQuorums } from "../../../app/store/slices/app";
import { AuthorFields, orderFields } from "../../../consts/labels";
import { AdministratorView } from "../components/step5/administratorView";
import { useQuestionsLocalStorage } from "../hooks/useQuestionsLocalStorage";
import { Confirm } from "../components/step5/confirm";
import { setCreateOccModal } from "../../../app/store/slices/modal";

// @TODO повторения вынести отдельно
// @TODO дописать типы
// @TODO вынести в кастомные хуки
interface IStep5 {
  createOcc: () => void;
  error: string;
}

const Step5 = ({ createOcc, error }: IStep5) => {
  const { state: occId } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { locationDictionary, meetingDictionary, typeDictionary, updateOcc } = useSelector(
    (state: RootState) => state.createOccOld
  );
  const quorums = useSelector((state: RootState) => state.app.quorums);
  const createOccModal = useSelector((state: RootState) => state.modal.createOcc);

  const [load, setLoad] = useState(occId);
  const [data, setData] = useState<any>(null);
  const { data: fiasData } = useFetchHouseAddress();
  const mode = useCurrentMode();
  const [questionsLocalStorage] = useQuestionsLocalStorage();
  const isEditMode = mode === createOccMode.edit;

  const updatedData = getLocalStorageData(["step1", "step2", "step3", "step4"]);

  const getOccData = async () => {
    try {
      const response = await api.occ.getOccById(occId);
      setData(response.data.Data);
      dispatch(setOccById(response.data.Data));
      setLoad(false);
    } catch (err: any) {
      setLoad(false);
    }
  };

  useEffect(() => {
    if (updateOcc.showNotification && updateOcc.data) {
      setTimeout(() => navigate(paths.myOcc), 3000);
    }
  }, [updateOcc.showNotification, updateOcc.showNotificationError]);

  useEffect(() => {
    if (occId) {
      dispatch(fetchLocationDictionary());
      dispatch(fetchMeetingFormDictionary());
      dispatch(fetchTypeDictionary());
      getOccData();
    }

    closeUpdateNotification("showNotification");

    return () => {
      dispatch(closeUpdateNotification("showNotification"));
      dispatch(closeUpdateNotification("showNotificationError"));
    };
  }, []);

  const handleCloseNotification = (prop: "showNotification" | "showNotificationError") => {
    dispatch(closeUpdateNotification(prop));
  };

  const handleCloseCreateOccModal = () => {
    dispatch(setCreateOccModal(false));
  };

  useEffect(() => {
    if (!quorums.data.length) {
      dispatch(fetchQuorums());
    }
  }, [quorums.data]);

  const renderOrderOcc = () => {
    return Object.entries(orderFields).map(([key, value]: any) => {
      if (!!data.Order[key]) {
        if (key === "FiasId") {
          const address = fiasData.find((el: any) => el.FiasId === data.Order[key])?.Address;
          return (
            <Box fontSize="14px" display="flex">
              <Box component="span" color="rgba(0, 0, 0, 0.54)" width="232px">
                {value}
              </Box>
              <Box component="span" paddingLeft={2}>
                {address}
              </Box>
            </Box>
          );
        }

        if (key === "ProcedureForFamiliarizationWithMaterialsOfOCC" || key === "ProcedureForMakingWrittenDecisions") {
          return (
            <Box fontSize="14px">
              <Box color="rgba(0, 0, 0, 0.54)" paddingBottom={1}>
                {value}
              </Box>
              <Box>{data.Order[key]}</Box>
            </Box>
          );
        }

        return (
          <Box fontSize="14px" display="flex">
            <Box component="span" color="rgba(0, 0, 0, 0.54)" width="232px">
              {value}
            </Box>
            <Box component="span" paddingLeft={2}>
              {data.Order[key]}
            </Box>
          </Box>
        );
      }

      return null;
    });
  };

  if (load) return <Spinner alt={"Загрузка..."} />;

  if (isEditMode) {
    return (
      <section className={styles.root}>
        <Grid container rowSpacing={2} columnSpacing={4}>
          <Instruction />
          {data && (
            <Grid item xs={12}>
              <AuthorView
                data={updatedData}
                meetingDictionary={meetingDictionary}
                locationDictionary={locationDictionary}
                typeDictionary={typeDictionary}
                fiasData={fiasData}
              />
              <AdministratorView data={updatedData} isEditMode={isEditMode} />
            </Grid>
          )}
          <Grid item xs={12} mt={2}>
            <Typography fontSize="20px" fontWeight={600}>
              Повестка
            </Typography>
          </Grid>
          {questionsLocalStorage &&
            questionsLocalStorage.map((el: any, indx: number) => (
              <QuestionItem data={el} index={indx} quorums={quorums} />
            ))}
        </Grid>
        <Snackbar
          open={updateOcc.showNotification && updateOcc.data}
          autoHideDuration={3000}
          onClose={() => handleCloseNotification("showNotification")}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={() => handleCloseNotification("showNotification")}
            severity="success"
            icon={false}
            sx={{ width: "336px", backgroundColor: "#000", color: "#fff" }}
          >
            Внесены изменения
          </Alert>
        </Snackbar>
        <Snackbar
          open={updateOcc.showNotificationError}
          autoHideDuration={3000}
          onClose={() => handleCloseNotification("showNotificationError")}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={() => handleCloseNotification("showNotificationError")}
            severity="error"
            sx={{ width: "100%" }}
          >
            {updateOcc.error}
          </Alert>
        </Snackbar>
      </section>
    );
  }

  return (
    <section className={styles.root}>
      <Confirm createOcc={createOcc} open={createOccModal.open} onClose={handleCloseCreateOccModal} error={error} />
      <Grid container rowSpacing={2} columnSpacing={4}>
        <Instruction />
        {data && (
          <Grid item xs={12}>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="secondary" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography fontFamily="GolosMedium">Порядок собрания</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>{renderOrderOcc()}</Stack>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="secondary" />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography fontFamily="GolosMedium">Инициатор</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  {Object.entries(AuthorFields[data.Author.Type]).map(([key, value]: any) => (
                    <Box fontSize="14px" display="flex">
                      <Box component="span" color="rgba(0, 0, 0, 0.54)" width="30%">
                        {value}{" "}
                      </Box>
                      <Box component="span" paddingLeft={2}>
                        {data.Author[key]}
                      </Box>
                    </Box>
                  ))}
                </Stack>
              </AccordionDetails>
            </Accordion>
            <AdministratorView data={data} isEditMode={isEditMode} />
          </Grid>
        )}
        <Grid item xs={12} mt={2}>
          <Typography fontSize="20px" fontWeight={600}>
            Повестка
          </Typography>
        </Grid>
        {data?.Questions && data?.Questions.map((el: any, indx: number) => <QuestionItem data={el} index={indx} />)}
      </Grid>
    </section>
  );
};

export { Step5 };
