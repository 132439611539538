import { useEffect, useRef, useState } from "react";
import { Button, Box, Stack, Stepper, Step, StepLabel } from "@mui/material";
import styles from "../create.module.scss";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { paths } from "../../../consts/routes";
import { Step1 } from "./step1";
import { Step2 } from "./step2";
import { Step3 } from "./step3";
import { Step4 } from "./step4";
import { Step5 } from "./step5";
import { api } from "../../../services/api";
import { DeleteModal } from "../components/deleteModal";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../app/store";
import { createOccMode } from "../../../consts/state";
import { fetchUpdateOcc } from "../../../app/store/slices/createOccOld";
import { setCreateOccModal } from "../../../app/store/slices/modal";

interface Props {
  step: number;
}

const Steps = ({ step }: Props) => {
  const childRef1 = useRef<any>();
  const childRef2 = useRef<any>();
  const childRef3 = useRef<any>();
  const childRef4 = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const [errorCreating, setErrorCreating] = useState<string>("");

  const { errorStep, mode } = useSelector((state: RootState) => state.createOccOld);

  const navigation = useNavigate();
  const [activeStep, setActiveStep] = useState(step);
  const [open, setOpen] = useState(false);
  const { state: occId } = useLocation();

  const toNextStep = (id?: number) => {
    setActiveStep(activeStep + 1);
    console.log(`next step -> ${activeStep + 1}`);
    navigation(`/occ/create/${activeStep + 1}`, { state: id || occId });
  };

  const mergedMode = localStorage.getItem("modeOcc") !== null ? localStorage.getItem("modeOcc") : mode;

  const createOcc = async () => {
    try {
      await api.occ.finishedCreateOcc(occId);
      navigation(`/occ/create/finish/${occId}`);
    } catch (err: any) {
      console.error(err);
      setErrorCreating(err?.response?.data?.Data?.Message);
      return false;
    }
  };

  const steps = [
    {
      label: "1. Порядок собрания",
      component: <Step1 refForm={childRef1} toNextStep={toNextStep} />,
      ref: childRef1,
    },
    {
      label: "2. Инициатор",
      component: <Step2 refForm={childRef2} toNextStep={toNextStep} />,
      ref: childRef2,
    },
    {
      label: "3. Администратор",
      component: <Step3 refForm={childRef3} toNextStep={toNextStep} />,
      ref: childRef3,
    },
    {
      label: "4. Создание повестки",
      component: <Step4 />,
      ref: childRef4,
    },
    {
      label: "5. Просмотр",
      component: <Step5 createOcc={createOcc} error={errorCreating} />,
    },
  ];

  const handleSubmitStep = () => {
    const step = steps[activeStep - 1];
    console.log(step);
    console.log(step.ref?.current);

    if (activeStep === 4) {
      !errorStep && toNextStep();
    }

    if (activeStep === 5 && mergedMode !== createOccMode.edit) {
      dispatch(setCreateOccModal(true));
    }

    if (activeStep === 5 && mergedMode === createOccMode.edit) {
      dispatch(fetchUpdateOcc());
    }

    if (step?.ref?.current && activeStep !== 5 && activeStep !== 4) {
      console.log("event ====");
      step.ref.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    }
  };

  const isLastStep = () => {
    return activeStep === steps.length;
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    navigation(`/occ/create/${activeStep - 1}`, { state: occId });
  };

  const handleDelete = async () => {
    try {
      await api.occ.deleteOcc({ OccId: occId });
      navigation(paths.createOcc);
      setOpen(false);
    } catch (err: any) {
      console.error(err);
    }
  };

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setActiveStep(Number(step));
  }, [step]);

  let content = steps[activeStep - 1].component;

  if (!steps[activeStep - 1]) {
    return <Navigate to={paths.createOcc} replace={true} />;
  }

  return (
    <>
      <Box className={styles.stepContainer}>
        <Stepper activeStep={activeStep - 1} connector={null} sx={{ display: "flex", alignItems: "flex-end" }}>
          {steps.map((item) => (
            <Step key={item.label}>
              <StepLabel>{item.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {content}
        <Box className={styles.footer}>
          <div>
            {(!!occId || activeStep !== 1) && (
              <Button variant="outlined" color="error" onClick={onOpen}>
                Удалить собрание
              </Button>
            )}
          </div>
          <Stack spacing={2} direction="row">
            {activeStep !== 1 && (
              <Button variant="outlined" color="secondary" onClick={handleBack}>
                Назад
              </Button>
            )}
            <Button variant="contained" color="primary" onClick={handleSubmitStep}>
              {isLastStep() ? (mergedMode === createOccMode.edit ? "Сохранить изменения" : "Создать") : "Далее"}
            </Button>
          </Stack>
        </Box>
      </Box>
      <DeleteModal open={open} onClose={onClose} onDelete={handleDelete} />
    </>
  );
};

export { Steps };
