import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Content } from "@/components/content";
import { Layout } from "@/components/layout";
import styles from "./management-template.module.scss";
import { RootState, useAppDispatch } from "@/app/store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "@/components/spinner";
import { fetchQuorums } from "@/app/store/slices/dictionary/actions";
import { Templates } from "@/types";
import { Accordion, Collapsed, Expanded } from "@/components/base-accordion";
import { addIcon2, chevronDownIcon, editIcon } from "@/consts/icons";
import DeleteGroupModal from "./modals/delete-group";
import DeleteQuestionModal from "./modals/delete-question";
import EditGroupModal from "./modals/edit-group";
import EditQuestionModal from "./modals/edit-question";
import TemplateQuestionItem from "./template-question-item";
import {
  fetchTemplateGroups,
  fetchDefaultQuestionsByGroupId,
  fetchQuestionsByGroupId,
} from "@/app/store/slices/templates/actions";
import BaseButton from "@/components/base-button";
import BaseTooltip from "@/components/base-tooltip";

const BACK_LINK_STORAGE_NAME = "from-path";

const ManagementTemplate = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const groups = useSelector((state: RootState) => state.templates.groups);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedGroup, selectGroup] = useState<Templates.Group | null>(null);
  const [selectedQuestion, selectQuestion] = useState<Templates.Question | null>(null);
  const [isEditGroupModalShown, setEditGroupModalShown] = useState<boolean>(false);
  const [isDeleteGroupModalShown, setDeleteGroupModalShown] = useState<boolean>(false);
  const [isEditQuestionModalShown, setEditQuestionModalShown] = useState<boolean>(false);
  const [isDeleteQuestionModalShown, setDeleteQuestionModalShown] = useState<boolean>(false);

  const handleBackClick = (event: React.SyntheticEvent) => {
    const path = localStorage.getItem(BACK_LINK_STORAGE_NAME);
    if (path) {
      event.preventDefault();
      navigate(path);
    }
  };

  const handleCreateGroup = () => {
    selectGroup(null);
    setEditGroupModalShown(true);
  };

  const handleEditGroup = (event: React.SyntheticEvent, group: Templates.Group) => {
    event?.stopPropagation();
    selectGroup(group);
    setEditGroupModalShown(true);
  };

  const handleDeleteGroup = (event: React.SyntheticEvent, group: Templates.Group) => {
    event?.stopPropagation();
    selectGroup(group);
    setDeleteGroupModalShown(true);
  };

  const handleCreateQuestion = (event: React.SyntheticEvent, group: Templates.Group) => {
    event?.stopPropagation();
    selectGroup(group);
    selectQuestion(null);
    setEditQuestionModalShown(true);
  };

  const handleEditQuestion = ({ group, question }: { group: Templates.Group; question: Templates.Question }) => {
    selectGroup(group);
    selectQuestion(question);
    setEditQuestionModalShown(true);
  };

  const handleDeleteQuestion = (question: Templates.Question) => {
    selectQuestion(question);
    setDeleteQuestionModalShown(true);
  };

  const getQuestionsByGroup = async (key: Templates.GroupWithQuestions["key"]) => {
    const group = groups.find((it) => it.key === key);
    if (!group) {
      return Promise.resolve([]);
    }

    if (group.questions.length > 0) {
      return Promise.resolve(group.questions);
    }

    if (group.isDefault) {
      return await dispatch(fetchDefaultQuestionsByGroupId(group.group.Id)).unwrap();
    }
    return await dispatch(fetchQuestionsByGroupId(group.group.Id)).unwrap();
  };

  const fetch = async () => {
    setLoading(true);
    try {
      await Promise.all([dispatch(fetchTemplateGroups()).unwrap(), dispatch(fetchQuorums()).unwrap()]);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();

    if (location.state && (location.state.startsWith("/snt/create-occ") || location.state.startsWith("/create-occ"))) {
      localStorage.setItem(BACK_LINK_STORAGE_NAME, location.state);
    }

    return () => {
      localStorage.removeItem(BACK_LINK_STORAGE_NAME);
    };
  }, []);

  return (
    <Layout>
      <Content style={{ backgroundColor: "white" }}>
        <div>
          <div className={styles.header}>
            <div>
              <h1 className="title-h1">Управление шаблонами</h1>
              <NavLink className={styles.back_link} to="/settings" onClick={handleBackClick}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.20711 2.79289C7.59763 3.18342 7.59763 3.81658 7.20711 4.20711L4.41421 7H14C14.5523 7 15 7.44772 15 8C15 8.55228 14.5523 9 14 9H4.41421L7.20711 11.7929C7.59763 12.1834 7.59763 12.8166 7.20711 13.2071C6.81658 13.5976 6.18342 13.5976 5.79289 13.2071L1.29289 8.70711C0.902369 8.31658 0.902369 7.68342 1.29289 7.29289L5.79289 2.79289C6.18342 2.40237 6.81658 2.40237 7.20711 2.79289Z"
                    fill="#226DFF"
                  />
                </svg>
                <span className="sf-text-regular text--primary color--primary">Вернуться назад</span>
              </NavLink>
            </div>
            <BaseButton startIcon={addIcon2} onClick={handleCreateGroup}>
              Создать группу шаблонов
            </BaseButton>
          </div>

          {isLoading ? (
            <Spinner alt={"Загрузка..."} />
          ) : (
            <div className={styles.content}>
              <h3 className="headline-h3 sf-bold">Группы шаблонов:</h3>
              <Accordion className={styles.accordion} isAsync={true} callback={getQuestionsByGroup}>
                {groups.map((group) => (
                  <div className={styles.group} key={group.key}>
                    <Collapsed
                      className={styles.collapsed}
                      id={group.key}
                      customIcon={chevronDownIcon("#818C99")}
                      upAndDownIconAnimation={true}
                    >
                      <div className={styles.collapsed__content}>
                        <span className="sf-text-bold headline-h4">{group.group.Title}</span>
                        <div className={styles.group_actions}>
                          <BaseTooltip title="Редактировать группу шаблонов">
                            <BaseButton
                              className={styles.button}
                              variant="icon-basic"
                              onClick={(e) => handleEditGroup(e, group.group)}
                            >
                              {editIcon("#818C99")}
                            </BaseButton>
                          </BaseTooltip>
                          <BaseTooltip title="Удалить группу шаблонов">
                            <BaseButton
                              className={styles.button}
                              variant="icon-basic"
                              onClick={(e) => handleDeleteGroup(e, group.group)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M9 2.3C9 2.01997 9 1.87996 8.9455 1.773C8.89757 1.67892 8.82108 1.60243 8.727 1.5545C8.62004 1.5 8.48003 1.5 8.2 1.5H7.8C7.51997 1.5 7.37996 1.5 7.273 1.5545C7.17892 1.60243 7.10243 1.67892 7.0545 1.773C7 1.87996 7 2.01997 7 2.3V3H9V2.3ZM5.5 2.4V3.09091L1.71304 3.43518C1.30921 3.47189 1 3.81048 1 4.21598C1 4.64898 1.35102 5 1.78402 5H5.5H10.5H14.216C14.649 5 15 4.64898 15 4.21598C15 3.81048 14.6908 3.47189 14.287 3.43518L10.5 3.09091V2.4C10.5 1.55992 10.5 1.13988 10.3365 0.819014C10.1927 0.536771 9.96323 0.3073 9.68099 0.16349C9.36012 0 8.94008 0 8.1 0H7.9C7.05992 0 6.63988 0 6.31901 0.16349C6.03677 0.3073 5.8073 0.536771 5.66349 0.819014C5.5 1.13988 5.5 1.55992 5.5 2.4ZM3.0969 6.30816C3.04746 6.42715 3.06454 6.58088 3.0987 6.88834L3.76278 12.865C3.84667 13.62 3.88861 13.9975 4.06276 14.2824C4.21617 14.5333 4.43996 14.7336 4.70631 14.8584C5.00868 15 5.38849 15 6.1481 15H9.8519C10.6115 15 10.9913 15 11.2937 14.8584C11.56 14.7336 11.7838 14.5333 11.9372 14.2824C12.1114 13.9975 12.1533 13.62 12.2372 12.865L12.9013 6.88835C12.9355 6.58088 12.9525 6.42715 12.9031 6.30816C12.8597 6.20366 12.7821 6.11697 12.683 6.06228C12.5702 6 12.4155 6 12.1062 6H3.89381C3.58446 6 3.42978 6 3.31698 6.06228C3.21791 6.11697 3.14032 6.20366 3.0969 6.30816Z"
                                  fill="#818C99"
                                />
                              </svg>
                            </BaseButton>
                          </BaseTooltip>
                        </div>
                      </div>
                    </Collapsed>
                    <Expanded id={group.key}>
                      <div className={styles.expanded}>
                        <div className={styles.expanded_header}>
                          <span className="sf-text-regular text--primary">Шаблоны:</span>
                          <BaseButton
                            className={styles.create_question}
                            variant="tertiary"
                            onClick={(e) => handleCreateQuestion(e, group.group)}
                            startIcon={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M8.4 3C8.731 3 9 3.269 9 3.6V7H12.4C12.731 7 13 7.269 13 7.6V8.4C13 8.731 12.731 9 12.4 9H9V12.4C9 12.731 8.731 13 8.4 13H7.6C7.269 13 7 12.731 7 12.4V9H3.6C3.269 9 3 8.731 3 8.4V7.6C3 7.269 3.269 7 3.6 7H7V3.6C7 3.269 7.269 3 7.6 3L8.4 3Z"
                                  fill="#226DFF"
                                />
                              </svg>
                            }
                          >
                            Добавить шаблон
                          </BaseButton>
                        </div>
                        {group.questions.length > 0 && (
                          <div className={styles.questions_list}>
                            {group.questions.map((question) => (
                              <TemplateQuestionItem
                                key={`${group.key}-${question.Id}`}
                                question={question}
                                onEdit={() => handleEditQuestion({ group: group.group, question: question })}
                                onRemove={() => handleDeleteQuestion(question)}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    </Expanded>
                  </div>
                ))}
              </Accordion>
            </div>
          )}

          <EditGroupModal
            group={selectedGroup}
            isOpen={isEditGroupModalShown}
            onClose={() => setEditGroupModalShown(false)}
          />

          {selectedGroup && (
            <EditQuestionModal
              group={selectedGroup}
              question={selectedQuestion}
              isOpen={isEditQuestionModalShown}
              onClose={() => setEditQuestionModalShown(false)}
            />
          )}

          {selectedGroup && (
            <DeleteGroupModal
              group={selectedGroup}
              isOpen={isDeleteGroupModalShown}
              onClose={() => setDeleteGroupModalShown(false)}
            />
          )}

          {selectedQuestion && (
            <DeleteQuestionModal
              question={selectedQuestion}
              isOpen={isDeleteQuestionModalShown}
              onClose={() => setDeleteQuestionModalShown(false)}
            />
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default ManagementTemplate;
