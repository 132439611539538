import { Button, Typography, Stack, Box } from "@mui/material";
import styles from "../create.module.scss";
import { useNavigate } from "react-router-dom";
import { Events } from "../../events";
import { Icon } from "../../../components/icon";
import finishIcon from "../../../assets/icons/finish.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { useEffect } from "react";
import { api } from "../../../services/api";

// TODO: Привести стили в нормальный вид, вынести на модули
const Finish = () => {
  const navigation = useNavigate();
  const numberOcc = useSelector(
    (state: RootState) => state.occ.occById.data.Order.Number
  );

  return (
    <>
      <Icon path={finishIcon} alt="finish" />
      <section
        className={styles.root}
        style={{
          paddingTop: "24px",
          paddingBottom: "36px",
          marginBottom: "28px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h2" className={styles.title}>
          Собрание {numberOcc} успешно создано!
        </Typography>
      </section>
      <section
        className={styles.root}
        style={{
          marginBottom: "28px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h4" sx={{ fontFamily: "GolosMedium" }}>
          Что будет делать система?
        </Typography>
        <Events
          classes={{
            root: styles.eventsContainer,
            row: styles.eventsRow,
            label: styles.eventsItem,
            value: styles.eventsItemValue,
          }}
        />
      </section>
      <section
        className={styles.root}
        style={{
          paddingBottom: "36px",
        }}
      >
        <Typography variant="h4" sx={{ fontFamily: "GolosMedium" }}>
          Что дальше делать администратору собрания?
        </Typography>
        <Box p="12px 0 24px" sx={{ display: "grid", gap: "24px" }}>
          <p>
            Важно понимать, что с администратора собрания не снимается
            обязанность информировать собственников о предстоящем собрании иными
            доступными способами, так как не все собственники зарегистрированы в
            приложении Локоло
          </p>
          <p>
            Используйте сформированные документы в разделе “Мои собрания”
            вкладки “Документы” для проведения всех необходимых процедур
            проведения общих собраний собственников.
          </p>
          <p>
            Вносите Решения собственников, принятые на бумажном носителе в
            систему в разделе “Мои собрания” вкладки “Внести решение”.
          </p>
          <p>
            Следите за наличием кворума в разделе “Мои собрания” вкладки
            “Результаты”
          </p>
        </Box>
        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigation("/my-occ")}
            sx={{ width: "220px" }}
          >
            Мои собрания
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigation("/occ/create")}
            sx={{ width: "220px" }}
          >
            Создать собрание
          </Button>
        </Stack>
      </section>
    </>
  );
};

export { Finish };
