import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import { chevronRight24Icon, lightbulbIcon } from "@/consts/icons";
import { RootState, useAppDispatch } from "@/app/store";
import {
  applySearchFilters,
  fetchUsers,
  getStatuses,
  loadMoreUsers,
  resetSearchFilters,
  setSearchQuery,
  setSearchQueryType,
  toggleUserDetailsModal,
} from "@/app/store/slices/houseUsers";
import { ContentContext } from "@/components/content";
import { Accordion, CustomCollapsed, Expanded } from "@/components/base-accordion";
import BaseHeadlineAlert from "@/components/BaseHeadlineAlert";
import BaseTooltip from "@/components/base-tooltip";
import BaseButton from "@/components/base-button";
import BaseRadio from "@/components/BaseRadio";
import { BaseDropdown, BaseDropdownMenuItem } from "@/components/base-dropdown";
import { Spinner } from "@/components/spinner";
import {
  BaseTable,
  BaseTableHead,
  BaseTableBody,
  BaseTableRow,
  BaseTableHeadCell,
  BaseTableCell,
  BaseContainer,
} from "@/components/base-table";
import UserDetailsModal from "./modals/UserDetailsModal";
import { HouseUsers } from "@/types/house-users";
import { activeAddress } from "@/app/store/slices/user";
import { Profile } from "@/types";
import UserStatus from "./UserStatus";
import styles from "./styles.module.scss";
import { fetchAccessRoles } from "@/app/store/slices/access/actions";

const filterAccordionId = "filter";

const LokoloUsers: React.FC = () => {
  const dispatch = useAppDispatch();

  const address = useSelector(activeAddress);
  const company = useSelector((state: RootState) => state.user.company);
  const { isLoading, filters, query, queryType, users, statuses, pagination } = useSelector(
    (state: RootState) => state.houseUsers
  );

  const mainEl = useContext(ContentContext);
  const { measureRef, isIntersecting, observer } = useInfiniteScroll();

  const tableBodyRef = useRef<HTMLTableSectionElement>(null);
  const [emptyTableHeight, setEmptyTableHeight] = useState<number>(0);
  const [filtersLocal, setFiltersLocal] = useState<typeof filters>(filters);

  const hasMore = useMemo(() => pagination && pagination.Index < pagination.Count, [pagination]);

  const statusTooltipText = useMemo(
    () =>
      company?.Type === Profile.CompanyType.SNT
        ? "Для подтверждения собственности пользователь должен авторизоваться в приложении через ЕСИА, а также он должен быть в вашем реестре участков"
        : company?.Type === Profile.CompanyType.UK
        ? "Для подтверждения собственности пользователь должен авторизоваться в приложении через ЕСИА, а также он должен быть в вашем реестре собственников"
        : "",
    [company]
  );

  function handleQueryChange(event: React.SyntheticEvent) {
    const value = (event.target as HTMLInputElement).value;
    if (value !== query) {
      dispatch(setSearchQuery(value));
    }
  }

  function handleQueryTypeChange(type: HouseUsers.QueryType) {
    if (queryType !== type) {
      dispatch(setSearchQueryType(type));
    }
  }

  async function syncFilters() {
    return await setFiltersLocal(filters);
  }

  async function applyFilters() {
    await dispatch(applySearchFilters(filtersLocal)).unwrap();
  }

  async function resetFilters() {
    setFiltersLocal({
      Esia: null,
      Registry: null,
      Ownership: null,
    });
    await dispatch(resetSearchFilters()).unwrap();
  }

  function handleUserSelect(user: HouseUsers.User | null) {
    dispatch(toggleUserDetailsModal({ isOpen: true, user }));
  }

  function getEmptyTableHeight() {
    if (mainEl.current && tableBodyRef.current) {
      const rect = tableBodyRef.current.getBoundingClientRect();
      const pageHeight = (mainEl.current as HTMLElement).getBoundingClientRect();
      setEmptyTableHeight(pageHeight.height - rect.top + 48); // 48 - top & bottom paddings by 24
    }
  }

  async function fetch() {
    if (!address) {
      return;
    }

    try {
      await Promise.all([
        dispatch(fetchUsers()).unwrap(),
        dispatch(getStatuses()).unwrap(),
        dispatch(fetchAccessRoles()).unwrap(),
      ]);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchMoreData() {
    try {
      const { Index, Count } = await dispatch(loadMoreUsers()).unwrap();
      if (observer && isIntersecting && Index === Count) {
        observer.disconnect();
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (isIntersecting) {
      fetchMoreData();
    }
  }, [isIntersecting]);

  useEffect(() => {
    fetch();
  }, [address]);

  useEffect(() => {
    getEmptyTableHeight();

    window.addEventListener("resize", getEmptyTableHeight, true);

    return () => {
      window.removeEventListener("resize", getEmptyTableHeight, true);
    };
  }, []);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.header_title}>
          <h1 className="title-h1">Пользователи Локоло</h1>
        </div>
        <div>
          <div className={styles.search}>
            <div>
              <BaseDropdown
                value={queryType}
                customToggler={
                  <div className={styles.select}>
                    <span className="text--primary color--text-primary sf-text-regular">
                      {queryType === HouseUsers.QueryType.Fio
                        ? "По пользователям"
                        : queryType === HouseUsers.QueryType.Number
                        ? company?.Type === Profile.CompanyType.SNT
                          ? "По участкам"
                          : "По помещениям"
                        : "Фильтр"}
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.171 7.36222C5.064 7.21889 5 7.03444 5 6.83333C5 6.37333 5.336 6 5.75 6H10.25C10.664 6 11 6.37333 11 6.83333C11 7.03444 10.936 7.21889 10.829 7.36222L8.606 10.6578C8.47 10.8644 8.249 11 8 11C7.751 11 7.53 10.8644 7.394 10.6578L5.171 7.36222Z"
                        fill="#818C99"
                      />
                    </svg>
                  </div>
                }
                isSelectable={true}
                onSelect={handleQueryTypeChange}
              >
                <>
                  <BaseDropdownMenuItem value={HouseUsers.QueryType.Fio}>
                    <span className="sf-text-regular">По пользователям</span>
                  </BaseDropdownMenuItem>
                  <BaseDropdownMenuItem value={HouseUsers.QueryType.Number}>
                    <span className="sf-text-regular">
                      {company?.Type === Profile.CompanyType.SNT ? "По участкам" : "По помещениям"}
                    </span>
                  </BaseDropdownMenuItem>
                </>
              </BaseDropdown>
            </div>
            <span></span>
            <div className={styles.query}>
              <svg className={styles.icon} width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.5 7C11.5 9.48528 9.48528 11.5 7 11.5C4.51472 11.5 2.5 9.48528 2.5 7C2.5 4.51472 4.51472 2.5 7 2.5C9.48528 2.5 11.5 4.51472 11.5 7ZM10.4765 11.8907C9.49572 12.5892 8.29583 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 8.29583 12.5892 9.49572 11.8907 10.4765L14.7071 13.2929C15.0976 13.6834 15.0976 14.3166 14.7071 14.7071C14.3166 15.0976 13.6834 15.0976 13.2929 14.7071L10.4765 11.8907Z"
                  fill="#818C99"
                />
              </svg>
              <input
                className="text--primary color--text-primary sf-text-regular"
                type="text"
                value={query}
                onChange={handleQueryChange}
                placeholder="Поиск"
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <BaseHeadlineAlert name="access-settings" title="Что это такое?" verticalMargin="0" icon={lightbulbIcon}>
          <p className="text--primary">
            Чтобы у собственника была возможность голосовать в мобильном приложении «Локоло», у него должна быть
            подтверждена собственность. Здесь вы можете отслеживать статусы пользователей мобильного приложения
          </p>
        </BaseHeadlineAlert>
      </div>

      <div className={styles.filters}>
        <Accordion isAsync={true} callback={syncFilters}>
          <div className="flex">
            <CustomCollapsed id={filterAccordionId} upAndDownIconAnimation={true}>
              <BaseButton
                variant="secondary"
                endIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    role="spinbutton"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.7803 7.71968C14.4874 7.42679 14.0126 7.42679 13.7197 7.71969L10 11.4394L6.28033 7.71968C5.98743 7.42679 5.51256 7.42679 5.21967 7.71969C4.92678 8.01258 4.92678 8.48745 5.21967 8.78035L9.46969 13.0303C9.76259 13.3232 10.2375 13.3232 10.5304 13.0303L14.7803 8.78034C15.0732 8.48745 15.0732 8.01258 14.7803 7.71968Z"
                      fill="#226DFF"
                    />
                  </svg>
                }
              >
                Фильтр
              </BaseButton>
            </CustomCollapsed>
          </div>

          <Expanded id={filterAccordionId}>
            <div className={styles.filters_content}>
              <div className={styles.filters_table}>
                <div className={styles.filters_row}>
                  <div className={styles.filters_head_cell}>
                    <p className="text--primary color--text-primary sf-text-regular">Статус в приложении:</p>
                  </div>
                  <div>
                    <div style={{ minWidth: "300px" }}>
                      <BaseDropdown
                        value={filtersLocal.Ownership}
                        display={
                          filtersLocal.Ownership === null
                            ? "Все"
                            : statuses.find((it) => it.key === filtersLocal.Ownership)?.name
                        }
                        variant="formfield"
                        isSelectable={true}
                        onSelect={(value) => {
                          setFiltersLocal((prevState) => ({ ...prevState, Ownership: value }));
                        }}
                      >
                        <>
                          <BaseDropdownMenuItem value={null}>
                            <span className="sf-text-regular">Все</span>
                          </BaseDropdownMenuItem>
                          {statuses.map((status) => (
                            <BaseDropdownMenuItem key={status.key} value={status.key}>
                              <span className="sf-text-regular">{status.name}</span>
                            </BaseDropdownMenuItem>
                          ))}
                        </>
                      </BaseDropdown>
                    </div>
                  </div>
                </div>

                <div className={styles.filters_row}>
                  <div className={styles.filters_head_cell}>
                    <p className="text--primary color--text-primary sf-text-regular">Есть в моем реестре:</p>
                  </div>
                  <div>
                    <div className={styles.filters_radio}>
                      <BaseRadio
                        value={null}
                        selected={filtersLocal?.Registry}
                        onChange={(value: typeof filters.Registry) =>
                          setFiltersLocal((prevState) => ({ ...prevState, Registry: value }))
                        }
                      >
                        Все
                      </BaseRadio>
                      <BaseRadio
                        value={true}
                        selected={filtersLocal?.Registry}
                        onChange={(value: typeof filters.Registry) =>
                          setFiltersLocal((prevState) => ({ ...prevState, Registry: value }))
                        }
                      >
                        Да
                      </BaseRadio>
                      <BaseRadio
                        value={false}
                        selected={filtersLocal?.Registry}
                        onChange={(value: typeof filters.Registry) =>
                          setFiltersLocal((prevState) => ({ ...prevState, Registry: value }))
                        }
                      >
                        Нет
                      </BaseRadio>
                    </div>
                  </div>
                </div>

                <div className={styles.filters_row}>
                  <div className={styles.filters_head_cell}>
                    <p className="text--primary color--text-primary sf-text-regular">Вход через ЕСИА:</p>
                  </div>
                  <div>
                    <div className={styles.filters_radio}>
                      <BaseRadio
                        value={null}
                        selected={filtersLocal?.Esia}
                        onChange={(value: typeof filters.Esia) =>
                          setFiltersLocal((prevState) => ({ ...prevState, Esia: value }))
                        }
                      >
                        Все
                      </BaseRadio>
                      <BaseRadio
                        value={true}
                        selected={filtersLocal?.Esia}
                        onChange={(value: typeof filters.Esia) =>
                          setFiltersLocal((prevState) => ({ ...prevState, Esia: value }))
                        }
                      >
                        Да
                      </BaseRadio>
                      <BaseRadio
                        value={false}
                        selected={filtersLocal?.Esia}
                        onChange={(value: typeof filters.Esia) =>
                          setFiltersLocal((prevState) => ({ ...prevState, Esia: value }))
                        }
                      >
                        Нет
                      </BaseRadio>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.filters_actions}>
                <div>
                  <BaseButton onClick={applyFilters}>Показать</BaseButton>
                </div>
                <div>
                  <BaseButton variant="secondary" appearance="negative" onClick={resetFilters}>
                    Сбросить фильтр
                  </BaseButton>
                </div>
              </div>
            </div>
          </Expanded>
        </Accordion>
      </div>

      <div>
        <BaseContainer className={styles.table}>
          <div className={styles.table_container}>
            <BaseTable className={styles.table_itself}>
              <BaseTableHead>
                <BaseTableRow className={styles.table_head_row}>
                  <BaseTableHeadCell>
                    {company?.Type === Profile.CompanyType.SNT ? "№ участка" : "№ помещения"}
                  </BaseTableHeadCell>
                  <BaseTableHeadCell>ФИО</BaseTableHeadCell>
                  <BaseTableHeadCell>Вход через ЕСИА</BaseTableHeadCell>
                  <BaseTableHeadCell>Есть в моем реестре</BaseTableHeadCell>
                  <BaseTableHeadCell>
                    <span className="flex middle" style={{ gap: "4px" }}>
                      Статус в приложении
                      <BaseTooltip title={statusTooltipText}>
                        <svg className="flex" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8 0C12.415 0 16 3.585 16 8C16 12.415 12.415 16 8 16C3.585 16 0 12.415 0 8C0 3.585 3.585 0 8 0ZM6.964 11.478C6.964 12.041 7.422 12.499 7.985 12.499C8.548 12.499 9.006 12.041 9.006 11.478C9.006 10.915 8.548 10.457 7.985 10.457C7.422 10.457 6.964 10.915 6.964 11.478ZM8.116 3.521C6.388 3.521 5.392 4.974 5.392 5.681C5.392 5.995 5.563 6.493 6.126 6.493C6.558 6.493 6.846 6.179 6.859 5.904C6.898 5.629 7.186 5 8.064 5C8.692 5 9.268 5.445 9.268 5.995C9.268 7.095 7.121 7.183 7.121 8.807C7.121 9.357 7.501 9.75 7.972 9.75C8.469 9.75 8.849 9.357 8.849 8.859C8.849 7.979 10.918 7.841 10.918 5.956C10.918 4.581 9.648 3.521 8.116 3.521Z"
                            fill="#99A2AD"
                          />
                        </svg>
                      </BaseTooltip>
                    </span>
                  </BaseTableHeadCell>
                  <BaseTableHeadCell></BaseTableHeadCell>
                </BaseTableRow>
              </BaseTableHead>

              {isLoading ? (
                <BaseTableBody className={styles.empty} refProp={tableBodyRef}>
                  <BaseTableRow>
                    <BaseTableCell colspan={7} style={{ height: `${emptyTableHeight}px` }}>
                      <div className="flex center">
                        <Spinner color="#226dff" size={36} />
                      </div>
                    </BaseTableCell>
                  </BaseTableRow>
                </BaseTableBody>
              ) : users.length > 0 ? (
                <BaseTableBody className={styles.table_body}>
                  {users.map((it) => (
                    <BaseTableRow
                      className={styles.table_row}
                      key={`${it.ObjectNumber}-${it.UserId}`}
                      onClick={() => handleUserSelect(it)}
                    >
                      <BaseTableHeadCell>
                        <span>{it.ObjectNumber}</span>
                      </BaseTableHeadCell>
                      <BaseTableCell>
                        <span className="sf-text-semibold">{it.Fio}</span>
                      </BaseTableCell>
                      <BaseTableCell>
                        <span>{it.EsiaExists ? "Да" : "Нет"}</span>
                      </BaseTableCell>
                      <BaseTableCell>
                        <span>{it.InRegistry ? "Да" : "Нет"}</span>
                      </BaseTableCell>
                      <BaseTableCell>
                        <span>
                          <UserStatus status={it.Ownership} />
                        </span>
                      </BaseTableCell>
                      <BaseTableCell>
                        <span className={styles.table_row_icon}>{chevronRight24Icon("var(--text-secondary)")}</span>
                      </BaseTableCell>
                    </BaseTableRow>
                  ))}
                  {hasMore && (
                    <tr className={styles.loading_more} ref={measureRef}>
                      <td colSpan={7}>
                        <div className="flex center">
                          <Spinner color="#226dff" size={36} />
                        </div>
                      </td>
                    </tr>
                  )}
                </BaseTableBody>
              ) : (
                <BaseTableBody className={styles.empty} refProp={tableBodyRef}>
                  <BaseTableRow>
                    <BaseTableCell colspan={7} style={{ height: `${emptyTableHeight}px` }}>
                      <div className="center">
                        {query.length > 0 ? (
                          <p className="text--primary sf-pro-regular color--text-primary">Поиск не дал результатов</p>
                        ) : (
                          <p className="text--primary sf-pro-regular color--text-primary">Пользователей пока нет</p>
                        )}
                      </div>
                    </BaseTableCell>
                  </BaseTableRow>
                </BaseTableBody>
              )}
            </BaseTable>
          </div>
        </BaseContainer>
      </div>

      <UserDetailsModal />
    </>
  );
};

export default LokoloUsers;
