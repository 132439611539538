import React from "react";
import styles from "./styles.module.scss";

interface IBaseUserAvatarProps {
  children: React.ReactNode;
  avatar?: string;
  size?: React.CSSProperties["width"];
}

const BaseUserAvatar: React.FC<IBaseUserAvatarProps> = ({ children, avatar, size = "40px" }) => {
  return (
    <div className={styles.base}>
      <span className={styles.base_avatar} style={{ width: size, height: size, minWidth: size }}>
        {avatar ? (
          <img src={avatar} alt="" />
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6C9.79086 6 8 7.79086 8 10C8 12.2091 9.79086 14 12 14ZM12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM18 17.2917C19.2447 15.8814 20 14.0289 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 14.0289 4.75528 15.8814 6.00002 17.2917C7.59156 15.8674 9.6953 15 12 15C14.3047 15 16.4084 15.8674 18 17.2917ZM16.4852 18.6254C15.2699 17.6099 13.7069 17 12 17C10.293 17 8.73005 17.6099 7.51483 18.6254C8.79404 19.4931 10.3378 20 12 20C13.6622 20 15.2059 19.4931 16.4852 18.6254Z"
              fill="white"
            />
          </svg>
        )}
      </span>
      {children}
    </div>
  );
};

export default BaseUserAvatar;
