import {useEffect, useState} from "react";
import {api} from "../../../services/api";

type AddressType = {
  FiasId: string,
  Address: string,
  defaultValue: string;
}

export const useFetchHouseAddress = () => {
  const [data, setData] = useState<AddressType[]>([]);
  const [loading, setLoading] = useState(false);

  const init = async () => {
      try {
        const response = await api.user.getData();
        setData(response.data.Data.Addresses);
        setLoading(true);
      } catch (err: any) {
        console.error(err);
        setLoading(true);
      }
  }

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {data, loading};
}