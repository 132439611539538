import { Middleware } from "redux";
import { setAuthorization } from "../slices/app";

const authorizationMiddleware: Middleware = (store: any) => (next: any) => (action: any) => {
  // if (setAuthorization.match(action)) {
  //   localStorage.setItem("isAuthorization", JSON.stringify(action.payload));
  // }

  return next(action);
};

export { authorizationMiddleware };
