import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import format from "date-fns/format";

import { AuthorFields, orderFields } from "../../../../consts/labels";

const renderUpdatedOrderOcc = ({
  data,
  fiasData,
  meetingDictionary,
  locationDictionary,
  typeDictionary,
}: any) => {
  return Object.entries({
    ...orderFields,
    ...{
      PublicationTimestamp: "Дата публикации сообщения",
      StartTimestamp: "Дата старта приема решений",
      EndTimestamp: "Дата окончания приема решений",
      FormId: "Форма собрания",
      LocationId: "Место проведения собрания",
      TypeId: "Вид собрания",
    },
  }).map(([key, value]: any) => {
    if (!!data[key]) {
      if (key === "FiasId") {
        const address = fiasData?.find(
          (el: any) => el.FiasId === data[key]
        )?.Address;
        return (
          <Box fontSize="14px" display="flex">
            <Box component="span" color="rgba(0, 0, 0, 0.54)" width="232px">
              {value}
            </Box>
            <Box component="span" paddingLeft={2}>
              {address}
            </Box>
          </Box>
        );
      }

      if (
        key === "ProcedureForFamiliarizationWithMaterialsOfOCC" ||
        key === "ProcedureForMakingWrittenDecisions"
      ) {
        return (
          <Box fontSize="14px">
            <Box color="rgba(0, 0, 0, 0.54)" paddingBottom={1}>
              {value}
            </Box>
            <Box>{data[key]}</Box>
          </Box>
        );
      }

      if (
        ["StartTimestamp", "EndTimestamp", "PublicationTimestamp"].includes(key)
      ) {
        return (
          <Box fontSize="14px" display="flex">
            <Box component="span" color="rgba(0, 0, 0, 0.54)" width="232px">
              {value}
            </Box>
            <Box component="span" paddingLeft={2}>
              {format(data[key] * 1000, "kk:mm dd.MM.yyyy")}
            </Box>
          </Box>
        );
      }

      if (["FormId", "LocationId", "TypeId"].includes(key)) {
        let dictionary: any;

        if (key === "FormId") {
          dictionary = meetingDictionary;
        }

        if (key === "LocationId") {
          dictionary = locationDictionary;
        }

        if (key === "TypeId") {
          dictionary = typeDictionary;
        }

        let dictionaryValue = dictionary?.data.find(
          (value: any) => value?.id === data[key]
        );

        return (
          <Box fontSize="14px" display="flex">
            <Box component="span" color="rgba(0, 0, 0, 0.54)" width="232px">
              {value}
            </Box>
            <Box component="span" paddingLeft={2}>
              {dictionaryValue?.name}
            </Box>
          </Box>
        );
      }

      return (
        <Box fontSize="14px" display="flex">
          <Box component="span" color="rgba(0, 0, 0, 0.54)" width="232px">
            {value}
          </Box>
          <Box component="span" paddingLeft={2}>
            {data[key]}
          </Box>
        </Box>
      );
    }

    return null;
  });
};

const AuthorView = ({
  data,
  meetingDictionary,
  locationDictionary,
  typeDictionary,
}: any) => (
  <>
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon color="secondary" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography fontFamily="GolosMedium">Порядок собрания</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          {renderUpdatedOrderOcc({
            data,
            meetingDictionary,
            locationDictionary,
            typeDictionary,
          })}
        </Stack>
      </AccordionDetails>
    </Accordion>
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon color="secondary" />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography fontFamily="GolosMedium">Инициатор</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          {Object.entries(AuthorFields[data.Author.TypeId]).map(
            ([key, value]: any) => (
              <Box fontSize="14px" display="flex">
                <Box component="span" color="rgba(0, 0, 0, 0.54)" width="30%">
                  {value}{" "}
                </Box>
                <Box component="span" paddingLeft={2}>
                  {data.Author.Object[key]}
                </Box>
              </Box>
            )
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  </>
);

export { AuthorView };
