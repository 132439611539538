import { Content } from "@/components/content";
import { Layout } from "@/components/layout";
import { Outlet } from "react-router-dom";

const BaseLayout = () => {
  return (
    <Layout>
      <Content style={{ backgroundColor: "white" }}>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default BaseLayout;
