import { createSlice } from "@reduxjs/toolkit";

import { statuses } from "../../../consts/statuses";
import { AddressType, houseApartmentsType, HouseDataType, HouseOwnersType } from "../../../types/registry";

type RenderingType = "save" | "edit" | "view" | "unkown" | "empty";
// @ActiveTabType 0 - apartments, 1 - owners
type ActiveTabType = "0" | "1";
type FiltersType = {
  limit: number;
  search: string;
  field: "number" | "area" | "full_name" | "";
  direction: "asc" | "desc" | "";
  tab: ActiveTabType;
};

interface IHousesState {
  address: null | Omit<AddressType, "GeoPoint">;
  hasAddress: boolean;
  stateRendering: RenderingType;
  registryLoaded: boolean;
  houses: {
    houseData: null | HouseDataType;
    loading: boolean;
    hasHousesData: boolean;
    fetchStatus: typeof statuses[keyof typeof statuses] | "";
    error: any;
  };
  houseOwners: {
    data: Array<HouseOwnersType>;
    loading: boolean;
    hasHouseOwnersData: boolean;
    fetchStatus: typeof statuses[keyof typeof statuses] | "";
    pagination: {
      page: number;
      count: number;
      limit: number;
    };
    error: any;
  };
  houseApartments: {
    data: Array<houseApartmentsType>;
    loading: boolean;
    hasHouseApartmentsData: boolean;
    fetchStatus: typeof statuses[keyof typeof statuses] | "";
    pagination: {
      page: number;
      count: number;
      limit: number;
    };
    error: any;
  };
  houseArea: {
    loading: boolean;
    error: string;
  };
  registryData: Array<any>;
  loaderRegistry: {
    error: string;
    allErrors: Array<string>;
    counterClicks: number;
  };
  filtersStore: FiltersType;
}

const limit = 50;

const initialState: IHousesState = {
  address: null,
  hasAddress: false,
  stateRendering: "unkown",
  registryLoaded: false,
  houses: {
    houseData: null,
    loading: false,
    hasHousesData: false,
    fetchStatus: "",
    error: "",
  },
  houseOwners: {
    data: [],
    loading: false,
    hasHouseOwnersData: false,
    fetchStatus: "",
    pagination: {
      count: 1,
      page: 1,
      limit,
    },
    error: "",
  },
  houseApartments: {
    data: [],
    loading: false,
    hasHouseApartmentsData: false,
    fetchStatus: "",
    pagination: {
      count: 1,
      page: 1,
      limit,
    },
    error: "",
  },
  houseArea: {
    loading: false,
    error: "",
  },
  registryData: [],
  loaderRegistry: {
    error: "",
    allErrors: [],
    counterClicks: 1,
  },
  filtersStore: {
    limit,
    search: "",
    field: "",
    direction: "",
    tab: "0",
  },
};

export const registrySlice = createSlice({
  name: "registry",
  initialState,
  reducers: {
    setAddress: (state, action) => {
      state.address = action.payload;
      state.houseApartments.hasHouseApartmentsData = false;

      if (action.payload) {
        state.hasAddress = true;
      }
    },
    resetAddress: (state) => {
      state.address = initialState.address;
    },
    resetHasAddress: (state) => {
      state.hasAddress = false;
    },
    setStateRendering: (state, action) => {
      state.stateRendering = action.payload;
      state.houseOwners = {
        data: [],
        loading: false,
        hasHouseOwnersData: false,
        fetchStatus: "",
        pagination: {
          count: 1,
          page: 1,
          limit,
        },
        error: "",
      };
    },
    setFiltersStore: (state, action) => {
      state.filtersStore = { ...state.filtersStore, ...action.payload };
    },
    resetFilters: (state) => {
      state.filtersStore = initialState.filtersStore;
    },
    setRegistryLoaded: (state, action) => {
      state.registryLoaded = action.payload;
    },
    resetRegistryData: (state) => {
      state.registryData = [];

      if (state.houses.houseData && state.stateRendering !== "view") {
        state.houses.houseData.Area = 0;
      }
    },
    setErrorLoaderRegistry: (state, action) => {
      state.loaderRegistry.error = action.payload;
    },
    setAllErrorsRegistry: (state, action) => {
      state.loaderRegistry.allErrors = action.payload;
    },
    setCounterClicks: (state, action) => {
      state.loaderRegistry.counterClicks = action.payload;
    },
    resetHouseData: (state) => {
      state.houses.houseData = null;
    },
    resetRegistry: () => initialState,
  },
  extraReducers: (builder) => {},
});

export default registrySlice.reducer;
export const {
  setAddress,
  setStateRendering,
  setFiltersStore,
  resetFilters,
  setRegistryLoaded,
  resetRegistryData,
  setErrorLoaderRegistry,
  setAllErrorsRegistry,
  setCounterClicks,
  resetHouseData,
  resetHasAddress,
  resetAddress,
  resetRegistry,
} = registrySlice.actions;
