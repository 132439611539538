import { forwardRef, useEffect, useState } from "react";
import { InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useFetchHouseAddress } from "../hooks/useFetchHouseAddress";
import { LabelTooltip } from "../../../components/tooltip";

interface IModalAddress {
  selected: string;
  name: string;
  onChange: any;
  error: boolean;
  onBlur: any;
  resetError: () => void;
}

export const ModalAddress = forwardRef(
  (
    { onChange, name, onBlur, error, selected, resetError }: IModalAddress,
    ref
  ) => {
    const { loading, data } = useFetchHouseAddress();
    const [selectedValue, setSelectedValue] = useState<string>("");

    useEffect(() => {
      setSelectedValue(selected);
    }, [selected]);

    const handleSelectChange = (event: SelectChangeEvent<string>) => {
      const value = event.target.value;
      setSelectedValue(value);
      resetError();
      onChange(event);
    };

    return (
      <>
        <InputLabel htmlFor="HouseAddress" sx={{ display: "flex", gap: "4px" }}>
          Адрес дома
          <LabelTooltip
            title="Из списка выберите адрес дома, для которого хотите создать собрание."
            placement="right"
            arrow
            enterDelay={500}
          />
        </InputLabel>
        <Select
          id="modalAddress"
          fullWidth
          disabled={!loading}
          ref={ref}
          onChange={handleSelectChange}
          name={name}
          onBlur={onBlur}
          error={error}
          defaultValue={selectedValue}
          value={selectedValue}
        >
          {data.map((item: any) => (
            <MenuItem key={item.FiasId} value={item}>
              {item.Address}
            </MenuItem>
          ))}
        </Select>
      </>
    );
  }
);
