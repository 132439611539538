import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import {
  Alert,
  OutlinedInput,
  InputLabel,
  Grid,
  FormHelperText,
  ToggleButtonGroup,
  ToggleButton,
  Select,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";

import { RootState } from "../../../app/store";
import { setCommonData } from "../../../app/store/slices/app";
import { api } from "../../../services/api";
import { Spinner } from "../../../components/spinner";
import { isObject } from "../../../helpers/validation";
import { LEGAL_FROMS } from "../constant";
import { ErrorsType } from "../../../types/common";

import styles from "../create.module.scss";
import { createOccMode } from "../../../consts/state";
import { useCurrentMode } from "../hooks/useCurrentMode";

interface IProps {
  refForm: any;
  toNextStep: (id?: number) => void;
}

// @TODO: Переписать 2 и 3 шаг
const Step2 = ({ refForm, toNextStep }: IProps) => {
  const { state: occId } = useLocation();
  const [load, setLoad] = useState(occId);
  const [type, setType] = useState(2);
  const [error, setError] = useState<ErrorsType>(null);
  const [errorPhone, setErrorPhone] = useState<string>("");
  const [legalFormValue, setLegalFormValue] = useState<string>("");
  const [form, setForm] = useState<{
    UkLegalForm: string;
    UkOGRN: string;
    Phone: string;
    FoundationAgreementDetails: string;
  }>({
    UkLegalForm: "",
    UkOGRN: "",
    Phone: "",
    FoundationAgreementDetails: "",
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    getValues,
    setValue,
  } = useForm();
  const dispatch = useDispatch();
  const userData = useSelector((state: RootState) => state.app.user.common);

  const mode = useCurrentMode();

  const storageData = localStorage.getItem("step2") !== null ? localStorage.getItem("step2") : null;

  const setOccData = (data: any) => {
    if (isObject(data)) {
      setLegalFormValue(data.UkLegalForm);
      setForm({
        UkLegalForm: data.UkLegalForm,
        UkOGRN: data.UkOGRN,
        Phone: data.Phone,
        FoundationAgreementDetails: data.FoundationAgreementDetails,
      });
      // const formField: string[] = [
      //   "Type",
      //   "Surname",
      //   "Patronymic",
      //   "RoomAddress",
      //   "Location",
      //   "OwnershipDocumentDetails",
      //   "FoundationAgreementDetails",
      //   "Phone",
      // ];
      // @TODO потом надо будет убрать этот колхоз
      // for (const field of formField) {
      //   setValue(field, data[field]);
      // }

      // if (data.Type === 1) {
      //   setValue("CompanyName", data["Name"]);
      //   setValue("OGRN", data["OGRN"]);
      //   setValue("LegalForm", data["LegalForm"]);
      // }

      // if (data.Type === 0) {
      //   setValue("Name", data["Name"]);
      // }

      // if (data.Type === 2) {
      // setValue("UkName", data["UkName"]);
      // setValue("UkOGRN", data["UkOGRN"]);
      // setValue("UkLegalForm", data["UkLegalForm"]);
      // }

      setType(data.Type);
    }
  };

  const getOccData = async () => {
    try {
      const response = await api.occ.getOccAuthor({ OccId: occId });
      if (Array.isArray(response.data.Data) && response.data.Data.length === 0) {
        if (userData) {
          setOccData({
            Name: userData.Name,
            UkId: userData.Id,
            LegalForm: userData.LegalForm,
            UkLegalForm: userData.LegalForm,
            OGRN: userData.OGRN,
            UkOGRN: userData.OGRN,
            FoundationAgreementDetails: userData.FoundationAgreementDetails,
            Phone: userData.Phone,
            Type: 2,
          });
        } else {
          const { data } = await api.user.getData();
          setOccData({
            Name: data.Data.Name,
            UkId: data.Data.Id,
            LegalForm: data.Data.LegalForm,
            UkLegalForm: data.Data.LegalForm,
            OGRN: data.Data.OGRN,
            UkOGRN: data.Data.OGRN,
            FoundationAgreementDetails: data.Data.FoundationAgreementDetails,
            Phone: data.Data.Phone,
            Type: 2,
          });
        }

        setLoad(false);
        return;
      }

      if (mode === createOccMode.edit && storageData) {
        let data = JSON.parse(storageData).Author;
        data = { ...data, ...data.Object };
        delete data.Object;

        setOccData({ ...data, ...data.Object, Type: data.TypeId });
      } else {
        setOccData(response.data.Data);
      }

      setLoad(false);
    } catch (err: any) {
      setLoad(false);
    }
  };

  const getUserCommonData = async () => {
    try {
      const response = await api.user.getData();

      dispatch(setCommonData(response.data.Data));
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUserCommonData();

    if (occId) {
      getOccData();
    }
  }, []);

  const createOcc = async () => {
    setError(null);

    let validation: any = {};
    if (form.Phone.length === 0) {
      validation.Phone = "Поле обязательно для заполнения";
    }
    if (form.FoundationAgreementDetails.length === 0) {
      validation.FoundationAgreementDetails = "Поле обязательно для заполнения";
    }
    if (form.UkLegalForm.length === 0) {
      validation.UkLegalForm = "Поле обязательно для заполнения";
    }
    if (form.UkOGRN.length === 0) {
      validation.UkOGRN = "Поле обязательно для заполнения";
    }

    if (Object.values(validation).some((it) => !!it) || !!errorPhone) {
      setError(validation);
      return;
    }

    try {
      let _data = {
        TypeId: type,
        OccId: occId,
        Object: {
          Name: userData?.Name,
          // Surname: data.Surname,
          // Patronymic: data.Patronymic,
          // RoomAddress: data.RoomAddress,
          // Location: data.Location,
          // OwnershipDocumentDetails: data.OwnershipDocumentDetails,
          // LegalForm: data.LegalForm,
          FoundationAgreementDetails: form.FoundationAgreementDetails,
          Phone: form.Phone,
          UkLegalForm: form.UkLegalForm,
          UkOGRN: form.UkOGRN,
        },
      };

      // if (type === 1) {
      //   _data.Object.Name = data.CompanyName;
      //   _data.Object.OGRN = data.OGRN;
      // }

      // if (type === 0) {
      //   _data.Object.Name = data.Name;
      // }

      // if (type === 2) {
      //   _data.Object.Name = data.UkName;
      //   _data.Object.UkLegalForm = data.UkLegalForm;
      //   _data.Object.UkOGRN = data.UkOGRN;
      // }

      if (mode === createOccMode.edit) {
        toNextStep();
        localStorage.setItem("step2", JSON.stringify({ Author: _data }));
        return;
      }

      await api.occ.createOccAuthor(_data);
      toNextStep();
    } catch (err: any) {
      setError(err.response.data.Data);
      console.log(err.response.data);
      return false;
    }
  };

  const onCloseError = () => {
    setError(null);
  };

  const handleChange = (event: any, newAlignment: any) => {
    if (newAlignment !== null) {
      setType(newAlignment);
    }
  };

  useEffect(() => {
    if (userData?.LegalForm && !legalFormValue) {
      setLegalFormValue(userData.LegalForm);
    }

    if (userData?.LegalForm === null) {
      setLegalFormValue(" ");
    }
  }, [userData]);

  // TODO: Посмотреть для чего это нужно здесь
  let ukName = localStorage.getItem("ukName");

  if (ukName) {
    ukName = JSON.parse(ukName).Name;
    setValue("UkName", ukName);
  }

  if (load) return <Spinner alt={"Загрузка..."} />;

  return (
    <section className={styles.root}>
      {/* {legalFormValue && ( */}
      <form ref={refForm} onSubmit={createOcc}>
        <Grid container rowSpacing={2} columnSpacing={4}>
          <Grid item xs={12}>
            {error && error?.Errors && (
              <Alert severity="error" onClose={onCloseError}>
                <Box>
                  {Object.keys(error?.Errors).map((field) => (
                    <Typography variant="caption" color="error">
                      {error?.Errors[field]}
                    </Typography>
                  ))}
                </Box>
              </Alert>
            )}
          </Grid>
          <Grid item xs={12}>
            {/* TODO: Вынести в один компонент - дублирование */}
            <Typography variant="subtitle1" sx={{ paddingBottom: "24px", color: "#000", opacity: 1 }}>
              Инициатор - лицо, по чьей инициативе организовывается собрание.
            </Typography>
            <ToggleButtonGroup color="primary" value={type} exclusive onChange={handleChange}>
              <ToggleButton value={1} sx={{ minWidth: "240px" }} disabled>
                Юридическое лицо
              </ToggleButton>
              <ToggleButton value={0} sx={{ minWidth: "240px" }} disabled>
                Физическое лицо
              </ToggleButton>
              <ToggleButton value={2} sx={{ minWidth: "240px", padding: 0, textTransform: "none" }}>
                Управляющая организация
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {/* {type === 1 && (
              <>
                <Grid item xs={12}>
                  <InputLabel>Компания</InputLabel>
                  <OutlinedInput
                    fullWidth
                    autoFocus
                    placeholder="Введите наименование компании"
                    {...register("CompanyName", {
                      required: "Поле обязательно для заполнения",
                    })}
                    error={
                      (errors["CompanyName"]?.type as unknown as string) ===
                      "required"
                    }
                  />
                  {(errors["CompanyName"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["CompanyName"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="LegalForm">
                    Организационно - правовая форма
                  </InputLabel>
                  <Select
                    placeholder="Выбрать"
                    id="LegalForm"
                    fullWidth
                    defaultValue={getValues("LegalForm") ?? " "}
                    {...register("LegalForm", {
                      required: "Поле обязательно для заполнения",
                    })}
                    error={
                      (errors["LegalForm"]?.type as unknown as string) ===
                      "required"
                    }
                  >
                    {LEGAL_FROMS.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  {(errors["LegalForm"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["LegalForm"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>ОГРН</InputLabel>
                  <OutlinedInput
                    fullWidth
                    placeholder="Введите номер ОГРН"
                    {...register("OGRN", {
                      required: "Поле обязательно для заполнения",
                    })}
                    error={
                      (errors["OGRN"]?.type as unknown as string) === "required"
                    }
                  />
                  {(errors["OGRN"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["OGRN"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Адрес помещения</InputLabel>
                  <OutlinedInput
                    fullWidth
                    placeholder="Введите адрес собственности инициатора"
                    {...register("Location", {
                      required: "Поле обязательно для заполнения",
                    })}
                    error={
                      (errors["Location"]?.type as unknown as string) ===
                      "required"
                    }
                  />
                  {(errors["Location"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["Location"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
              </>
            )}
            {type === 0 && (
              <>
                <Grid item xs={4}>
                  <InputLabel>Фамилия</InputLabel>
                  <OutlinedInput
                    fullWidth
                    placeholder="Введите фамилию"
                    {...register("Surname", {
                      required: "Поле обязательно для заполнения",
                    })}
                    error={
                      (errors["Surname"]?.type as unknown as string) ===
                      "required"
                    }
                  />
                  {(errors["Surname"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["Surname"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <InputLabel>Имя</InputLabel>
                  <OutlinedInput
                    fullWidth
                    placeholder="Введите имя"
                    {...register("Name", {
                      required: "Поле обязательно для заполнения",
                    })}
                    error={
                      (errors["Name"]?.type as unknown as string) === "required"
                    }
                  />
                  {(errors["Name"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["Name"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <InputLabel>Отчество</InputLabel>
                  <OutlinedInput
                    fullWidth
                    placeholder="Введите отчество"
                    {...register("Patronymic", {
                      required: "Поле обязательно для заполнения",
                    })}
                    error={
                      (errors["Patronymic"]?.type as unknown as string) ===
                      "required"
                    }
                  />
                  {(errors["Patronymic"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["Patronymic"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Адрес помещения</InputLabel>
                  <OutlinedInput
                    fullWidth
                    placeholder="Введите адрес собственности инициатора"
                    {...register("RoomAddress", {
                      required: "Поле обязательно для заполнения",
                    })}
                    error={
                      (errors["RoomAddress"]?.type as unknown as string) ===
                      "required"
                    }
                  />
                  {(errors["RoomAddress"]?.type as unknown as string) ===
                    "required" && (
                    <FormHelperText error>
                      {errors["RoomAddress"]?.message as unknown as string}
                    </FormHelperText>
                  )}
                </Grid>
              </>
            )}
            {type === 2 && ( */}
          <>
            <Grid item xs={12}>
              <InputLabel>Компания</InputLabel>
              <OutlinedInput
                fullWidth
                autoFocus
                placeholder="Ввести"
                disabled
                multiline
                defaultValue={userData?.Name}
                // {...register("UkName", {
                //   required: "Поле обязательно для заполнения",
                // })}
              />
            </Grid>
            {
              <Grid item xs={12}>
                <InputLabel htmlFor="UkLegalForm">Организационно - правовая форма</InputLabel>
                <Select
                  placeholder="Выбрать"
                  id="UkLegalForm"
                  fullWidth
                  value={form.UkLegalForm}
                  // {...register("UkLegalForm", {
                  //   required: "Поле обязательно для заполнения",
                  // })}
                  onChange={(e) => setForm((prevState) => ({ ...prevState, UkLegalForm: e.target.value }))}
                  error={
                    (errors["UkLegalForm"]?.type as unknown as string) === "required" || !!(error as any)?.UkLegalForm
                  }
                >
                  {LEGAL_FROMS.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                {(errors["UkLegalForm"]?.type as unknown as string) === "required" && (
                  <FormHelperText error>{errors["UkLegalForm"]?.message as unknown as string}</FormHelperText>
                )}
                {!!(error as any)?.UkLegalForm && <FormHelperText error>{(error as any)?.UkLegalForm}</FormHelperText>}
              </Grid>
            }
            <Grid item xs={12}>
              <InputLabel>ОГРН</InputLabel>
              <OutlinedInput
                fullWidth
                placeholder="Введите номер ОГРН"
                value={form.UkOGRN}
                onChange={(e) => setForm((prevState) => ({ ...prevState, UkOGRN: e.target.value }))}
                error={(errors["UkOGRN"]?.type as unknown as string) === "required" || !!(error as any)?.UkOGRN}
              />
              {(errors["UkOGRN"]?.type as unknown as string) === "required" && (
                <FormHelperText error>{errors["UkOGRN"]?.message as unknown as string}</FormHelperText>
              )}
              {!!(error as any)?.UkOGRN && <FormHelperText error>{(error as any)?.UkOGRN}</FormHelperText>}
            </Grid>
            <Grid item xs={12}>
              <InputLabel>
                Реквизиты договора основания на наличие права инициировать общее собрание собственников
              </InputLabel>
              <OutlinedInput
                fullWidth
                placeholder="Введите реквизиты договора основания..."
                value={form.FoundationAgreementDetails}
                onChange={(e) => setForm((prevState) => ({ ...prevState, FoundationAgreementDetails: e.target.value }))}
                error={
                  (errors["FoundationAgreementDetails"]?.type as unknown as string) === "required" ||
                  !!(error as any)?.FoundationAgreementDetails
                }
              />
              {(errors["FoundationAgreementDetails"]?.type as unknown as string) === "required" && (
                <FormHelperText error>
                  {errors["FoundationAgreementDetails"]?.message as unknown as string}
                </FormHelperText>
              )}
              {!!(error as any)?.FoundationAgreementDetails && (
                <FormHelperText error>{(error as any)?.FoundationAgreementDetails}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Контактный телефон</InputLabel>
              <InputMask
                mask="+7 (999) 999-99-99"
                value={String(form.Phone)}
                onChange={(e) => {
                  const phone = e.target.value.replace(/[^0-9+]/g, "");
                  if (phone.length > 11) {
                    setErrorPhone("");
                  }

                  setForm((prevState) => ({ ...prevState, Phone: phone }));

                  if (phone.length < 12) {
                    setErrorPhone("Некорректный формат телефона");
                  }
                }}
              >
                <OutlinedInput
                  placeholder="+7 (XXX) XXX-XX-XX"
                  error={
                    (errors["Phone"]?.type as unknown as string) === "required" ||
                    !!errorPhone ||
                    !!(error as any)?.Phone
                  }
                  type="tel"
                  fullWidth
                  required
                />
              </InputMask>
              {(errors["Phone"]?.type as unknown as string) === "required" && (
                <FormHelperText error>{errors["Phone"]?.message as unknown as string}</FormHelperText>
              )}
              {!!errorPhone && (errors["Phone"]?.type as unknown as string) !== "required" && (
                <FormHelperText error>{errorPhone}</FormHelperText>
              )}
              {!!(error as any)?.Phone && <FormHelperText error>{(error as any)?.Phone}</FormHelperText>}
            </Grid>
          </>
          {/* // )} */}
        </Grid>
      </form>
      {/* )} */}
    </section>
  );
};

export { Step2 };
