import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { createOccMode } from "../../../consts/state";

/**
 * A hook that returns the current mode, which is either the mode stored in local storage or the mode
 * from the `createOcc` slice of the Redux store. If there is no mode stored in local storage, the
 * mode from the store will be returned.
 *
 * @returns {string} The current mode.
 */
export const useCurrentMode = () => {
  const { mode } = useSelector((state: RootState) => state.createOccOld);

  let mergedMode = localStorage.getItem("modeOcc") ?? mode;

  mergedMode = mergedMode ? mergedMode : createOccMode.create;

  return mergedMode;
};
