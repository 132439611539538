export enum VariantEnum {
  main = "main",
  management = "management",
  create = "create",
}

export enum VoteEnum {
  notVote = 0,
  offline = 2,
  online = 1,
}

export enum InputType {
  password = "password",
  text = "text",
}
