import { useState } from "react";
import { closeIcon } from "@/consts/icons";
import styles from "./styles.module.scss";

interface IBaseHeadlineAlertProps {
  title: string;
  name: string;
  children?: JSX.Element;
  icon?: JSX.Element;
  verticalMargin?: string;
}

const BaseHeadlineAlert: React.FC<IBaseHeadlineAlertProps> = ({
  title,
  name,
  children,
  icon,
  verticalMargin = "12px 0",
}) => {
  const storageName = "hidden--alerts";
  const [isHidden, setHidden] = useState<boolean>(false);

  const hideAlert = () => {
    const stored = localStorage.getItem(storageName);
    const list = stored ? (JSON.parse(stored) as Array<string>) : [];
    list.push(name);
    localStorage.setItem(storageName, JSON.stringify(list));

    setHidden(true);
  };

  const storedAlerts = localStorage.getItem(storageName);
  if (isHidden || (storedAlerts && JSON.parse(storedAlerts).includes(name))) {
    return null;
  }

  return (
    <div style={{ padding: verticalMargin }}>
      <section className={styles.headline_alert}>
        <div className={styles.content}>
          <span className={styles.icon}>
            {icon ? (
              icon
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M12 11C12.5523 11 13 11.4477 13 12V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V12C11 11.4477 11.4477 11 12 11Z"
                  fill="#3A3B3D"
                />
                <path
                  d="M12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z"
                  fill="#3A3B3D"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4Z"
                  fill="#3A3B3D"
                />
              </svg>
            )}
          </span>

          <div className={styles.text}>
            <h4 className="headline-h4 sf-text-bold color--text-primary">{title}</h4>
            {children && <div className="sf-text-regular text--primary color--text-primary">{children}</div>}
          </div>
        </div>

        <span className={styles.close} role="button" onClick={hideAlert}>
          {closeIcon("#898D93")}
        </span>
      </section>
    </div>
  );
};

export default BaseHeadlineAlert;
