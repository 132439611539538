import { Content } from "../../components/content";
import { Layout } from "../../components/layout";
import { CreateOccComponent } from "../../features/createOcc";
import { VariantEnum } from "../../types/enum";

const StepsCreateOcc = () => {
  return (
    <>
      <Layout variant={VariantEnum.create}>
        <Content>
          <CreateOccComponent />
        </Content>
      </Layout>
    </>
  );
};

export default StepsCreateOcc;
