import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useState } from "react";
import { useAppDispatch } from "../../../../app/store";
import { fetchSetAddress } from "../../../../app/store/slices/app";
import { setAddress } from "../../../../app/store/slices/registry";
import { ModalAddress } from "../modalAddress";
import { api } from "../../../../services/api";
import { customErrors } from "../../../../consts/form";

interface IAddressModal {
  open: boolean;
  onClose: () => void;
  selected?: any;
}

const AddressModal = ({ open, onClose, selected }: IAddressModal) => {
  const [errorResponse, setErrorResponse] = useState<string>("");
  const [error, setError] = useState<string>("");
  const {
    formState: { errors },
    register,
    getValues,
  } = useForm();
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const onCloseModal = () => {
    onClose();
  };

  const onSubmit = async () => {
    const { address } = getValues() as any;

    try {
      const response = await api.house.getHouseData({
        FiasId: address.FiasId,
        OnlyActual: true,
      });

      if (response.data.Data.Count === 0) {
        setError(customErrors.createRegistry);
        return;
      }

      dispatch(fetchSetAddress(address.FiasId));
      dispatch(setAddress(address));

      navigation("/occ/create/1");
    } catch (err: any) {
      console.log("err");
      setErrorResponse(err?.response?.data?.Data?.Message);
    }
  };

  const handleResetError = () => {
    setError("");
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "480px",
          },
        },
      }}
    >
      <DialogTitle paddingTop={"14px"}>
        <Typography
          fontFamily="GolosBold"
          sx={{
            width: "375px",
            paddingBottom: "14px",
            fontSize: "20px",
            lineHeight: "28px",
          }}
        >
          Из списка выберите адрес дома, для которого хотите создать собрание
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid item xs={6}>
          <ModalAddress
            {...register("address", {
              required: "Поле не заполнено",
            })}
            selected={selected}
            error={
              (errors["address"]?.type as unknown as string) === "required"
            }
            resetError={handleResetError}
          />
          {(error || errorResponse) && (
            <FormHelperText error>{error || errorResponse}</FormHelperText>
          )}
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          display: "block",
          padding: "0 24px 16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "0 8px",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            sx={{
              width: "85px",
              height: "38px",
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            Отмена
          </Button>
          <Button
            variant="contained"
            style={{
              borderRadius: 10,
              width: "120px",
              height: "38px",
              fontSize: "14px",
              lineHeight: "20px",
            }}
            component="span"
          >
            <Typography
              variant="caption"
              sx={{
                fontFamily: "GolosMedium",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#fff",
              }}
              onClick={onSubmit}
            >
              Продолжить
            </Typography>
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export { AddressModal };
