import { Box } from "@mui/material";
import { Content } from "../../components/content";
import { Layout } from "../../components/layout";
import UsefulCompaniesContacts from "../../features/useful-contacts";
import AddContact from "./modals/add-contact";
import RemoveContact from "./modals/remove-contact";
import { useAppDispatch } from "../../app/store";
import { toggleEditModal } from "../../app/store/slices/contacts";
import { addIcon24 } from "../../consts/icons";
import BaseButton from "../../components/base-button";

const Contacts = () => {
  const dispatch = useAppDispatch();
  const handleToggleAddModal = () => {
    dispatch(toggleEditModal({ isOpen: true }));
  };

  return (
    <Layout>
      <Content>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <h1 className="title-h1">Полезные контакты</h1>
          <BaseButton startIcon={addIcon24()} onClick={handleToggleAddModal}>
            Добавить контакт
          </BaseButton>
        </Box>
        <UsefulCompaniesContacts />
        <AddContact />
        <RemoveContact />
      </Content>
    </Layout>
  );
};

export default Contacts;
