import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/app/store";
import { dropdownSecondaryChevron } from "@/consts/icons";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import {
  fetchRosreestrLoadInfo,
  loadApartmentsComparison,
  setStatusFilter,
  startRosreestrDataUpload,
  toggleApartmentСomparisonModal,
  toggleHouseDetailsCompliantModal,
  toggleHouseDetailsUpdatingModal,
  toggleSystemMessage,
} from "@/app/store/slices/uk/rosreestr";
import { activeAddress } from "@/app/store/slices/user";
import { SnackbarType, showSnackbar } from "@/app/store/slices/snackbar";
import { ContentContext } from "@/components/content";
import registryFolderIcon from "@/assets/images/registryFolder.svg";
import registrySearchIcon from "@/assets/images/registrySearch.svg";
import { UKRegistry } from "@/types";
import {
  RegistryUpdatedModal,
  HouseDetailsUpdatingModal,
  HouseDetailsCompliantModal,
  ApartmentСomparisonModal,
  SizeSharesErrorModal,
} from "./modals";
import BaseHeadlineAlert from "@/components/BaseHeadlineAlert";
import BaseButton from "@/components/base-button";
import BaseDropdown, { BaseDropdownMenuItem } from "@/components/base-dropdown";
import BaseTable, {
  BaseContainer,
  BaseTableToolbar,
  BaseTableHead,
  BaseTableRow,
  BaseTableHeadCell,
  BaseTableBody,
  BaseTableCell,
} from "@/components/base-table";
import BaseTooltip from "@/components/base-tooltip";
import { Spinner } from "@/components/spinner";
import RosreestrApartmentStatus from "./ApartmentStatus";
import RosreestrApartmentRow from "./ApartmentRow";
import styles from "./styles.module.scss";
import { UKRegistryService } from "@/services/v2";

const UKRegistryRosreestr: React.FC = () => {
  const dispatch = useAppDispatch();

  const address = useSelector(activeAddress);
  const { details, query, status, isLoading, pagination, apartments, isStartRosreestrUploadLoading } = useSelector(
    (state: RootState) => state.ukRosreestr
  );

  const { measureRef, isIntersecting, observer } = useInfiniteScroll();
  const mainEl = useContext(ContentContext);
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);
  const [emptyTableHeight, setEmptyTableHeight] = useState<number>(0);

  const hasMore = useMemo(() => pagination && pagination.Index < pagination.Count, [pagination]);

  function updateFilter(value: UKRegistry.RosreestrСomparisonStatus) {
    if (status === value) {
      dispatch(setStatusFilter(null));
      return;
    }

    dispatch(setStatusFilter(value));
  }

  function handleApartmentСomparisonSelect(value: UKRegistry.RosreestrСomparison) {
    if (value.Status.Key === UKRegistry.RosreestrСomparisonStatus.Match) {
      dispatch(
        toggleHouseDetailsCompliantModal({ isShown: true, comparisonId: value.Id, apartmentNumber: value.Number })
      );
      return;
    }

    if (value.Status.Key === UKRegistry.RosreestrСomparisonStatus.InProgress) {
      dispatch(toggleHouseDetailsUpdatingModal(true));
      return;
    }

    dispatch(toggleApartmentСomparisonModal({ isShown: true, comparisonId: value.Id }));
  }

  function loadFromRosreestr() {
    dispatch(startRosreestrDataUpload());
  }

  function getEmptyTableHeight() {
    if (mainEl.current && tableBodyRef.current) {
      const rect = tableBodyRef.current.getBoundingClientRect();
      const pageHeight = (mainEl.current as HTMLElement).getBoundingClientRect();
      setEmptyTableHeight(pageHeight.height - rect.top + 48); // 48 - top & bottom paddings by 24
    }
  }

  async function fetch() {
    try {
      await dispatch(fetchRosreestrLoadInfo()).unwrap();
    } catch (error) {
      dispatch(
        showSnackbar({
          key: "fetch-house-details",
          body: error && typeof error === "string" ? error : "Неизвестная ошибка. Попробуйте позже еще раз",
          type: SnackbarType.ERROR,
        })
      );
    } finally {
      checkReestrMissingData();
    }
  }

  const fetchMoreData = async () => {
    try {
      const { Index, Count } = await dispatch(loadApartmentsComparison()).unwrap();
      if (observer && isIntersecting && Index === Count) {
        observer.disconnect();
      }
    } catch (error) {}
  };

  async function checkReestrMissingData() {
    if (!address) {
      return;
    }

    const { data } = await UKRegistryService.getApartmentsComparison({
      HouseFiasId: address?.FiasId,
      Status: UKRegistry.RosreestrСomparisonStatus.ReestrDataMissing,
      limit: 10,
    });

    if (data.Data.length > 0) {
      await dispatch(
        toggleSystemMessage({
          isShown: true,
          date: Date.now(),
          apartments: data.Data,
          pagination: data.Page,
        })
      );
    }
  }

  useEffect(() => {
    if (isIntersecting) {
      fetchMoreData();
    }
  }, [isIntersecting]);

  useEffect(() => {
    fetch();
  }, [address]);

  useEffect(() => {
    getEmptyTableHeight();

    window.addEventListener("resize", getEmptyTableHeight, true);

    return () => {
      window.removeEventListener("resize", getEmptyTableHeight, true);
      dispatch(
        toggleSystemMessage({
          isShown: false,
          date: null,
          apartments: [],
          pagination: null,
        })
      );
    };
  }, []);

  return (
    <>
      <BaseHeadlineAlert title="Важно знать" name="rosreestr">
        <>
          <p>Персональные данные из Росреестра будут отображаться только в двух случаях:</p>
          <p>1{")"} Если они совпали с данными в заполненном вами реестре собственников</p>
          <p>
            2{")"} Если пользователь вошел в мобильное приложение Локоло через Госуслуги, в соответствии с
            пользовательским соглашением
          </p>
          <p>В иных случаях распространение персональных данных запрещено законом</p>
        </>
      </BaseHeadlineAlert>

      <BaseContainer className={styles.table}>
        <BaseTableToolbar
          caption={
            details ? (
              <span className={styles.table_caption}>
                {details?.LastLoadDate ? (
                  details?.IsLoaded ? (
                    `Последнее обновление: ${details.LastLoadDate}`
                  ) : (
                    <span className="flex middle" style={{ gap: "4px" }}>
                      {timeIcon}
                      <span>Отправлено, обновляем...</span>
                    </span>
                  )
                ) : (
                  "Вы не запрашивали данные из Росреестра"
                )}
                {(!details?.LastLoadDate || details?.IsLoaded) && (
                  <BaseTooltip title={details?.ReloadError ?? ""}>
                    <span
                      className="color--primary sf-text-regular text--primary"
                      aria-disabled={!details?.CanReload ?? false}
                      role="button"
                      onClick={() => (details?.CanReload ? loadFromRosreestr() : null)}
                    >
                      Обновить
                    </span>
                  </BaseTooltip>
                )}
              </span>
            ) : null
          }
        >
          <BaseDropdown
            className={styles.filters}
            placeholder="Фильтр"
            value={status}
            isSelectable={true}
            disabled={
              (!status &&
                query.length === 0 &&
                apartments.length === 0 &&
                (!details?.LastLoadDate || (!!details?.LastLoadDate && !details?.IsLoaded))) ||
              isLoading
            }
            onSelect={(value) => updateFilter(value)}
            customIcon={dropdownSecondaryChevron}
          >
            <div className={styles.filters_options}>
              <BaseDropdownMenuItem value={UKRegistry.RosreestrСomparisonStatus.Match} defaultHover>
                <RosreestrApartmentStatus status={UKRegistry.RosreestrСomparisonStatus.Match} />
              </BaseDropdownMenuItem>
              <BaseDropdownMenuItem value={UKRegistry.RosreestrСomparisonStatus.DontMatch} defaultHover>
                <RosreestrApartmentStatus status={UKRegistry.RosreestrСomparisonStatus.DontMatch} />
              </BaseDropdownMenuItem>
              <BaseDropdownMenuItem value={UKRegistry.RosreestrСomparisonStatus.ReestrDataMissing} defaultHover>
                <RosreestrApartmentStatus status={UKRegistry.RosreestrСomparisonStatus.ReestrDataMissing} />
              </BaseDropdownMenuItem>
              <BaseDropdownMenuItem value={UKRegistry.RosreestrСomparisonStatus.InProgress} defaultHover>
                <RosreestrApartmentStatus status={UKRegistry.RosreestrСomparisonStatus.InProgress} />
              </BaseDropdownMenuItem>
            </div>
          </BaseDropdown>
        </BaseTableToolbar>

        <div className={styles.table_container}>
          <BaseTable className={styles.table_itself}>
            <BaseTableHead className={styles.table_head}>
              <BaseTableRow>
                <BaseTableHeadCell>№ помещения</BaseTableHeadCell>
                <BaseTableHeadCell>Площадь (м²)</BaseTableHeadCell>
                <BaseTableHeadCell>Кадастровый номер</BaseTableHeadCell>
                <BaseTableHeadCell>Тип</BaseTableHeadCell>
                <BaseTableHeadCell>Данные из Росреестра</BaseTableHeadCell>
                <BaseTableHeadCell></BaseTableHeadCell>
              </BaseTableRow>
            </BaseTableHead>

            {isLoading ? (
              <BaseTableBody className={styles.empty} refProp={tableBodyRef}>
                <BaseTableRow>
                  <BaseTableCell colspan={8} style={{ height: `${emptyTableHeight}px` }}>
                    <div className="flex center">
                      <Spinner color="#226dff" size={36} />
                    </div>
                  </BaseTableCell>
                </BaseTableRow>
              </BaseTableBody>
            ) : apartments.length ? (
              <BaseTableBody>
                {apartments.map((it, index) => (
                  <RosreestrApartmentRow
                    key={it.Id}
                    apartment={it}
                    onSelect={() => handleApartmentСomparisonSelect(it)}
                  />
                ))}
                {hasMore && (
                  <tr className={styles.loading_more} ref={measureRef}>
                    <td colSpan={8}>
                      <div className="flex center">
                        <Spinner color="#226dff" size={36} />
                      </div>
                    </td>
                  </tr>
                )}
              </BaseTableBody>
            ) : (
              <BaseTableBody className={styles.empty} refProp={tableBodyRef}>
                <BaseTableRow>
                  <BaseTableCell colspan={8} style={{ height: `${emptyTableHeight}px` }}>
                    <div className="center">
                      {query.length > 0 || status !== null ? (
                        <p className="text--primary sf-pro-regular color--text-primary">Поиск не дал результатов</p>
                      ) : details?.LastLoadDate && !details?.IsLoaded ? (
                        <div className={styles.empty_content}>
                          <img width="200" height="200" src={registryFolderIcon} alt="" />
                          <div>
                            <h4 className="headline-h4 sf-text-bold color--text-primary">
                              Информация из Росреестра запрошена
                            </h4>
                            <p className="text--primary sf-pro-regular color--text-secondary">
                              Получение и обработка данных обычно занимает 1-2 полных дня.
                            </p>
                          </div>
                        </div>
                      ) : details?.LastLoadDate && details?.IsLoaded && !details?.CanReload ? (
                        <div className={styles.empty_content}>
                          <img width="200" height="200" src={registrySearchIcon} alt="" />
                          <div>
                            <h4 className="headline-h4 sf-text-bold color--text-primary">
                              Информация из Росреестра не найдена
                            </h4>
                            <p className="text--primary sf-pro-regular color--text-primary">
                              Не удалось получить данные из Росреестра, попробуйте запросить данные позднее
                            </p>
                          </div>
                          <BaseButton
                            disabled={true}
                            onClick={loadFromRosreestr}
                            isLoading={isStartRosreestrUploadLoading}
                          >
                            Запросить из Росреестра
                          </BaseButton>
                        </div>
                      ) : (
                        <div className={styles.empty_content}>
                          <img width="200" height="200" src={registrySearchIcon} alt="" />
                          <div>
                            <h4 className="headline-h4 sf-text-bold color--text-primary">
                              Запросите информацию из Росреестра
                            </h4>
                            <p className="text--primary sf-pro-regular color--text-primary">
                              Получение и обработка данных обычно занимает 1-2 полных дня. <br /> Отслеживать
                              поступление данных вы можете на вкладке "Данные из Росреестра". <br /> Вы можете работать
                              с полученными данными и переносить их в свой реестр не дожидаясь завершения.
                            </p>
                          </div>
                          <BaseButton onClick={loadFromRosreestr} isLoading={isStartRosreestrUploadLoading}>
                            Запросить из Росреестра
                          </BaseButton>
                        </div>
                      )}
                    </div>
                  </BaseTableCell>
                </BaseTableRow>
              </BaseTableBody>
            )}
          </BaseTable>
        </div>
      </BaseContainer>

      <RegistryUpdatedModal />
      <HouseDetailsUpdatingModal />
      <HouseDetailsCompliantModal />
      <ApartmentСomparisonModal />
      <SizeSharesErrorModal />
    </>
  );
};

export default UKRegistryRosreestr;

const timeIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      opacity="0.3"
      d="M2.34315 13.6569C1.22433 12.538 0.4624 11.1126 0.153718 9.56072C-0.154964 8.00887 0.00346269 6.40034 0.608964 4.93853C1.21446 3.47672 2.23985 2.22729 3.55544 1.34824C4.87103 0.469192 6.41775 -1.88681e-08 8 0V8L2.34315 13.6569Z"
      fill="#898D93"
    />
    <circle cx="8" cy="8" r="7.25" stroke="#898D93" strokeWidth="1.5" />
    <path d="M8 4V8L5 11" stroke="#898D93" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);
