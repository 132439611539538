import { useCallback, useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames/bind";
import { RootState, useAppDispatch } from "@/app/store";
import { fetchTemplateGroups } from "@/app/store/slices/template-groups";
import { fetchTemplateQuestions } from "@/app/store/slices/template-questions";
import { Accordion, Collapsed, Expanded } from "@/components/base-accordion";
import DropdownContext from "@/components/base-dropdown/context";
import { paths } from "@/consts/routes";
import styles from "./styles.module.scss";
import { checkedIcon } from "@/consts/icons";

const cx = classNames.bind(styles);

interface IQuestionTemplateDropdownProps {
  selectedId?: any;
  onSelect?: (question: any) => void;
}

const QuestionTemplateDropdown: React.FC<IQuestionTemplateDropdownProps> = ({ selectedId, onSelect }) => {
  const navigate = useNavigate();
  const context = useContext(DropdownContext);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const groups = useSelector((state: RootState) => state.templateGroups.groups.data);
  const groupQuestions = useSelector((state: RootState) => state.templateQuestions.groupQuestions.data);

  async function getQuestionsByGroupId(GroupId: any) {
    return await dispatch(fetchTemplateQuestions({ GroupId })).unwrap();
  }

  function handleTemplatesManagementClick(event: React.SyntheticEvent) {
    event.preventDefault();
    navigate(paths.managementTemplate, { state: location.pathname });
  }

  function selectTemplate(question: any) {
    if (onSelect) {
      onSelect(question);
      context?.onSelect(question);
    }
  }

  useEffect(() => {
    if (groups.length === 0) {
      dispatch(fetchTemplateGroups());
    }
  }, []);

  return (
    <div className={styles.box}>
      <NavLink
        className={styles.templates_management}
        to={paths.managementTemplate}
        onClick={handleTemplatesManagementClick}
      >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.7314 1.95491C10.9124 2.25491 11.2374 2.43891 11.5884 2.43891C12.1984 2.43891 13.1634 2.43891 13.7784 2.43891C14.1354 2.43891 14.4654 2.62991 14.6444 2.93891C14.9424 3.45591 15.4054 4.25691 15.7054 4.77791C15.8874 5.09291 15.8844 5.48191 15.6964 5.79291C15.4064 6.27591 14.9714 6.99891 14.6794 7.48391C14.4884 7.80191 14.4884 8.19791 14.6794 8.51591C14.9714 9.00091 15.4064 9.72391 15.6964 10.2069C15.8844 10.5179 15.8874 10.9069 15.7054 11.2219C15.4054 11.7429 14.9424 12.5439 14.6444 13.0609C14.4654 13.3699 14.1354 13.5609 13.7784 13.5609C13.1634 13.5609 12.1984 13.5609 11.5884 13.5609C11.2374 13.5609 10.9124 13.7449 10.7314 14.0449C10.4834 14.4579 10.1284 15.0469 9.88042 15.4589C9.69942 15.7599 9.37442 15.9439 9.02342 15.9439C8.44642 15.9439 7.55442 15.9439 6.97742 15.9439C6.62642 15.9439 6.30142 15.7599 6.12042 15.4589C5.87242 15.0469 5.51742 14.4579 5.26942 14.0449C5.08842 13.7449 4.76342 13.5609 4.41242 13.5609C3.80242 13.5609 2.83742 13.5609 2.22242 13.5609C1.86542 13.5609 1.53542 13.3699 1.35642 13.0609C1.05842 12.5439 0.595419 11.7429 0.295419 11.2219C0.113419 10.9069 0.116419 10.5179 0.304419 10.2069C0.594419 9.72391 1.02942 9.00091 1.32142 8.51591C1.51242 8.19791 1.51242 7.80191 1.32142 7.48391C1.02942 6.99891 0.594419 6.27591 0.304419 5.79291C0.116419 5.48191 0.113419 5.09291 0.295419 4.77791C0.595419 4.25691 1.05842 3.45591 1.35642 2.93891C1.53542 2.62991 1.86542 2.43891 2.22242 2.43891C2.83742 2.43891 3.80242 2.43891 4.41242 2.43891C4.76342 2.43891 5.08842 2.25491 5.26942 1.95491C5.51742 1.54191 5.87242 0.952908 6.12042 0.540908C6.30142 0.239908 6.62642 0.0559082 6.97742 0.0559082C7.55442 0.0559082 8.44642 0.0559082 9.02342 0.0559082C9.37442 0.0559082 9.69942 0.239908 9.88042 0.540908C10.1284 0.952908 10.4834 1.54191 10.7314 1.95491ZM8.00042 4.99991C9.65642 4.99991 11.0004 6.34391 11.0004 7.99991C11.0004 9.65591 9.65642 10.9999 8.00042 10.9999C6.34442 10.9999 5.00042 9.65591 5.00042 7.99991C5.00042 6.34391 6.34442 4.99991 8.00042 4.99991Z"
            fill="#226DFF"
          />
        </svg>
        <span className="text--primary sf-text-medium">Настроить шаблоны</span>
      </NavLink>

      <div className={styles.templates}>
        <p className="text--primary sf-text-medium">ГРУППЫ ШАБЛОНОВ:</p>
        <Accordion className={styles.templates_accordion} initial="" isAsync={true} callback={getQuestionsByGroupId}>
          {groups.map((group) => (
            <div key={group.Id}>
              <Collapsed className={styles.collapsed} id={group.Id}>
                <span className="text--primary sf-text-semibold">{group.Title}:</span>
              </Collapsed>
              <Expanded id={group.Id}>
                {groupQuestions[group.Id] && groupQuestions[group.Id].length > 0 && (
                  <div className={styles.templates_list}>
                    {groupQuestions[group.Id].map((question) => (
                      <TemplateItem
                        key={`${question.GroupId}-${question.Id}`}
                        selectedId={selectedId}
                        question={question}
                        onSelect={() => selectTemplate(question)}
                      />
                    ))}
                  </div>
                )}
              </Expanded>
            </div>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

interface ITemplateItemProps {
  question: any;
  selectedId?: any;
  onSelect?: () => void;
}

const TemplateItem: React.FC<ITemplateItemProps> = ({ question, selectedId, onSelect }) => {
  const [isContentMultiline, setContentMultiline] = useState<boolean>(false);

  const onRefChange = useCallback((node: HTMLElement) => {
    setContentMultiline(node && node.offsetHeight > 20);
  }, []);

  return (
    <div className={cx({ template_question: true, multiline: isContentMultiline })} onClick={onSelect} role="button">
      <span ref={onRefChange} className="text--primary sf-text-regular">
        {question.Title}
      </span>
      {selectedId && selectedId === question.Id && checkedIcon()}
    </div>
  );
};

export default QuestionTemplateDropdown;
