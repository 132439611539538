import { Typography, Box, Button } from "@mui/material";

import styles from "./questionReadyList.module.scss";
import { questions } from "./questions";
import { useEffect } from "react";
import { RootState, useAppDispatch } from "@/app/store";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { paths } from "@/consts/routes";
import { Accordion, Collapsed, Expanded } from "@/components/base-accordion";
import { fetchQuestionsByGroup, fetchTemplateQuestionsByGroup } from "@/app/store/slices/createOccOld";
import {
  fetchUKTemplateGroups,
  fetchDefaultQuestionsByGroupId,
  fetchQuestionsByGroupId,
} from "@/app/store/slices/templates/actions";
import { getTemplateGroups } from "@/app/store/slices/templates";
import { Templates } from "@/types";
import TemplateItem from "./templateItem";
import omit from "lodash/omit";

type SelectQuestionType = {
  QuorumId: number;
  Title: string;
  Value: string;
};

type QuestionsGroupsType = {
  Id: number;
  Title: string;
  Order: number;
};

type QuestionsByGroupType = {
  Id: number;
  GroupId: number;
  GroupTitle: string;
  QuorumId: number;
  QuorumName: string;
  Title: string;
  Value: string;
  Order: number;
};

interface IQuestionReadyList {
  onSelect: (data: SelectQuestionType) => void;
  questionsGroups: Array<QuestionsGroupsType>;
  questionsByGroup: Array<QuestionsByGroupType>;
  questionsByGroupLoading: boolean;
  activeGroupId: number;
  onLoadQuestionsByGroup: (groupId: number) => void;
  onSelectQuestionFromGroup: (data: SelectQuestionType) => void;
}

export const QuestionReadyList: React.FC<IQuestionReadyList> = ({
  onSelect,
  questionsGroups,
  questionsByGroup,
  onSelectQuestionFromGroup,
}: IQuestionReadyList) => {
  const dispatch = useAppDispatch();

  const questionsList = useSelector((state: RootState) => state.createOccOld.questions);
  const company = useSelector((state: RootState) => state.user.company);
  const templateGroups = useSelector(getTemplateGroups(company?.Type));
  const navigate = useNavigate();
  const location = useLocation();

  const handleSelect = (question: Templates.Question) => {
    onSelect(omit(question, "Id"));
  };

  const getQuestionsByGroup = async (key: Templates.GroupWithQuestions["key"]) => {
    const group = templateGroups.find((it) => it.key === key);
    if (!group) {
      return Promise.resolve([]);
    }

    if (group.questions.length > 0) {
      return Promise.resolve(group.questions);
    }

    if (group.isDefault) {
      return await dispatch(fetchDefaultQuestionsByGroupId(group.group.Id)).unwrap();
    }
    return await dispatch(fetchQuestionsByGroupId(group.group.Id)).unwrap();
  };

  // const onClick = (indx: number) => {
  //   const current = questions[indx];

  //   const data = {
  //     QuorumId: 2,
  //     Title: current.label,
  //     Value: current.text,
  //   };

  //   onSelect(data);
  // };

  const handleNavigateToTemplate = () => {
    navigate(paths.managementTemplate, { state: location.pathname });
  };

  // const handleSelectGroup = ({ id, isDefault }: { id: number; isDefault: boolean }) => {
  //   if (!isDefault) {
  //     dispatch(fetchTemplateQuestionsByGroup({ GroupId: id }));

  //     return;
  //   }

  //   dispatch(fetchQuestionsByGroup(id));
  // };

  const fetch = async () => {
    try {
      await dispatch(fetchUKTemplateGroups()).unwrap();
    } catch (error) {}
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <div className={styles.title}>
        <h2>Шаблоны вопросов:</h2>
        <Button variant="contained" className={styles.button} onClick={handleNavigateToTemplate}>
          Управление шаблонами
        </Button>
      </div>
      <Typography color={"rgba(0, 0, 0, 0.54)"} fontSize={"14px"}>
        Выберите интересующую Вас группу и кликните на вопрос для добавления в повестку дня. Шаблоны можно удалять и
        редактировать.
      </Typography>
      <Box pt={3}>
        <div className={styles.accordion}>
          <Accordion className={styles.templates_accordion} isAsync={true} callback={getQuestionsByGroup}>
            {templateGroups.map((group) => (
              <div className={styles.templates_group} key={group.key}>
                <Collapsed className={styles.collapsed} id={group.key}>
                  <span className="golosBold">{group.group.Title}:</span>
                </Collapsed>
                <Expanded id={group.key}>
                  {group.questions.length > 0 && (
                    <div className={styles.templates_list}>
                      {group.questions.map((question) => (
                        <TemplateItem
                          key={`${group.key}-${question.Id}`}
                          question={question}
                          onSelect={() => handleSelect(question)}
                          isSelected={questionsList.data.find(
                            (it: any) => it.Title === question.Title && it.Value === question.Value
                          )}
                        />
                      ))}
                    </div>
                  )}
                </Expanded>
              </div>
            ))}
          </Accordion>
        </div>
      </Box>
    </>
  );
};
