import { useEffect, useState, forwardRef } from "react";
import { InputLabel, MenuItem, Select } from "@mui/material";
import { api } from "../../../services/api";
import { LabelTooltip } from "../../../components/tooltip";

type QuorumType = {
  id: number;
  name: string;
  key: string;
};

export const Quorum = forwardRef(
  ({ onChange, name, onBlur, error, defaultValue }: any, ref) => {
    const [load, setLoad] = useState(false);
    const [options, setOptions] = useState<QuorumType[]>([]);

    const init = async () => {
      try {
        const response = await api.occ.getQuorum();
        setOptions(response.data.Data);
        setLoad(true);
      } catch (err: any) {
        console.error(err);
        setLoad(true);
      }
    };

    useEffect(() => {
      init();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!load) return null;

    return (
      <>
        <InputLabel htmlFor="QuorumId" sx={{ display: "flex", gap: "4px" }}>
          Кворум по вопросу повестки
          <LabelTooltip
            title="Выберите значение из списка - из какого расчета будет определяться набрался ли кворум "
            placement="right"
            arrow
            enterDelay={700}
          />
        </InputLabel>
        <Select
          name={name}
          id="QuorumId"
          fullWidth
          disabled={!load}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          error={error}
          defaultValue={defaultValue}
        >
          {options.map((item) => (
            <MenuItem key={item.key} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </>
    );
  }
);
