import React, { createContext, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { paths } from "@/consts/routes";
import styles from "./styles.module.scss";

interface IContentProps {
  children: JSX.Element | JSX.Element[];
  style?: React.CSSProperties;
}

export const ContentContext = createContext({ current: null });

const Content: React.FC<IContentProps> = ({ children, style }) => {
  const mainEl = useRef(null);
  const { pathname } = useLocation();

  const isUsefulContactsPage: boolean = useMemo(() => pathname === paths.contacts, [pathname]);

  return (
    <main
      ref={mainEl}
      className={`${styles.content} ${isUsefulContactsPage ? styles["contacts_page"] : ""}`}
      style={style}
    >
      <ContentContext.Provider value={mainEl}>{children}</ContentContext.Provider>
    </main>
  );
};

export { Content };
