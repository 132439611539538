import { Alert, Grid, Typography } from "@mui/material";
import { memo } from "react";
import { Events } from "../../../events";
import styles from "../../create.module.scss";

const Instruction = memo(() => {
  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          sx={{ paddingBottom: "24px", color: "#000", opacity: 1 }}
        >
          Проверьте заполненные сведения и при необходимости вернитесь к
          редактированию сведений через кнопку "Назад"
        </Typography>
        <Alert severity="info" icon={false}>
          <span style={{ fontFamily: "GolosMedium" }}>
            Для завершения процедуры создания собрания, нажмите кнопку
            “Создать”.
          </span>
          <br />
          <br />
          После нажатия кнопки “Создать” система переведет собрание в статус
          "Запланировано", а также:
          <br />
          <Events
            classes={{
              root: styles.eventsContainer,
              row: styles.eventsRow,
              label: styles.eventsLabel,
              value: styles.eventsValue,
            }}
            variant="2"
          />
        </Alert>
      </Grid>
    </>
  );
});

export { Instruction };
