import {
  IApiModule,
  OccAllResponse,
  OccCreateResponse,
  OccIdType,
} from "../types";
import Qs from "qs";
import { OccType } from "../../../types/occ";

export default {
  getOccAll: ({ StatusId = null, FiasId = null, limit }: OccAllResponse) =>
    !StatusId && !FiasId && StatusId !== 0
      ? {
          method: "GET",
          url: `/api/occ/get-all?${Qs.stringify({ limit })}`,
        }
      : {
          method: "GET",
          url: `/api/occ/get-all?${Qs.stringify({ StatusId, FiasId })}`,
        },
  getOccById: (OccId: OccIdType) => ({
    method: "GET",
    url: `/api/occ/get?${Qs.stringify({ OccId })}`,
  }),
  deleteOcc: ({ OccId }: OccIdType) => ({
    method: "POST",
    url: `/api/occ/delete`,
    data: {
      OccId,
    },
  }),
  updateOcc: (data: any) => ({
    method: "POST",
    url: `/api/occ/update`,
    data,
  }),
  getOccDataAll: ({ OccId }: OccIdType) => ({
    method: "GET",
    url: `/api/occ/get?${Qs.stringify({ OccId })}`,
  }),
  getOccData: ({ OccId }: OccIdType) => ({
    method: "GET",
    url: `/api/occ/get-order?${Qs.stringify({ OccId })}`,
  }),
  getOccAuthor: ({ OccId }: OccIdType) => ({
    method: "GET",
    url: `/api/occ/get-author?${Qs.stringify({ OccId })}`,
  }),
  getOccAdministrator: ({ OccId }: OccIdType) => ({
    method: "GET",
    url: `/api/occ/get-administrator?${Qs.stringify({ OccId })}`,
  }),
  createOcc: (data: OccCreateResponse) => ({
    method: "POST",
    url: `/api/occ/create`,
    data: { ...data },
  }),
  createOccAuthor: (data) => ({
    method: "POST",
    url: `/api/occ/set-author`,
    data: { ...data },
  }),
  createOccAdministrator: (data) => ({
    method: "POST",
    url: `/api/occ/set-administrator`,
    data: { ...data },
  }),
  setQuestions: (data) => ({
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
    },
    url: `/api/occ/set-questions`,
    data: data,
  }),
  finishedCreateOcc: (OccId: number) => ({
    method: "POST",
    url: `/api/occ/start`,
    data: { OccId },
  }),
  getOccType: () => ({
    method: "GET",
    url: `/api/data/occ/get-type`,
  }),
  getOccFroms: () => ({
    method: "GET",
    url: `/api/data/occ/get-form`,
  }),
  getLocation: () => ({
    method: "GET",
    url: `/api/data/occ/get-location`,
  }),
  getDocuments: (OccId: OccIdType) => ({
    method: "GET",
    url: `api/occ/get-documents?${Qs.stringify({ OccId })}`,
  }),

  getQuorum: () => ({
    method: "GET",
    url: `/api/data/occ/get-quorum`,
  }),
  getQuestions: ({ OccId }: OccIdType) => ({
    method: "GET",
    url: `/api/occ/get-questions?${Qs.stringify({ OccId })}`,
  }),
  getAllQuestions: (OccId: OccIdType) => ({
    method: "GET",
    url: `api/occ/get-questions-result?${Qs.stringify({ OccId })}`,
  }),
  getProgress: (OccId: OccIdType) => ({
    method: "GET",
    url: `api/occ/get-progress?${Qs.stringify({ OccId })}`,
  }),
  getOwnerQuestions: (OccId: OccType) => ({
    method: "GET",
    url: `api/occ/get-owners-questions-results?${Qs.stringify({
      OccId,
    })}`,
  }),
  deleteQuestions: (OccQuestionId: number) => ({
    method: "POST",
    url: `/api/occ/delete-question`,
    data: { OccQuestionId },
  }),
  getOwnerRegistry: (OccId: OccType) => ({
    method: "GET",
    url: `api/occ/get-owner-reestr?${Qs.stringify({ OccId })}`,
  }),
  setOwnerResult: (data: any) => ({
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
    },
    url: "api/occ/set-owner-result",
    data,
  }),
  getOwnerResult: ({
    OccId,
    OwnerId,
  }: {
    OccId: OccType;
    OwnerId: number;
  }) => ({
    method: "GET",
    url: `api/occ/get-owner-result?${Qs.stringify({ OccId, OwnerId })}`,
  }),
} as IApiModule;
