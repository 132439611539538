import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { chevronRight24Icon } from "@/consts/icons";
import { ProfileService } from "@/services/v2";
import { Pagination, Profile } from "@/types";
import { paths } from "@/consts/routes";
import { activeAddress } from "@/app/store/slices/user";
import { ContentContext } from "@/components/content";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import PageHeader from "@/components/PageHeader";
import BaseQuerySearch from "@/components/base-query-search";
import {
  BaseTable,
  BaseContainer,
  BaseTableHead,
  BaseTableRow,
  BaseTableHeadCell,
  BaseTableBody,
  BaseTableCell,
} from "@/components/base-table";
import OccStatus from "@/components/occ-status";
import { Spinner } from "@/components/spinner";
import styles from "./styles.module.scss";

const CadastralNumber: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const address = useSelector(activeAddress);

  const [query, setQuery] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [houses, setHouses] = useState<Profile.HousesWithActualOCC[]>([]);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const [emptyTableHeight, setEmptyTableHeight] = useState<number>(0);

  const mainEl = useContext(ContentContext);
  const { measureRef, isIntersecting, observer } = useInfiniteScroll();
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);

  const hasMore = useMemo(() => pagination && pagination.Index < pagination.Count, [pagination]);
  const filtered = useMemo(
    () => houses.filter((it) => it.Address.toLowerCase().includes(query.toLowerCase())),
    [houses, query]
  );

  function handleOccClick(house: Profile.HousesWithActualOCC) {
    if (house.Occ?.Id) {
      localStorage.setItem("my-occ-from-path", location.pathname);
      navigate(paths.myOccById(house.Occ.Id).details);
    }
  }

  function handleQueryChange(value: string) {
    if (value !== query) {
      setQuery(value);
    }
  }

  function getEmptyTableHeight() {
    if (mainEl.current && tableBodyRef.current) {
      const rect = tableBodyRef.current.getBoundingClientRect();
      const pageHeight = (mainEl.current as HTMLElement).getBoundingClientRect();
      setEmptyTableHeight(pageHeight.height - rect.top + 48); // 48 - top & bottom paddings by 24
    }
  }

  async function fetch() {
    if (!address) {
      return;
    }

    setQuery("");
    setIsLoading(true);
    setPagination(null);

    try {
      const { data } = await ProfileService.getUkHousesWithActualOCC(address.FiasId);
      setHouses(data.Data);
      setPagination(data.Page);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchMoreData() {
    if (!address) {
      if (observer) {
        observer.disconnect();
      }
      return;
    }

    try {
      const { data } = await ProfileService.getUkHousesWithActualOCC(address.FiasId, (pagination?.Index ?? 0) + 1);
      setHouses((prevState) => [...prevState, ...data.Data]);
      setPagination(data.Page);
      if (observer && isIntersecting && data.Page.Index === data.Page.Count) {
        observer.disconnect();
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (isIntersecting) {
      fetchMoreData();
    }
  }, [isIntersecting]);

  useEffect(() => {
    getEmptyTableHeight();
    fetch();

    window.addEventListener("resize", getEmptyTableHeight, true);

    return () => {
      window.removeEventListener("resize", getEmptyTableHeight, true);
    };
  }, []);

  return (
    <>
      <PageHeader title="Мои Дома">
        <BaseQuerySearch
          style={{ width: "340px" }}
          value={query}
          onChange={handleQueryChange}
          placeholder="Поиск по адресу"
        />
      </PageHeader>

      <BaseContainer className={styles.table}>
        <div className={styles.table_container}>
          <BaseTable>
            <BaseTableHead>
              <BaseTableRow className={styles.table_row}>
                <BaseTableHeadCell>Адрес</BaseTableHeadCell>
                <BaseTableHeadCell>Количество пользователей мобильного приложения Локоло</BaseTableHeadCell>
                <BaseTableHeadCell>Количество подтвержденных собственников</BaseTableHeadCell>
                <BaseTableHeadCell>Запрошены данные из Росреестра</BaseTableHeadCell>
                <BaseTableHeadCell>Количество помещений в реестре</BaseTableHeadCell>
                <BaseTableHeadCell>Количество собственников в реестре</BaseTableHeadCell>
                <BaseTableHeadCell className={styles.table_row_status}>Статус последнего собрания</BaseTableHeadCell>
                <BaseTableHeadCell>Период голосования</BaseTableHeadCell>
                <BaseTableHeadCell></BaseTableHeadCell>
              </BaseTableRow>
            </BaseTableHead>

            {isLoading ? (
              <BaseTableBody className={styles.empty} refProp={tableBodyRef}>
                <BaseTableRow>
                  <BaseTableCell colspan={9} style={{ height: `${emptyTableHeight}px` }}>
                    <div className="flex center">
                      <Spinner color="#226dff" size={36} />
                    </div>
                  </BaseTableCell>
                </BaseTableRow>
              </BaseTableBody>
            ) : filtered.length ? (
              <>
                <BaseTableBody className={styles.table_body}>
                  {filtered.map((it, index) => (
                    <BaseTableRow className={styles.table_row} key={it.FiasId} onClick={() => handleOccClick(it)}>
                      <BaseTableHeadCell>
                        <span className="sf-text-semibold">{it.Address}</span>
                      </BaseTableHeadCell>
                      <BaseTableCell>{it.Lokolo.UsersCount}</BaseTableCell>
                      <BaseTableCell>{it.Lokolo.ConfirmedOwnersCount}</BaseTableCell>
                      <BaseTableCell>{it.Reestr.RosreestrRequestExist ? "Да" : "Нет"}</BaseTableCell>
                      <BaseTableCell>{it.Reestr.ApartmentsCount}</BaseTableCell>
                      <BaseTableCell>{it.Reestr.OwnersCount}</BaseTableCell>
                      <BaseTableCell className={styles.table_row_status}>
                        {it.Occ && <OccStatus statusId={it.Occ.StatusId} statusName={it.Occ.Status} />}
                      </BaseTableCell>
                      <BaseTableCell>{it.Occ?.VotingPeriod}</BaseTableCell>
                      <BaseTableCell>
                        {it.Occ && <span className={styles.table_row_icon}>{chevronRight24Icon("#818C99")}</span>}
                      </BaseTableCell>
                    </BaseTableRow>
                  ))}
                </BaseTableBody>
                {hasMore && (
                  <tbody className={styles.loading_more} ref={measureRef}>
                    <tr>
                      <td colSpan={9}>
                        <div className="flex center">
                          <Spinner color="#226dff" size={36} />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </>
            ) : (
              <BaseTableBody className={styles.empty} refProp={tableBodyRef}>
                <BaseTableRow>
                  <BaseTableCell colspan={9} style={{ height: `${emptyTableHeight}px` }}>
                    <div className="center">
                      {query.length > 0 ? (
                        <p className="text--primary sf-pro-regular color--text-primary">Поиск не дал результатов</p>
                      ) : (
                        <p className="text--primary sf-pro-regular color--text-primary">Дома пока не добавлены</p>
                      )}
                    </div>
                  </BaseTableCell>
                </BaseTableRow>
              </BaseTableBody>
            )}
          </BaseTable>
        </div>
      </BaseContainer>
    </>
  );
};

export default CadastralNumber;
