import { useMemo } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Profile } from "@/types";
import { VariantEnum } from "@/types/enum";
import { paths } from "@/consts/routes";
import { createOccMode } from "@/consts/state";
import { chevronRight16Icon, logoIcon, ukIcon } from "@/consts/icons";
import { RootState, useAppDispatch } from "@/app/store";
import { authLogout } from "@/app/store/slices/user";
import SelectAddressDropdown from "@/components/SelectAddressDropdown";
import BaseUserAvatar from "@/components/BaseUserAvatar";
import BaseButton from "@/components/base-button";
import styles from "./styles.module.scss";

interface IHeaderProps {
  variant?: VariantEnum;
}

const Header: React.FC<IHeaderProps> = ({ variant = VariantEnum.main }) => {
  const dispatch = useAppDispatch();

  const { profile, company } = useSelector((state: RootState) => state.user);
  const occId = useSelector((state: RootState) => state.createOcc.occId);
  const { mode } = useSelector((state: RootState) => state.createOccOld);

  const navigate = useNavigate();
  const location = useLocation();

  const stepId = String(Number(location.pathname.slice(-1)) - 1);
  const prevUrl = location.pathname.slice(0, -1) + stepId;

  const userInitials: string = useMemo(() => {
    let initials = profile?.last_name ?? "";
    if (profile?.first_name) initials += ` ${profile?.first_name?.charAt(0)}.`;
    if (profile?.middle_name) initials += ` ${profile?.middle_name?.charAt(0)}.`;
    return initials;
  }, [profile]);

  const handleGoBack = () => {
    if (stepId === "0" && mode === createOccMode.edit) {
      navigate(-1);
      localStorage.removeItem("modeOcc");
      return;
    }

    if (mode === createOccMode.edit) {
      navigate(-1);
      return;
    }

    if (stepId === "0") {
      navigate(paths.createOcc);
      return;
    }

    if (location.pathname === paths.managementTemplate) {
      if (company?.Type === Profile.CompanyType.SNT) {
        return navigate("/snt/create-occ");
      }

      return navigate(paths.createOcc);
    }

    navigate(prevUrl, { state: occId });
  };

  function handleLogout() {
    dispatch(authLogout()).unwrap();
  }

  return (
    <header className={styles["b-header"]}>
      {variant === VariantEnum.create ? (
        <div className={styles["b-header__v-create"]}>
          <KeyboardBackspaceIcon onClick={handleGoBack} />
        </div>
      ) : (
        <div className={styles["b-header__base"]}>
          <div className={styles["b-header__logo"]}>
            {logoIcon(48)}
            <p className="headline-h4 sf-text-regular">
              Государственная информационная <br />
              система <span className="sf-text-bold">«ЛОКОЛО»</span>
            </p>
          </div>

          <div className={styles["b-header__actions"]}>
            {profile?.role === Profile.UserRole.Company && profile?.company_type === Profile.CompanyType.SNT && (
              <div className={styles["b-header__snt"]}>
                <div className="flex middle">
                  <span className="sf-text-medium">{company?.Name}</span>
                  {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.5 15H1.5C0.672 15 0 14.328 0 13.5C0 13.214 0.08 12.947 0.219 12.72L6.902 1.644C7.118 1.26 7.529 1 8 1C8.52 1 8.966 1.316 9.158 1.766L15.776 12.711C15.918 12.94 16 13.211 16 13.5C16 14.328 15.328 15 14.5 15ZM9 12C9 11.448 8.552 11 8 11C7.448 11 7 11.448 7 12C7 12.552 7.448 13 8 13C8.552 13 9 12.552 9 12ZM9 6C9 5.448 8.552 5 8 5C7.448 5 7 5.448 7 6V9C7 9.552 7.448 10 8 10C8.552 10 9 9.552 9 9V6Z"
                        fill="#DD2B0E"
                      />
                    </svg> */}
                </div>
                <NavLink to={paths.snt().fullPath().passport.root} className="flex middle color--text-primary">
                  <span className="sf-text-regular">Паспорт садоводства</span>
                  {chevronRight16Icon("#226DFF")}
                </NavLink>
              </div>
            )}

            {profile?.role === Profile.UserRole.Company && profile?.company_type === Profile.CompanyType.UK && (
              <SelectAddressDropdown />
            )}

            <div className={styles["b-header__user"]}>
              {profile?.role === Profile.UserRole.Institution ? (
                <p className="text--primary sf-text-semibold color--text-primary">
                  Министерство сельского хозяйства и продовольствия РТ
                </p>
              ) : profile?.company_type === Profile.CompanyType.UK ? (
                <>
                  <div className={styles["b-header__user_uk-avatar"]}>{ukIcon}</div>
                  <p className="text--primary sf-text-semibold color--text-primary">{company?.Name}</p>
                </>
              ) : profile?.company_type === Profile.CompanyType.SNT ? (
                <BaseUserAvatar>
                  <p className="sf-text-semibold color--text-primary text--primary">{userInitials}</p>
                </BaseUserAvatar>
              ) : null}
            </div>

            <BaseButton variant="icon-basic">
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" onClick={handleLogout}>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6 3C4.34315 3 3 4.34315 3 6V22C3 23.6569 4.34315 25 6 25H12C13.6569 25 15 23.6569 15 22V20C15 19.4477 14.5523 19 14 19C13.4477 19 13 19.4477 13 20V22C13 22.5523 12.5523 23 12 23H6C5.44772 23 5 22.5523 5 22V6C5 5.44772 5.44772 5 6 5H12C12.5523 5 13 5.44772 13 6V8C13 8.55228 13.4477 9 14 9C14.5523 9 15 8.55228 15 8V6C15 4.34315 13.6569 3 12 3H6ZM25.7071 14.7071C26.0976 14.3166 26.0976 13.6834 25.7071 13.2929L19.7071 7.29289C19.3166 6.90237 18.6834 6.90237 18.2929 7.29289C17.9024 7.68342 17.9024 8.31658 18.2929 8.70711L22.5858 13L11 13C10.4477 13 10 13.4477 10 14C10 14.5523 10.4477 15 11 15L22.5858 15L18.2929 19.2929C17.9024 19.6834 17.9024 20.3166 18.2929 20.7071C18.6834 21.0976 19.3166 21.0976 19.7071 20.7071L25.7071 14.7071Z"
                  fill="#3A3B3D"
                />
              </svg>
            </BaseButton>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
