import { useEffect, useState } from "react";
import { api } from "../../../services/api";

type QuestionsType = {
  Files: any;
  Id: number;
  Order: number;
  QuorumId: number;
  Title: string;
  Value: string;
};

/***
 * @hook useQuestionsLocalStorage
 * @returns {[Array<QuestionsType>, function, function, boolean]} - This hook returns an array with three values:
 * questionsLocalStorage - an array of QuestionsType objects retrieved from local storage
 * setQuestionsLocalStorage - a function that sets the state of questionsLocalStorage
 * setValueStorage - function that writes to storage
 * isEmptyQuestionsStorage - boolean
 ***/
export const useQuestionsLocalStorage = () => {
  const recalculateOrder = (questions: any) =>
    questions.map((question: any, index: number) => ({
      ...question,
      Order: index + 1,
    }));

  const valueLocalStorage: string | null =
    localStorage.getItem("step4") ?? null;

  const parsedValue: any =
    valueLocalStorage !== null ? JSON.parse(valueLocalStorage) : [];

  const initState = () => {
    if (parsedValue?.Questions) {
      return parsedValue.Questions;
    }

    return [];
  };

  const [questionsLocalStorage, setQuestionsLocalStorage] =
    useState<any>(initState);

  const isEmptyQuestionsStorage = !parsedValue?.Questions?.length;

  const setValueStorage = (
    questions: Array<QuestionsType>,
    deleteIds: undefined | number,
    editData: any
  ) => {
    // Если вопрос новый и не имеет id, то не записываем его id в storage
    const DeleteQuestionsForStorage = [
      // @ts-ignore
      ...new Set(
        [
          ...(parsedValue.DeleteQuestions || []),
          questions.find((question) => question.Id === deleteIds)
            ? deleteIds
            : undefined,
        ].filter((id) => id)
      ),
    ];

    const DeleteQuestions = [
      // @ts-ignore
      ...new Set(
        [...(parsedValue.DeleteQuestions || []), deleteIds].filter((id) => id)
      ),
    ];

    let newQuestion: any = [];
    let newQeustionState: any = [];

    // Add
    if (editData && !editData.Id && !editData.Order) {
      const { Files, fileId, ...rest } = editData;
      let fileIds = fileId ? [...fileId] : [];

      newQuestion = [...newQuestion, { FileIds: fileIds, ...rest }];
      newQeustionState = [{ Files: Files ?? [], ...rest }];
    }

    const mappedQuestionsForStorage = questions
      .map((question: QuestionsType) => {
        const { Files, ...rest } = question;
        const ids = Files.map((file: any) => file.DocumentId);

        // Delete
        if (DeleteQuestions.includes(question.Id)) {
          return;
        }

        // Delete new questions, when don't have id
        if (DeleteQuestions.includes(question.Order)) {
          return;
        }

        // Edit
        if (
          (editData &&
            question.Id === editData.Id &&
            editData.Id !== undefined) ||
          (editData && editData.Order === question.Order)
        ) {
          const { Files, fileId, ...rest } = editData;

          if (fileId === undefined) {
            return {
              FileIds: [...Files?.map((file: any) => file.DocumentId)].filter(
                (file) => file
              ),
              ...rest,
            };
          }

          return {
            FileIds: [
              ...Files?.map((file: any) => file.DocumentId),
              ...fileId,
            ].filter((file) => file),
            ...rest,
          };
        }

        return { FileIds: ids, ...rest };
      })
      .filter((question) => question);

    const mappedNewQuestions = questions
      .map((question: QuestionsType) => {
        // Delete
        if (DeleteQuestions.includes(question.Id)) {
          return;
        }

        // Delete new questions, when don't have id
        if (DeleteQuestions.includes(question.Order)) {
          return;
        }

        return question;
      })
      .filter((question) => question);

    // Записываем данные в хранилище
    localStorage.setItem(
      "step4",
      JSON.stringify({
        Questions: newQuestion.length
          ? recalculateOrder([...mappedQuestionsForStorage, ...newQuestion])
          : recalculateOrder(mappedQuestionsForStorage),
        DeleteQuestions: [...DeleteQuestionsForStorage],
      })
    );

    // Если есть вопросы новые
    if (!!newQuestion.length) {
      setQuestionsLocalStorage([...mappedNewQuestions, ...newQeustionState]);
    }

    if (!newQuestion.length) {
      setQuestionsLocalStorage(mappedNewQuestions);
    }
  };

  // Запросы с документами
  const fetchFile = async (id: number) => {
    try {
      const response = await api.document.getDocument(id);
      return response.data.Data;
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAllFiles = async (fileIds: Array<number>) => {
    const files = [];

    for (const fileId of fileIds) {
      try {
        const response = await fetchFile(fileId);
        files.push(response);
      } catch (err) {
        console.log(err);
      }
    }

    return files;
  };

  const synchronizationData = async () => {
    const parsedQuestions = await Promise.all(
      (parsedValue?.Questions ?? []).map(async (question: any) => {
        const { FileIds, ...rest } = question;
        let files = [];

        if (!!question.FileIds.length) {
          files = await fetchAllFiles(question.FileIds);
        }

        if (files[0] === undefined) {
          files = [];
        }

        return { Files: files, ...rest };
      })
    );

    setQuestionsLocalStorage(parsedQuestions);
  };

  // @ts-ignore
  useEffect(() => {
    synchronizationData();
  }, [valueLocalStorage]);

  return [
    questionsLocalStorage,
    setQuestionsLocalStorage,
    setValueStorage,
    isEmptyQuestionsStorage,
  ];
};
