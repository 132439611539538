import { AnyAction, configureStore, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import app from "./slices/app";
import occ from "./slices/occ";
import house from "./slices/house";
import createOccOld from "./slices/createOccOld";
import createOcc from "./slices/createOcc";
import modal from "./slices/modal";
import contacts from "./slices/contacts";
import registry from "./slices/registry";
import snackbar from "./slices/snackbar";
import user from "./slices/user";
import sntRegistry from "./slices/snt/registry";
import sntMyOcc from "./slices/snt/myOcc";
import sntPassport from "./slices/snt/passport";
import sntsMap from "./slices/snt/sntsMap";
import dictionary from "./slices/dictionary";
import templateQuestions from "./slices/template-questions";
import templateGroups from "./slices/template-groups";
import templates from "./slices/templates";
import documents from "./slices/documents";
import access from "./slices/access";
import houseUsers from "./slices/houseUsers";

import ukRegistry from "./slices/uk/registry";
import ukRosreestr from "./slices/uk/rosreestr";

import { addressMiddleware } from "./middleware/address";
import { authorizationMiddleware } from "./middleware/authorization";
import { occModeMiddleware } from "./middleware/occ";

const store = configureStore({
  reducer: {
    app,
    occ,
    house,
    createOccOld,
    createOcc,
    registry,
    modal,
    contacts,
    snackbar,
    user,
    sntRegistry,
    sntMyOcc,
    sntPassport,
    sntsMap,
    dictionary,
    templateQuestions,
    templateGroups,
    templates,
    documents,
    access,
    houseUsers,
    ukRegistry,
    ukRosreestr,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(addressMiddleware, authorizationMiddleware, occModeMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

export const useAppDispatch = () => useDispatch<AppThunkDispatch>();

export default store;
