import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { statuses } from "../../../consts/statuses";
import { api } from "../../../services/api";
import { OccType, OwnerRegistryType, QuorumType } from "../../../types/occ";

type AddressDataType = {
  Id: number;
  FiasId: string;
  Address: string;
  GeoPoint: { [key: string]: string };
};

type AddressType = {
  data: AddressDataType[];
  loading: boolean;
  error: string;
};

type PaginationType = {
  currentPage: number;
  pages: number;
};

type ResultType = {
  TotalVotes: number;
  VotersNumber: number;
  VotersPercentage: number;
  QuorumCompleted: boolean;
};

type QuestionType = {
  QuestionId: string;
  VotersPercentage: number;
  NonVotersPercentage: number;
  Quorum: string;
  QuorumPresentValue: number;
  QuorumCompleted: boolean;
};

interface AppState {
  managmentCompany: string;
  addresses: AddressType;
  myOcc: {
    data: OccType[] | [];
    dataFiltered: OccType[] | [];
    pagination: {
      limit: number;
      pages: number;
    };
    count: number;
    loading: boolean;
    fetchStatus: typeof statuses[keyof typeof statuses] | "";
    error: string;
  };
  occById: {
    data: any;
    loading: boolean;
    fetchStatus: typeof statuses[keyof typeof statuses] | "";
    error: any;
    documents: {
      data: any;
      loading: boolean;
      fetchStatus: typeof statuses[keyof typeof statuses] | "";
      error: string;
    };
    result: {
      data: ResultType | {};
      loading: boolean;
      fetchStatus: typeof statuses[keyof typeof statuses] | "";
      error: string;
    };
    questions: {
      data: QuestionType[];
      dataFiltered: QuestionType[];
      loading: boolean;
      fetchStatus: typeof statuses[keyof typeof statuses] | "";
      error: string;
    };
    ownerQuestions: {
      data: any;
      dataFiltered: any;
      sendData: any;
      loading: boolean;
      fetchStatus: typeof statuses[keyof typeof statuses] | "";
      error: string;
      errorSendData: string;
    };
    ownerRegistry: {
      data: OwnerRegistryType[];
      dataFiltered: OwnerRegistryType[];
      pagination: PaginationType;
      count: number;
      error: string;
      loading: boolean;
      fetchStatus: typeof statuses[keyof typeof statuses] | "";
    };
    questionsAll: {
      data: any;
      loading: boolean;
      fetchStatus: typeof statuses[keyof typeof statuses] | "";
      error: string;
    };
    quorums: {
      data: Array<QuorumType>;
      loading: boolean;
      fetchStatus: typeof statuses[keyof typeof statuses] | "";
      error: string;
    };
    occDelete: {
      data: boolean;
      loading: boolean;
      fetchStatus: typeof statuses[keyof typeof statuses] | "";
      error: string;
    };
  };
}

const initialState: AppState = {
  managmentCompany: "",
  addresses: {
    data: [],
    loading: false,
    error: "",
  },
  myOcc: {
    data: [],
    dataFiltered: [],
    pagination: {
      limit: 50,
      pages: 0,
    },
    count: 0,
    loading: false,
    fetchStatus: "",
    error: "",
  },
  occById: {
    data: {},
    loading: false,
    fetchStatus: "",
    error: "",
    documents: {
      data: [],
      loading: false,
      fetchStatus: "",
      error: "",
    },
    result: { data: {}, loading: false, fetchStatus: "", error: "" },
    questions: {
      data: [],
      dataFiltered: [],
      loading: false,
      fetchStatus: "",
      error: "",
    },
    ownerQuestions: {
      data: [],
      dataFiltered: [],
      sendData: { Results: [] },
      loading: false,
      fetchStatus: "",
      error: "",
      errorSendData: "",
    },
    ownerRegistry: {
      data: [],
      dataFiltered: [],
      pagination: {
        currentPage: 1,
        pages: 1,
      },
      count: 0,
      loading: false,
      fetchStatus: "",
      error: "",
    },
    questionsAll: {
      data: [],
      error: "",
      loading: false,
      fetchStatus: "",
    },
    quorums: {
      data: [],
      loading: false,
      fetchStatus: "",
      error: "",
    },
    occDelete: {
      data: false,
      loading: false,
      fetchStatus: "",
      error: "",
    },
  },
};

const fetchAllQuestions = createAsyncThunk(
  "occ/get-questions-result",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setResultQuestionsError(""));
      const response = await api.occ.getAllQuestions(id);

      return response.data.Data.Questions;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchDocuments = createAsyncThunk(
  "occ/get-documents",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.occ.getDocuments(id);

      return response.data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchOccById = createAsyncThunk(
  "occ/get",
  async (OccId: number, { rejectWithValue }) => {
    try {
      const response = await api.occ.getOccById(OccId);

      return response.data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchQuorums = createAsyncThunk(
  "data/occ/get-quorum",
  async (data: undefined, { rejectWithValue }) => {
    try {
      const response = await api.occ.getQuorum();

      return response.data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchMyOcc = createAsyncThunk(
  "occ/get-all",
  async ({ limit }: { limit: number }, { rejectWithValue }) => {
    try {
      const response = await api.occ.getOccAll({
        StatusId: null,
        FiasId: null,
        limit,
      });

      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchProgress = createAsyncThunk(
  "occ/get-progress",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setResultError(""));
      const response = await api.occ.getProgress(id);

      return response.data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchOwnerQuestions = createAsyncThunk(
  "occ/get-owners-questions-results",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setOwnerQuestionsError(""));
      const response = await api.occ.getOwnerQuestions(id);

      return response.data.Data.OccOwnersQuestionsResults;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchQuestions = createAsyncThunk(
  "occ/get-questions",
  async (OccId: number, { rejectWithValue }) => {
    try {
      const response = await api.occ.getQuestions({ OccId });

      return response.data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchOwnerRegistry = createAsyncThunk(
  "occ/get-owner-reestr",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await api.occ.getOwnerRegistry(id);

      return response.data.Data.Owners;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchDeleteOcc = createAsyncThunk(
  "/occ/delete",
  async (
    { OccId, callback }: { OccId: number; callback: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.occ.deleteOcc({ OccId });
      callback();

      return response.data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const occSlice = createSlice({
  name: "occ",
  initialState,
  reducers: {
    setDataManagmentCompany: (state, action) => {
      state.managmentCompany = action.payload.Name;
      state.addresses.data = action.payload.Addresses;
      state.addresses.loading = false;
    },
    setDataManagmentCompanyLoading: (state) => {
      state.addresses.loading = true;
    },
    setMyOcc: (state, action) => {
      state.myOcc.data = action.payload;
      state.myOcc.count = action.payload.length;
    },
    setMyOccFiltered: (state, action) => {
      state.myOcc.dataFiltered = action.payload;
    },
    resetMyOcc: (state) => {
      state.myOcc.data = [];
      state.myOcc.count = 0;
      state.myOcc.dataFiltered = [];
    },
    // Заменить в других местах на thunk
    setOccById: (state, action) => {
      state.occById.data = action.payload;
    },
    resetOccById: (state) => {
      state.occById.data = {};
    },
    setDocuments: (state, action) => {
      state.occById.documents = action.payload;
    },
    setResult: (state, action) => {
      state.occById.result.data = action.payload;
    },
    setResultError: (state, action) => {
      state.occById.result.error = action.payload;
    },
    setResultQuestions: (state, action) => {
      state.occById.questions.data = action.payload;
    },
    setResultQuestionsFiltered: (state, action) => {
      state.occById.questions.dataFiltered = action.payload;
    },
    setResultQuestionsError: (state, action) => {
      state.occById.questions.error = action.payload;
    },
    setOwnerQuestions: (state, action) => {
      state.occById.ownerQuestions.data = action.payload;
    },
    setOwnerQuestionFiltered: (state, action) => {
      state.occById.ownerQuestions.dataFiltered = action.payload;
    },
    setOwnerQuestionsError: (state, action) => {
      state.occById.ownerQuestions.error = action.payload;
    },
    setOwnerRegistry: (state, action) => {
      state.occById.ownerRegistry.data = action.payload.Owners;
      state.occById.ownerRegistry.count = action.payload.length;
    },
    setOwnerRegistryFiltered: (state, action) => {
      state.occById.ownerRegistry.dataFiltered = action.payload;
    },
    setPaginationOwnerRegistry: (state, action) => {
      state.occById.ownerRegistry.pagination = action.payload;
    },
    setOwnerRegistryError: (state, action) => {
      state.occById.ownerRegistry.error = action.payload;
    },
    setQuestionsAll: (state, action) => {
      state.occById.questionsAll.data = action.payload;
    },
    setQuestionsOwnerSendData: (state, action) => {
      state.occById.ownerQuestions.sendData = {
        ...state.occById.ownerQuestions.sendData,
        ...action.payload,
      };
    },
    resetQuestionsSendData: (state) => {
      state.occById.ownerQuestions.sendData.Results = [];
    },
    resetOwnerQuestionsFile: (state) => {
      state.occById.ownerQuestions.sendData.Files = [];
    },
    resetOccDeletedData: (state) => {
      state.occById.occDelete = {
        data: false,
        loading: false,
        fetchStatus: "",
        error: "",
      };
    },
    setQuestionsOwnerSendDataError: (state, action) => {
      state.occById.ownerQuestions.errorSendData = action.payload;
    },
    resetOcc: () => initialState,
  },
  extraReducers: (builder) => {
    // @fetchOccById - request
    builder.addCase(fetchOccById.pending, (state: any) => {
      state.occById.loading = true;
      state.occById.fetchStatus = statuses.pending;
      state.occById.error = "";
    });
    builder.addCase(fetchOccById.fulfilled, (state: any, action) => {
      state.occById.loading = false;
      state.occById.fetchStatus = statuses.success;
      state.occById.data = action.payload;
    });
    builder.addCase(fetchOccById.rejected, (state: any, action) => {
      state.occById.loading = false;
      state.occById.fetchStatus = statuses.reject;

      if (action.payload) {
        state.occById.error = action.payload;
      }
    });

    // fetchQuorums - request
    builder.addCase(fetchQuorums.pending, (state: any) => {
      state.occById.quorums.loading = true;
      state.occById.quorums.fetchStatus = statuses.pending;
      state.occById.quorums.error = "";
    });
    builder.addCase(fetchQuorums.fulfilled, (state: any, action) => {
      state.occById.quorums.loading = false;
      state.occById.quorums.fetchStatus = statuses.success;
      state.occById.quorums.data = action.payload;
    });
    builder.addCase(fetchQuorums.rejected, (state: any, action) => {
      state.occById.quorums.loading = false;
      state.occById.quorums.fetchStatus = statuses.reject;

      if (action.payload) {
        state.occById.quorums.error = action.payload;
      }
    });

    // fetchMyOcc - request
    builder.addCase(fetchMyOcc.pending, (state: any) => {
      state.myOcc.loading = true;
      state.myOcc.fetchStatus = statuses.pending;
      state.myOcc.error = "";
    });
    builder.addCase(fetchMyOcc.fulfilled, (state: any, action) => {
      state.myOcc.loading = false;
      state.myOcc.fetchStatus = statuses.success;
      state.myOcc.data = action.payload.Data;
      state.myOcc.dataFiltered = action.payload.Data;
      state.myOcc.pagination.pages = action.payload.Page.Count;
      state.myOcc.pagination.limit = Number(action.payload.Page.Limit);
    });
    builder.addCase(fetchMyOcc.rejected, (state: any, action) => {
      state.myOcc.loading = false;
      state.myOcc.fetchStatus = statuses.reject;

      if (action.payload) {
        state.myOcc.error = action.payload;
      }
    });

    // @fetchDocuments - request
    builder.addCase(fetchDocuments.pending, (state: any) => {
      state.occById.documents.loading = true;
      state.occById.documents.fetchStatus = statuses.pending;
      state.occById.documents.error = "";
    });
    builder.addCase(fetchDocuments.fulfilled, (state: any, action) => {
      state.occById.documents.loading = false;
      state.occById.documents.fetchStatus = statuses.success;
      state.occById.documents.data = action.payload;
    });
    builder.addCase(fetchDocuments.rejected, (state: any, action) => {
      state.occById.documents.loading = false;
      state.occById.documents.fetchStatus = statuses.reject;

      if (action.payload) {
        state.occById.documents.error = action.payload;
      }
    });

    // fetchProgress - request
    builder.addCase(fetchProgress.pending, (state: any) => {
      state.occById.result.loading = true;
      state.occById.result.fetchStatus = statuses.pending;
      state.occById.result.error = "";
    });
    builder.addCase(fetchProgress.fulfilled, (state: any, action) => {
      state.occById.result.loading = false;
      state.occById.result.fetchStatus = statuses.success;
      state.occById.result.data = action.payload;
    });
    builder.addCase(fetchProgress.rejected, (state: any, action) => {
      state.occById.result.loading = false;
      state.occById.result.fetchStatus = statuses.reject;

      if (action.payload) {
        state.occById.result.error = action.payload;
      }
    });

    // fetchAllQuestions - request
    builder.addCase(fetchAllQuestions.pending, (state: any) => {
      state.occById.questions.loading = true;
      state.occById.questions.fetchStatus = statuses.pending;
      state.occById.questions.error = "";
    });
    builder.addCase(fetchAllQuestions.fulfilled, (state: any, action) => {
      state.occById.questions.loading = false;
      state.occById.questions.fetchStatus = statuses.success;
      state.occById.questions.data = action.payload;
    });
    builder.addCase(fetchAllQuestions.rejected, (state: any, action) => {
      state.occById.questions.loading = false;
      state.occById.questions.fetchStatus = statuses.reject;

      if (action.payload) {
        state.occById.questions.error = action.payload;
      }
    });

    // fetchOwnerQuestions - request
    builder.addCase(fetchOwnerQuestions.pending, (state: any) => {
      state.occById.ownerQuestions.loading = true;
      state.occById.ownerQuestions.fetchStatus = statuses.pending;
      state.occById.ownerQuestions.error = "";
    });
    builder.addCase(fetchOwnerQuestions.fulfilled, (state: any, action) => {
      state.occById.ownerQuestions.loading = false;
      state.occById.ownerQuestions.fetchStatus = statuses.success;
      state.occById.ownerQuestions.data = action.payload;
      state.occById.ownerQuestions.dataFiltered = action.payload;
    });
    builder.addCase(fetchOwnerQuestions.rejected, (state: any, action) => {
      state.occById.ownerQuestions.loading = false;
      state.occById.ownerQuestions.fetchStatus = statuses.reject;

      if (action.payload) {
        state.occById.ownerQuestions.error = action.payload;
      }
    });

    // fetchQuestions - request
    builder.addCase(fetchQuestions.pending, (state: any) => {
      state.occById.questionsAll.loading = true;
      state.occById.questionsAll.fetchStatus = statuses.pending;
      state.occById.questionsAll.error = "";
    });
    builder.addCase(fetchQuestions.fulfilled, (state: any, action) => {
      state.occById.questionsAll.loading = false;
      state.occById.questionsAll.fetchStatus = statuses.success;
      state.occById.questionsAll.data = action.payload;
    });
    builder.addCase(fetchQuestions.rejected, (state: any, action) => {
      state.occById.questionsAll.loading = false;
      state.occById.questionsAll.fetchStatus = statuses.reject;

      if (action.payload) {
        state.occById.questionsAll.error = action.payload;
      }
    });

    // fetchOwnerRegistry - request
    builder.addCase(fetchOwnerRegistry.pending, (state: any) => {
      state.occById.ownerRegistry.loading = true;
      state.occById.ownerRegistry.fetchStatus = statuses.pending;
      state.occById.ownerRegistry.error = "";
    });
    builder.addCase(fetchOwnerRegistry.fulfilled, (state: any, action) => {
      state.occById.ownerRegistry.loading = false;
      state.occById.ownerRegistry.fetchStatus = statuses.success;
      state.occById.ownerRegistry.data = action.payload;
      state.occById.ownerRegistry.dataFiltered = action.payload;
    });
    builder.addCase(fetchOwnerRegistry.rejected, (state: any, action) => {
      state.occById.ownerRegistry.loading = false;
      state.occById.ownerRegistry.fetchStatus = statuses.reject;

      if (action.payload) {
        state.occById.ownerRegistry.error = action.payload;
      }
    });

    // @fetchDeleteOcc - request
    builder.addCase(fetchDeleteOcc.pending, (state: AppState) => {
      state.occById.occDelete.loading = true;
      state.occById.occDelete.error = "";
    });
    builder.addCase(fetchDeleteOcc.fulfilled, (state: AppState, action) => {
      state.occById.occDelete.loading = false;
      state.occById.occDelete.data = action.payload;
    });
    builder.addCase(fetchDeleteOcc.rejected, (state: AppState, action: any) => {
      state.occById.occDelete.loading = false;

      if (action.payload) {
        state.occById.occDelete.error = action.payload;
      }
    });
  },
});

export {
  fetchOccById,
  fetchQuorums,
  fetchMyOcc,
  fetchDocuments,
  fetchProgress,
  fetchAllQuestions,
  fetchOwnerQuestions,
  fetchQuestions,
  fetchOwnerRegistry,
  fetchDeleteOcc,
};
export const {
  setDataManagmentCompany,
  setDataManagmentCompanyLoading,
  setMyOcc,
  setMyOccFiltered,
  resetMyOcc,
  setOccById,
  setDocuments,
  setResult,
  setResultError,
  setResultQuestions,
  setResultQuestionsFiltered,
  setResultQuestionsError,
  setOwnerQuestions,
  setOwnerQuestionFiltered,
  setOwnerQuestionsError,
  setOwnerRegistry,
  setOwnerRegistryError,
  setOwnerRegistryFiltered,
  setPaginationOwnerRegistry,
  setQuestionsAll,
  setQuestionsOwnerSendData,
  setQuestionsOwnerSendDataError,
  resetQuestionsSendData,
  resetOwnerQuestionsFile,
  resetOccById,
  resetOccDeletedData,
  resetOcc,
} = occSlice.actions;
export default occSlice.reducer;
