import Qs from "qs";

interface IGetApartments {
  page?: number;
  limit?: number;
  HouseFiasId: string;
  OwnerSearch?: string;
  Sort?: any;
}

export default {
  getHouseData: ({ cadastralNumber, address, FiasId, OnlyActual }: any) => ({
    method: "POST",
    url: "api/reestr/mkd/get",
    data: {
      cadastralNumber,
      address,
      FiasId,
      OnlyActual,
    },
  }),
  sendHouseData: ({ File, Address }: any) => ({
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
    },
    url: "api/reestr/mkd/send",
    data: {
      File,
      Address,
    },
  }),
  getHousesData: ({
    FiasId,
    limit,
    page,
  }: {
    FiasId: number | null;
    limit: number;
    page: number;
  }) => ({
    method: "GET",
    url: `api/occ/get-for-uk-houses?${Qs.stringify({
      FiasId,
      limit,
      page,
    })}`,
  }),
  saveHouseArea: ({ FiasId, Area }: { FiasId: string; Area: number }) => ({
    method: "POST",
    url: "api/reestr/mkd/area/send",
    data: {
      FiasId,
      Area,
    },
  }),
  getHouseApartments: (data: IGetApartments) => ({
    method: "POST",
    url: `api/reestr/mkd/apartment/get?${Qs.stringify({
      page: data?.page,
      limit: data?.limit,
    })}`,
    data: {
      HouseFiasId: data.HouseFiasId,
      OwnerSearch: data?.OwnerSearch,
      Sort: data?.Sort,
    },
  }),
  getHouseOwners: (data: IGetApartments) => ({
    method: "POST",
    url: `api/reestr/mkd/owner/get?${Qs.stringify({
      page: data?.page,
      limit: data?.limit,
    })}`,
    data: {
      HouseFiasId: data.HouseFiasId,
      OwnerSearch: data?.OwnerSearch,
      Sort: data?.Sort,
    },
  }),
  getApartmentById: ({
    Id,
    HouseFiasId,
  }: {
    Id: number;
    HouseFiasId: string;
  }) => ({
    method: "POST",
    url: `api/reestr/mkd/apartment/get-by-id?${Qs.stringify({
      Include: ["OwnerChange"],
    })}`,
    data: {
      Id,
      HouseFiasId,
    },
  }),
  checkDeleteApartment: ({
    HouseFiasId,
    ApartmentNumber,
  }: {
    HouseFiasId: string;
    ApartmentNumber: string;
  }) => ({
    method: "POST",
    url: "api/reestr/mkd/apartment/delete-is-possible",
    data: {
      HouseFiasId,
      ApartmentNumber,
    },
  }),
  deleteHouseApartment: ({
    HouseFiasId,
    HouseId,
    Id,
    Number,
  }: {
    HouseFiasId: string;
    HouseId: number;
    Id: number;
    Number: number;
  }) => ({
    method: "DELETE",
    url: "api/reestr/mkd/apartment",
    data: {
      HouseFiasId,
      HouseId,
      Id,
      Number,
    },
  }),
  updateHouseApartment: ({
    HouseFiasId,
    HouseId,
    Id,
    Number,
    Area,
    Type,
    CadastralNumber,
    CreatedOwners,
    UpdatedOwners,
    DeletedOwners,
  }: any) => ({
    method: "PATCH",
    url: "api/reestr/mkd/apartment",
    data: {
      HouseFiasId,
      HouseId,
      Id,
      Number,
      Area,
      Type,
      CadastralNumber,
      CreatedOwners,
      UpdatedOwners,
      DeletedOwners,
    },
  }),
  addHouseApartment: ({
    HouseFiasId,
    HouseId,
    Number,
    Area,
    Type,
    CadastralNumber,
    CreatedOwners,
  }: any) => ({
    method: "POST",
    url: "api/reestr/mkd/apartment",
    data: {
      HouseFiasId,
      HouseId,
      Number,
      Area,
      Type,
      CadastralNumber,
      CreatedOwners,
    },
  }),

  searchAddress: (Query: string) => ({
    method: "GET",
    url: "api/address/search/",
    params: {
      Query,
      Count: 5
    }
  }),
} as any;
