import { Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../app/store";
import { isObject } from "../../helpers/validation";

type EventData = { date: string; text: string };

interface IEvents {
  classes: {
    root: string;
    row?: string;
    label?: string;
    value?: string;
  };
  variant?: "1" | "2";
  withoutTime?: boolean;
  capitalized?: boolean;
}

const Events = ({
  classes,
  variant = "1",
  withoutTime = false,
  capitalized = false,
}: IEvents) => {
  const { PlannedEvents } = useSelector(
    (state: RootState) => state.occ.occById.data
  );
  const [events, setEvents] = useState<EventData[]>([]);

  const mappedEventsData = (events: any) =>
    Object.keys(events).map((event: string) => ({
      date: events[event].Date,
      text: events[event].Text,
    }));

  useEffect(() => {
    isObject(PlannedEvents) && setEvents(mappedEventsData(PlannedEvents));
  }, [PlannedEvents]);

  return (
    <Box className={classes.root}>
      {events?.map((event: EventData) => (
        <Box className={classes.row} key={event.date}>
          {variant === "1" ? (
            <>
              <Typography variant="subtitle2" className={classes.label}>
                {withoutTime
                  ? event.date.split(" ").slice(0, -1).join(" ")
                  : event.date}
              </Typography>
              <Typography variant="subtitle2" className={classes.value}>
                {capitalized
                  ? event.text.charAt(0).toUpperCase() + event.text.slice(1)
                  : event.text}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="subtitle2" className={classes.label}>
                {event.date} - {event.text}
              </Typography>
            </>
          )}
        </Box>
      ))}
    </Box>
  );
};

export { Events };
