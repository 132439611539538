import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/app/store";
import { clearQuestions, resetCheckQuestion, setModeOcc } from "@/app/store/slices/createOccOld";
import { resetFilters } from "@/app/store/slices/registry";
import { sidebar, NavLinkType } from "@/consts/sidebar";
import styles from "./routesManager.module.scss";
import { Profile } from "@/types";

interface IRoutesManagerProps {
  disabled: boolean;
}

const RoutesManager = ({ disabled }: IRoutesManagerProps) => {
  const profile = useSelector((state: RootState) => state.user.profile);
  const dispatch = useAppDispatch();

  const linksFiltered: NavLinkType[] = useMemo(
    () =>
      sidebar.filter(
        (it) =>
          it.role === profile?.role &&
          ((profile.role === Profile.UserRole.Company && (it.type === profile.company_type || it.type === null)) ||
            profile.role === Profile.UserRole.Institution)
      ),
    [profile]
  );

  const handleRoute = (event: any): void => {
    if (disabled) event.preventDefault();

    localStorage.removeItem("modeOcc");
    localStorage.removeItem("step1");
    localStorage.removeItem("step2");
    localStorage.removeItem("step3");
    localStorage.removeItem("step4");

    dispatch(setModeOcc("create"));
    dispatch(clearQuestions());
    dispatch(resetFilters());
    dispatch(resetCheckQuestion());
  };

  return (
    <ul className={styles.navigation}>
      {linksFiltered.map((link, index) => (
        <li key={index} className={disabled ? styles.disabled : undefined}>
          <NavLink
            aria-disabled
            to={link.path}
            onClick={handleRoute}
            className={({ isActive }) => (isActive ? styles.active : undefined)}
          >
            <>
              {link.icon}
              <span className={styles.link}>{link.text}</span>
            </>
          </NavLink>
        </li>
      ))}
      {disabled && (
        <p className={styles.disabled_message}>
          Меню недоступно. <br />
          Выберите адрес
        </p>
      )}
    </ul>
  );
};

export { RoutesManager };
