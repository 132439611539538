import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  open: boolean;
  onDelete: () => void;
  onClose: () => void;
}

const DeleteModal = ({ onDelete, open, onClose }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Удалить собрание
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        Вы уверены что хотите удалить и прервать процес создания собрания?
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Отмена
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onDelete}
          autoFocus
        >
          Удалить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { DeleteModal };
