import React, { useState } from "react";
import styles from "./draftItem.module.scss";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../../components/icon";
import DeleteIcon from "../../../../assets/icons/delete.svg";
import { OccType } from "../../../../types/occ";
import { DeleteModal } from "../deleteModal";

interface Props {
  data: OccType;
  onDelete: (occId: number) => void;
}

export const DraftItem = ({ data, onDelete }: Props) => {
  const navigation = useNavigate();
  const [open, setOpen] = useState(false);

  const onOpen = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setOpen(true);
  };

  const toEdit = (id: number) => {
    navigation("/occ/create/1", { state: id });
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setOpen(false);
    onDelete(data.Id);
  };

  return (
    <>
      <div className={styles.root} onClick={() => toEdit(data.Id)}>
        <div>
          <div className={styles.root__title}>№ {data.Number}</div>
          <div className={styles.root__desc}>
            {data.UpdatedAt} {data.Status}
          </div>
        </div>
        <IconButton>
          <Icon path={DeleteIcon} className={styles.root__delete} onClick={onOpen} />
        </IconButton>
      </div>
      <DeleteModal onClose={onClose} open={open} onDelete={handleDelete} />
    </>
  );
};
