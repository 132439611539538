import { IApiModule } from "../types";

export default {
  getTypeApartment: () => ({
    method: "GET",
    url: "api/reestr/mkd/apartment/get-type",
  }),
  getTypeOwnership: () => ({
    method: "GET",
    url: "api/reestr/mkd/owner/get-ownership-type",
  }),
} as IApiModule;
