import { useForm, useWatch } from "react-hook-form";
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  FormHelperText,
  InputLabel,
  Typography,
  Grid,
  Button,
  Alert,
  IconButton,
  Tooltip,
  Link,
} from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { Quorum } from "../quorum";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { api } from "../../../../services/api";
import ClearIcon from "../../../../assets/icons/clear.svg";
import { Icon } from "../../../../components/icon";
import { LabelTooltip } from "../../../../components/tooltip";
import style from "./questionForm.module.scss";
import { classnames } from "../../../../helpers/classnames";
import { formRules } from "../../../../consts/form";
import { cutFileName } from "../../../../helpers/common";
import { isEmptyObject } from "../../../../helpers/validation";
import { checkQuestion, resetErrorStep, setErrorStep } from "../../../../app/store/slices/createOccOld";
import { useAppDispatch } from "../../../../app/store";
import { isEmpty } from "lodash";

interface Props {
  occId: unknown;
  onReload?: () => void;
  data?: any;
  onActiveEdit: any;
  onActiveAdd: any;
  setValueStorage?: any;
  questionsLocalStorage?: any;
  isEditMode: boolean;
}

const QuestionForm = ({
  occId,
  onReload,
  data,
  onActiveEdit,
  onActiveAdd,
  setValueStorage,
  questionsLocalStorage,
  isEditMode,
}: Props) => {
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState("");
  const [questionsData, setQuestionsData] = useState<any>();
  const updatedQuestion = useRef({});
  const inputRef = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    control,
  } = useForm();

  const titleValueForm = useWatch({
    control,
    name: "Title",
  });

  const textValueForm = useWatch({
    control,
    name: "Value",
  });

  const showFiles = data ? !isEmptyObject(data) && data.Files?.length : false;

  function emitInputFocus(event: React.SyntheticEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.click();
    }
  }

  const handleChangeFile = async (event: React.FormEvent) => {
    const filesList = (event.target as HTMLInputElement).files;

    if (isEditMode && filesList && filesList[0]) {
      try {
        const response = await api.document.saveDocument(filesList[0]);
        const id = response.data.Data.Id;
        let ids: any = [];

        // @ts-ignore
        if (updatedQuestion.current?.fileId?.length) {
          // @ts-ignore
          ids = [...updatedQuestion.current.fileId];
        }

        updatedQuestion.current = { ...data, fileId: [...ids, id] };
      } catch (err) {
        console.log(err);
      }
    }

    if (
      filesList &&
      filesList.length > 0 &&
      !files.find(
        (it) =>
          it.name === filesList[0].name &&
          it.size === filesList[0].size &&
          it.lastModified === filesList[0].lastModified
      )
    ) {
      setFiles([...files, filesList[0]]);
    }
  };

  const createQuestion = async (data: any) => {
    setError("");
    try {
      const _data = new FormData();

      _data.append("OccId", occId as string);
      _data.append("Questions[0][QuorumId]", data.QuorumId);
      _data.append("Questions[0][Title]", data.Title);
      _data.append("Questions[0][Value]", data.Value);

      if (data.Id) {
        _data.append("Questions[0][Id]", data.Id);
      }

      if (files?.length) {
        files.forEach((el, index) => {
          _data.append(`Questions[0][Files][${index}]`, el);
        });
      }

      await api.occ.setQuestions(_data);
      dispatch(
        checkQuestion({
          Title: data.Title,
        })
      );
      onReload && onReload();
    } catch (err: any) {
      let errorText = err.response.data?.Data?.Message;
      const errors = err.response.data?.Data?.Errors;

      if (errors) {
        Object.keys(errors).forEach((e) => {
          errorText = errorText + "<br/>" + errors[e];
        });
      }

      setError(errorText);

      return false;
    }
  };

  const updateQuestions = async (data: any) => {
    if (!isEmpty(updatedQuestion.current)) {
      setValueStorage(questionsLocalStorage, null, {
        ...updatedQuestion.current,
        ...data,
      });
    } else {
      setValueStorage(questionsLocalStorage, null, data);
    }

    updatedQuestion.current = {};

    dispatch(
      checkQuestion({
        Title: data.Title,
      })
    );

    onActiveEdit(null);
    onActiveAdd(false);
  };

  const handleQustionsForm = (data: any) => {
    isEditMode ? updateQuestions(data) : createQuestion(data);
  };

  const onCloseError = () => {
    setError("");
  };

  const deleteFile = (index: number) => {
    files.splice(index, 1);
    setFiles([...files]);
  };

  useEffect(() => {
    if (data) {
      for (let field of Object.keys(data)) {
        setValue(field, data[field]);
      }
      setQuestionsData(data);
    }

    return () => {
      updatedQuestion.current = {};
    };
  }, [data]);

  useEffect(() => {
    !isEmptyObject(errors) || !!error ? dispatch(setErrorStep()) : dispatch(resetErrorStep());
  }, [errors, error]);

  return (
    <Box sx={{ backgroundColor: "white", borderRadius: "16px", padding: "16px" }}>
      <Typography fontSize={"20px"} mb={3}>
        Добавить вопрос в повестку
      </Typography>
      <form onSubmit={handleSubmit(handleQustionsForm)}>
        <Grid container rowSpacing={2} columnSpacing={4}>
          <Grid item xs={12}>
            {error && (
              <Alert severity="error" onClose={onCloseError}>
                <span dangerouslySetInnerHTML={{ __html: error }} />
              </Alert>
            )}
          </Grid>
          <Grid item xs={12}>
            {/* TODO: Избавиться от компонента Quorum - делает лишние запросы, компонент должен принимать только пропсы для отрисовки */}
            <Quorum
              key={questionsData?.QuorumId}
              {...register("QuorumId", {
                required: "Поле обязательно для заполнения",
              })}
              defaultValue={questionsData?.QuorumId}
              error={(errors["QuorumId"]?.type as unknown as string) === "required"}
            />
            {(errors["QuorumId"]?.type as unknown as string) === "required" && (
              <FormHelperText error>{errors["QuorumId"]?.message as unknown as string}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <InputLabel>Заголовок вопроса</InputLabel>
              <Typography variant="subtitle2">
                {titleValueForm?.replace(/\n/g, "****").length || 0}/{formRules.question.title.maxLength}
              </Typography>
            </Box>
            <TextareaAutosize
              placeholder="Введите заголовок вопроса"
              minRows={3}
              {...register("Title", {
                required: "Поле обязательно для заполнения",
                maxLength: formRules.question.title.maxLength,
              })}
              className={classnames(
                style.textarea,
                (errors["Title"]?.type as unknown as string) === "required" ||
                  ((errors["Title"]?.type as unknown as string) === "maxLength" && style.error)
              )}
            />
            {(errors["Title"]?.type as unknown as string) === "required" && (
              <FormHelperText error>{errors["Title"]?.message as unknown as string}</FormHelperText>
            )}
            {(errors["Title"]?.type as unknown as string) === "maxLength" && (
              <FormHelperText error>Максимум можно ввести {formRules.question.title.maxLength} символов</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <InputLabel htmlFor="select" sx={{ display: "flex", gap: "4px" }}>
                Введите текст вопроса (решения)
                <LabelTooltip
                  title="Введите текст вопроса. Опишите предполагаемый вариант решения"
                  placement="right"
                  arrow
                  enterDelay={700}
                />
              </InputLabel>
              <Typography variant="subtitle2">
                {textValueForm?.replace(/\n/g, "****").length || 0}/{formRules.question.value.maxLength}
              </Typography>
            </Box>
            <TextareaAutosize
              placeholder="Ввести..."
              minRows={5}
              {...register("Value", {
                required: "Поле обязательно для заполнения",
                maxLength: formRules.question.value.maxLength,
              })}
              className={classnames(
                style.textarea,
                (errors["Value"]?.type as unknown as string) === "required" ||
                  ((errors["Value"]?.type as unknown as string) === "maxLength" && style.error)
              )}
            />
            {(errors["Value"]?.type as unknown as string) === "required" && (
              <FormHelperText error>{errors["Value"]?.message as unknown as string}</FormHelperText>
            )}
            {(errors["Value"]?.type as unknown as string) === "maxLength" && (
              <FormHelperText error>Максимум можно ввести {formRules.question.value.maxLength} символов</FormHelperText>
            )}
          </Grid>

          {files && (
            <>
              {files.map((file: any, index: number) => (
                <Grid item key={file.name} sx={{ width: "100%" }}>
                  <Box sx={{ maxWidth: "230px" }}>
                    <Box
                      sx={{
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                        borderRadius: "10px",
                        padding: "12px",
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        fontSize: "14px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minWidth: "32px",
                          height: "32px",
                          borderRadius: "90px",
                          backgroundColor: "#EFEDEA",
                        }}
                      >
                        <Typography variant="subtitle2" fontSize="10px" sx={{ textTransform: "uppercase" }}>
                          {file.name?.split(".")[1]}
                        </Typography>
                      </Box>
                      <Box pl={1}>
                        <Box>{cutFileName(file.name, 17, 7)}</Box>
                        <Box color={"rgba(0, 0, 0, 0.54)"}>{(file.size / 1048576).toFixed(2)} MB</Box>
                      </Box>
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "-12px",
                          right: "-12px",
                          zIndex: 1,
                        }}
                      >
                        <Icon path={ClearIcon} onClick={() => deleteFile(index)} />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </>
          )}
          {!!showFiles && (
            <Grid rowSpacing={2} columnSpacing={2} mt={2} pl={4}>
              {data.Files.map((file: any) => (
                <Grid item mt="8px">
                  <Link href={file.URL} target={"_blank"} underline="none" color="inherit">
                    <Box
                      sx={{
                        minWidth: "230px",
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                        borderRadius: "10px",
                        padding: "12px",
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        fontSize: "14px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minWidth: "32px",
                          height: "32px",
                          borderRadius: "90px",
                          backgroundColor: "#EFEDEA",
                        }}
                      >
                        <Typography variant="subtitle2" fontSize="10px" sx={{ textTransform: "uppercase" }}>
                          {file.Extension}
                        </Typography>
                      </Box>
                      <Box width="100%" pl="12px">
                        <Box>{cutFileName(file.Name + "." + file.Extension, 17, 7)}</Box>
                        <Box color={"rgba(0, 0, 0, 0.54)"}>{(file.Size / 1048576).toFixed(2)} MB</Box>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
              ))}
            </Grid>
          )}
          <Tooltip
            title="При необходимости вы можете прикрепить файл к вопросу"
            placement="right"
            arrow
            enterDelay={500}
          >
            <Grid item xs={12}>
              <label onClick={emitInputFocus}>
                <Button
                  component="span"
                  sx={{
                    backgroundColor: "#EFEDEA",
                    width: "100%",
                    color: "#000000",
                  }}
                >
                  <AttachFileIcon />
                  Прикрепить файл
                </Button>
              </label>
              <input
                ref={inputRef}
                style={{ display: "none" }}
                id="upload-file"
                name="upload-file"
                type="file"
                onChange={handleChangeFile}
              />
            </Grid>
          </Tooltip>
          <Grid item xs={12} textAlign={"right"}>
            <Button variant="contained" color="primary" type="submit" sx={{ height: "36px", fontSize: "14px" }}>
              Добавить
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export { QuestionForm };
