import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames/bind";
import { api } from "@/services/api";
import { SearchField } from "@/components/search";
import useDebounce from "@/hooks/useDebounce";
import styles from "./address-search.module.scss";
import { House } from "@/types/house";
import AddressItem from "./addressItem";
import { Spinner } from "@/components/spinner";

const cx = classNames.bind(styles);

interface IAddressesSearchDropdownProps {
  address: string;
  onSelect: (address: House.Address) => void;
  onClear: () => void;
  placeholder?: string;
  errorMessage?: string;
}

const AddressesSearchDropdown = ({
  address,
  onSelect,
  onClear,
  placeholder,
  errorMessage,
}: IAddressesSearchDropdownProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const searchBodyRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(true);
  const [addresses, setAddresses] = useState<House.Address[]>([]);
  const [position, setPostion] = useState<{ left: string; top: string; width: string; height: string }>({
    left: "0",
    top: "0",
    width: "auto",
    height: "212px",
  });
  const [debouncedQuery] = useDebounce(query, 300);

  const openDropdown = () => {
    const rect = ref.current?.getBoundingClientRect();

    const scrolltop = window?.scrollY ?? 0;
    const windowHeight = window?.innerHeight;
    const top = scrolltop + (rect?.top ?? 0);
    const left = rect?.left ?? 0;
    const rectBottom = (rect?.top ?? 0) + 296;
    const height = rectBottom > windowHeight ? windowHeight - (rect?.top ?? 0) - 84 : 212;
    setPostion({
      top: `${top}px`,
      left: `${left}px`,
      width: `${rect?.width}px`,
      height: `${height}px`,
    });
    setOpen(true);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const onQueryChange = async (value: string) => {
    try {
      if (value.length < 3) {
        setAddresses([]);
        return;
      }

      setLoading(true);
      const { data } = await api.house.searchAddress(value);
      setAddresses(data.Data);
    } catch (error) {
      setAddresses([]);
    } finally {
      setLoading(false);
    }
  };

  const clearAddress = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    onClear();
  };

  const onAddressSelectHandle = (address: House.Address) => {
    onSelect(address);
    setQuery(address.Value);

    if (address.House) {
      setOpen(false);
    }
  };

  useEffect(() => {
    onQueryChange(debouncedQuery);
  }, [debouncedQuery]);

  useEffect(() => {
    setQuery(address);
  }, [address]);

  const handleClickOutside = (event: any) => {
    if (
      isOpen &&
      ref.current &&
      !ref.current.contains(event.target) &&
      searchBodyRef.current &&
      !searchBodyRef.current.contains(event.target)
    ) {
      setOpen(false);
    }
  };

  const onResize = () => {
    setOpen(false);
  };

  useEffect(() => {
    searchInputRef.current && searchInputRef.current.focus();

    document.addEventListener("click", handleClickOutside, true);
    window.addEventListener("resize", onResize, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      window.removeEventListener("resize", onResize, true);
    };
  }, [ref, searchBodyRef, isOpen]);

  const onFocus = () => {
    setOpen(true);
  };

  return (
    <div ref={ref} data-error={errorMessage} className={cx({ base: true, has_error: !!errorMessage })}>
      <div className={cx({ display: true, placeholder: !address })} onClick={openDropdown}>
        <div className="flex middle between">
          <span>{address ? address : placeholder}</span>
          {address && (
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" role="button" onClick={clearAddress}>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.8841 7.9995L4.5911 5.7065C4.2001 5.3165 4.2001 4.6825 4.5911 4.2925C4.9811 3.9025 5.6151 3.9025 6.0051 4.2925L8.2981 6.5855L10.5911 4.2925C10.9811 3.9025 11.6151 3.9025 12.0051 4.2925C12.3951 4.6825 12.3951 5.3165 12.0051 5.7065L9.7121 7.9995L12.0051 10.2925C12.3951 10.6825 12.3951 11.3165 12.0051 11.7065C11.6151 12.0965 10.9811 12.0965 10.5911 11.7065L8.2981 9.4135L6.0051 11.7065C5.6151 12.0965 4.9811 12.0965 4.5911 11.7065C4.2001 11.3165 4.2001 10.6825 4.5911 10.2925L6.8841 7.9995Z"
                fill="#818C99"
              />
            </svg>
          )}
        </div>
        <input type="hidden" onFocus={onFocus} />
      </div>

      {isOpen &&
        createPortal(
          <div
            ref={searchBodyRef}
            className={styles.body}
            style={{
              left: position.left,
              top: position.top,
              width: position.width,
            }}
          >
            <div className={styles.search}>
              <SearchField
                inputRef={searchInputRef}
                value={query}
                onChange={handleSearchChange}
                placeholder="Поиск адреса"
              />
            </div>
            <div className={styles.list} style={{ height: position.height }}>
              {isLoading ? (
                <div className={styles.list__loader}>
                  <Spinner alt={"Загрузка..."} />
                </div>
              ) : addresses.length > 0 ? (
                addresses.map((address, index) => (
                  <AddressItem key={index} address={address.Value} onSelect={() => onAddressSelectHandle(address)} />
                ))
              ) : debouncedQuery.length < 3 ? (
                <div className={styles.list__emty}>Начните вводить адрес</div>
              ) : (
                <div className={styles.list__emty}>Адрес не найден</div>
              )}
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default AddressesSearchDropdown;
