import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { statuses } from "../../../consts/statuses";
import { api } from "../../../services/api";
import { MyHouseResponse } from "../../../services/api/types";
import { HouseType } from "../../../types/house";

type PaginationType = {
  limit: number;
  pages: number;
  page: number;
};

type statusesKeys = keyof typeof statuses;
type RequestStatusType = typeof statuses[statusesKeys];

interface AppState {
  hasHouseData: boolean;
  registryLoading: boolean;
  housesCount: number;
  requestHouseDataStatus: RequestStatusType;
  myHouses: {
    data: HouseType[] | [];
    dataFiltered: HouseType[] | [];
    pagination: PaginationType;
    count: number;
    loading: boolean;
    fetchStatus: typeof statuses[keyof typeof statuses] | "";
    error: string;
  };
}

const initialState: AppState = {
  hasHouseData: false,
  registryLoading: false,
  housesCount: 0,
  requestHouseDataStatus: statuses.pending,
  myHouses: {
    data: [],
    dataFiltered: [],
    pagination: {
      limit: 50,
      pages: 0,
      page: 1,
    },
    count: 0,
    loading: false,
    fetchStatus: "",
    error: "",
  },
};

const fetchHousesDataThunk = createAsyncThunk(
  "occ/get-for-uk-houses",
  async (
    { limit, page = 1 }: { limit: number; page: number },
    { getState, rejectWithValue }
  ) => {
    try {
      const address = localStorage.getItem("address");
      let FiasId: number | null = null;

      if (address) {
        FiasId = JSON.parse(address).FiasId;
      }

      const response = await api.house.getHousesData({
        FiasId,
        limit,
        page,
      });

      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const occSlice = createSlice({
  name: "house",
  initialState,
  reducers: {
    setHouseData: (state, action) => {
      state.housesCount = action.payload?.apartaments?.length;
    },
    resetHouseData: (state) => {
      state.housesCount = 0;
      state.hasHouseData = false;
    },
    startRegistryLoading: (state) => {
      state.registryLoading = !state.registryLoading;
    },
    setRequestHouseDataStatus: (state, action) => {
      state.requestHouseDataStatus = action.payload;
    },
    setMyHouses: (state, action) => {
      state.myHouses.data = action.payload;
      state.myHouses.count = action.payload.length;
    },
    setMyHousesFiltered: (state, action) => {
      state.myHouses.dataFiltered = action.payload;
    },
    resetMyHouses: (state) => {
      state.myHouses.data = [];
      state.myHouses.count = 0;
      state.myHouses.dataFiltered = [];
    },
    setPagination: (state, action: PayloadAction<PaginationType>) => {
      state.myHouses.pagination = action.payload;
    },
  },
  extraReducers: (builder) => {
    // @fetchHousesDataThunk - request
    builder.addCase(fetchHousesDataThunk.pending, (state: any) => {
      state.myHouses.loading = true;
      state.myHouses.fetchStatus = statuses.pending;
      state.myHouses.error = "";
    });
    builder.addCase(fetchHousesDataThunk.fulfilled, (state: any, action) => {
      state.myHouses.loading = false;
      state.myHouses.fetchStatus = statuses.success;

      const myOcc = [...state.myHouses.data, ...action.payload.Data].map(
        (house: MyHouseResponse) => {
          const occ = { ...house, ...house?.Occ };
          delete occ?.Occ;

          return occ;
        }
      );

      state.myHouses.data = myOcc;
      state.myHouses.dataFiltered = [...myOcc];
      state.myHouses.pagination.pages = action.payload.Page.Count;
      state.myHouses.pagination.limit = action.payload.Page.Limit;
      state.myHouses.pagination.page = action.payload.Page.Index;
    });
    builder.addCase(fetchHousesDataThunk.rejected, (state: any, action) => {
      state.myHouses.loading = false;
      state.myHouses.fetchStatus = statuses.reject;

      if (action.payload) {
        state.myHouses.error = action.payload;
      }
    });
  },
});

export { fetchHousesDataThunk };
export const {
  setHouseData,
  startRegistryLoading,
  resetHouseData,
  setRequestHouseDataStatus,
  setMyHouses,
  setMyHousesFiltered,
  resetMyHouses,
  setPagination,
} = occSlice.actions;
export default occSlice.reducer;
