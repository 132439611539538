import { forwardRef } from "react";
import { InputLabel, MenuItem, Select } from "@mui/material";
import { LabelTooltip } from "../../../components/tooltip";

export const MeettingFroms = forwardRef(
  (
    { onChange, name, onBlur, error, defaultValue, data, loading }: any,
    ref
  ) => {
    return (
      <>
        <InputLabel
          htmlFor="MeettingFroms"
          sx={{ display: "flex", gap: "4px" }}
        >
          Форма собрания
          <LabelTooltip
            title={`Форма собрания "Заочная с использованием системы" согласно ст. 47.1 ЖК РФ предполагает от собственников помещений как дистанционный сбор голосов через систему (мобильное приложение "Локоло"), так и сбор письменных решений администратором системы.`}
            placement="right"
            arrow
            enterDelay={700}
          />
        </InputLabel>
        <Select
          id="MeettingFroms"
          fullWidth
          disabled={loading}
          ref={ref}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          error={error}
          defaultValue={defaultValue}
        >
          {data.map((item: any) => (
            <MenuItem key={item.key} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </>
    );
  }
);
