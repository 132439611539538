import wrapModule from "./wrapModule";

import userApi from "./modules/user";
import houseApi from "./modules/house";
import occApi from "./modules/occ";
import dictionariesApi from "./modules/dictionaries";
import documentApi from "./modules/document";
import questionsApi from "./modules/questions";
import contactsApi from "./modules/contacts";
import templateGroupsApi from "./modules/template-groups";
import templateQuestionsApi from "./modules/template-questions";

export const api = {
  user: wrapModule(userApi),
  house: wrapModule(houseApi),
  occ: wrapModule(occApi),
  dictionaries: wrapModule(dictionariesApi),
  document: wrapModule(documentApi),
  questions: wrapModule(questionsApi),
  contacts: wrapModule(contactsApi),
  templateGroups: wrapModule(templateGroupsApi),
  templateQuestions: wrapModule(templateQuestionsApi),
};
