import { OCC } from "@/types";

export const paths = {
  baseUrl: "/",
  authorization: "/auth",
  createOcc: "/occ/create",
  createOccFinish: "/occ/create/finish/:occId",
  myOcc: "/my-occ",
  myOccById(
    occId: OCC.OccOrderBase["Id"] | ":occId" = ":occId",
    ownerId: OCC.RegistryOwner["Id"] | ":ownerId" = ":ownerId"
  ) {
    return {
      index: `/my-occ/${occId}`,
      details: `/my-occ/${occId}/details`,
      results: `/my-occ/${occId}/results`,
      resultsMeeting: `/my-occ/${occId}/results/meeting`,
      resultsQuestions: `/my-occ/${occId}/results/questions`,
      resultsOwners: `/my-occ/${occId}/results/owners`,
      questions: `/my-occ/${occId}/questions`,
      events: `/my-occ/${occId}/events`,
      documents: `/my-occ/${occId}/documents`,
      decision: `/my-occ/${occId}/decision`,
      decisionByOwner: `/my-occ/${occId}/decision/${ownerId}`,
    };
  },
  houses: "/my-houses",
  contacts: "/contacts",
  managementTemplate: "/management-template",
  settings: "/settings",
  documents: "/useful-documents",
  accessSettings: "/access",
  cadastralNumber: "/cadastral-number",
  users: "/users",

  ukRegistry() {
    const root = "/registry";
    return {
      root,
      main: "main",
      rosreestr: "rosreestr",
      analytics: "analytics",

      fullPath() {
        return {
          main: `${this.root}/${this.main}`,
          rosreestr: `${this.root}/${this.rosreestr}`,
          analytics: `${this.root}/${this.analytics}`,
        };
      },
    };
  },

  institution(sntId: string | ":sntId" = ":sntId") {
    const root = "/institution";
    return {
      root,
      map: "map",
      sntsList: "snts",
      analytics: "analytics",
      analyticsStateSupport: "state-support",
      analyticsInfrastructure: "infrastructure",
      sntItem: sntId.toString(),
      sntItemAgricultureMinistry: "main",
      sntItemBoard: "board",
      sntItemSummary: "summary",

      fullPath() {
        return {
          root,
          map: `${root}/${this.map}`,
          sntsList: `${root}/${this.sntsList}`,
          analytics: `${root}/${this.sntsList}/${this.analytics}`,
          analyticsStateSupport: `${root}/${this.sntsList}/${this.analytics}/${this.analyticsStateSupport}`,
          analyticsInfrastructure: `${root}/${this.sntsList}/${this.analytics}/${this.analyticsInfrastructure}`,
          sntItem: `${root}/${this.sntsList}/${sntId}`,
          sntItemAgricultureMinistry: `${root}/${this.sntsList}/${sntId}/${this.sntItemAgricultureMinistry}`,
          sntItemBoard: `${root}/${this.sntsList}/${sntId}/${this.sntItemBoard}`,
          sntItemSummary: `${root}/${this.sntsList}/${sntId}/summary`,
        };
      },
    };
  },

  ukCreate() {},

  snt() {
    const root = "/snt";
    return {
      root,
      passport: {
        root: "passport",
        main: "main",
        minselhoz: "minselhoz",
      },

      fullPath() {
        return {
          passport: {
            root: `${this.root}/${this.passport.root}`,
            main: `${this.root}/${this.passport.root}/${this.passport.main}`,
            minselhoz: `${this.root}/${this.passport.root}/${this.passport.minselhoz}`,
          },
        };
      },
    };
  },
};
