import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface IConfirm {
  createOcc: () => void;
  onClose: () => void;
  open: boolean;
  error: string;
}

export const Confirm = ({ createOcc, onClose, open, error }: IConfirm) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "490px",
          },
        },
      }}
    >
      <DialogTitle>
        <Typography
          fontFamily="GolosMedium"
          sx={{
            width: "350px",
            fontSize: "20px",
            lineHeight: "28px",
            fontWeight: 600,
          }}
        >
          Вы действительно хотите создать и запустить собрание?
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 0 }}>
        <Typography color="secondary">
          Система в выбранную Вами дату разместит сообщение о собрании
          собственников в мобильном приложении Локоло, с последующей
          возможностью голосовать для собственников.
        </Typography>
        <FormHelperText error>{error}</FormHelperText>
      </DialogContent>
      <DialogActions sx={{ padding: "0 24px 24px 16px" }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onClose}
          sx={{
            width: "88px",
            height: "36px",
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          Отмена
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={createOcc}
          autoFocus
          sx={{
            width: "88px",
            height: "36px",
            borderRadius: "10px",
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 500,
          }}
        >
          Создать
        </Button>
      </DialogActions>
    </Dialog>
  );
};
