import { Input, InputAdornment, IconButton } from "@mui/material";

import { Icon } from "../icon";
import clearIcon from "../../assets/icons/cancel.svg";

import styles from "./search.module.scss";
import { searchIcon } from "../../consts/icons";

interface ISearchFieldProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClearField?: () => void;
  placeholder?: string;
  inputRef?: React.Ref<any>;
  style?: React.CSSProperties;
}

const SearchField = ({ value, onChange, onClearField, placeholder, inputRef, style = {} }: ISearchFieldProps) => {
  return (
    <Input
      inputRef={inputRef}
      className={`${styles.search} ${value ? styles.searchActive : undefined}`}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      startAdornment={
        <InputAdornment sx={{ marginRight: "4px" }} position="start">
          {searchIcon}
        </InputAdornment>
      }
      style={style}
      endAdornment={
        value && onClearField ? (
          <InputAdornment position="end" onClick={onClearField}>
            <IconButton edge="end">
              <Icon path={clearIcon} alt="удалить" />
            </IconButton>
          </InputAdornment>
        ) : null
      }
    />
  );
};

export { SearchField };
