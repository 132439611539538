import { axiosInstance } from "./config";
import { IApiModule, ModuleType } from "./types";

export default (module: IApiModule): ModuleType => {
  return Object.entries(module).reduce(
    (acc, [key, getAxiosRequestConfig]) => ({
      ...acc,
      [key]: <P>(args: P) => axiosInstance(getAxiosRequestConfig(args)),
    }),
    {}
  );
};
