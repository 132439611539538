import { paths } from "./routes";
import {
  createIcon,
  myHouseIcon,
  myMeetingsIcon,
  registryIcon,
  contactsIcon,
  landRegistryIcon,
  documentsIcon,
  usersIcon,
  institutionIcon,
  institutionSntsMapIcon,
} from "./icons";
import { Profile } from "@/types";

export type NavLinkType = {
  text: string;
  icon: JSX.Element;
  path: string;
  role: Profile.UserRole;
  type: Profile.CompanyType | null;
};

export const sidebar: NavLinkType[] = [
  // Institution
  {
    text: "Садоводства РТ",
    icon: institutionIcon,
    path: paths.institution().fullPath().sntsList,
    role: Profile.UserRole.Institution,
    type: null,
  },
  {
    text: "Карта РТ",
    icon: institutionSntsMapIcon(),
    path: paths.institution().map,
    role: Profile.UserRole.Institution,
    type: null,
  },
  // UK
  {
    text: "Реестр собственников",
    icon: registryIcon,
    path: paths.ukRegistry().root,
    role: Profile.UserRole.Company,
    type: Profile.CompanyType.UK,
  },
  {
    text: "Мои дома",
    icon: myHouseIcon,
    path: paths.houses,
    role: Profile.UserRole.Company,
    type: Profile.CompanyType.UK,
  },
  {
    text: "Создание собрания",
    icon: createIcon,
    path: "/create-occ",
    role: Profile.UserRole.Company,
    type: Profile.CompanyType.UK,
  },
  {
    text: "Мои собрания",
    icon: myMeetingsIcon,
    path: paths.myOcc,
    role: Profile.UserRole.Company,
    type: Profile.CompanyType.UK,
  },

  // SNT
  {
    text: "Реестр участков",
    icon: landRegistryIcon,
    path: "/snt/registry",
    role: Profile.UserRole.Company,
    type: Profile.CompanyType.SNT,
  },
  {
    text: "Создание собрания",
    icon: createIcon,
    path: "/snt/create-occ",
    role: Profile.UserRole.Company,
    type: Profile.CompanyType.SNT,
  },
  {
    text: "Мои собрания",
    icon: myMeetingsIcon,
    path: paths.myOcc,
    role: Profile.UserRole.Company,
    type: Profile.CompanyType.SNT,
  },

  // common
  {
    text: "Полезные контакты",
    icon: contactsIcon,
    path: paths.contacts,
    role: Profile.UserRole.Company,
    type: null,
  },
  {
    text: "Полезные документы",
    icon: documentsIcon,
    path: paths.documents,
    role: Profile.UserRole.Company,
    type: null,
  },
  {
    text: "Пользователи Локоло",
    icon: usersIcon,
    path: paths.users,
    role: Profile.UserRole.Company,
    type: null,
  },
];
