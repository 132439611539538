import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

interface IBaseTableRowProps {
  children: React.ReactNode;
  className?: string;
  onClick?: React.EventHandler<any>;
}

const BaseTableRow: React.FC<IBaseTableRowProps> = ({ children, className, onClick }) => {
  return (
    <tr className={cx(className, { row: true })} onClick={onClick}>
      {children}
    </tr>
  );
};

export default BaseTableRow;
