export const AuthorFields: any = {
  "0": {
    Surname: "Фамилия",
    Name: "Имя",
    Patronymic: "Отчество",
    RoomAddress: "Адрес помещения",
  },
  "1": {
    Name: "Компания",
    LegalForm: "Организационно - правовая форма",
    OGRN: "ОГРН",
    Location: "Адрес помещения",
  },
  "2": {
    Name: "Управляющая организация",
    UkLegalForm: "Организационно - правовая форма",
    FoundationAgreementDetails:
      "Реквизиты договора основания на наличие права инициировать общее собрание собственников",
    UkOGRN: "ОГРН",
    Phone: "Контактный телефон",
  },
};

export const orderFields: any = {
  Number: "Номер собрания",
  TypeName: "Вид собрания",
  FormName: "Форма собрания",
  LocationName: "Место проведения собрания",
  PublicationDate: "Дата публикации сообщения",
  StartDate: "Дата старта приема решений",
  EndDate: "Дата окончания приема решений",
  FiasId: "Адрес дома",
  ProcedureForFamiliarizationWithMaterialsOfOCC:
    "Порядок ознакомления с материалами собрания",
  ProcedureForMakingWrittenDecisions: "Порядок приема письменных решений",
};

export const AdminFields: any = {
  "0": {
    Surname: "Фамилия",
    Name: "Имя",
    Patronymic: "Отчество",
    RoomAddress: "Адрес помещения",
    OwnershipDocumentDetails: "Место постоянного проживания",
    Phone: "Контактный телефон",
    Email: "Электронная почта",
    Series: "Серия",
    Number: "Номер",
    IssueDate: "Дата выдачи",
    DepartmentCode: "Код подразделения",
    IssuedBy: "Кем выдан",
  },
  "1": {
    Name: "Компания",
    LegalForm: "Организационно - правовая форма",
    Location: "Место нахождения",
    MailingAddress: "Почтовый адрес",
    Phone: "Контактный телефон",
    Site: "Сайт",
  },
  "2": {
    Name: "Управляющая организация",
    LegalForm: "Организационно - правовая форма",
    FoundationAgreementDetails:
      "Реквизиты договора основания на наличие права инициировать общее собрание собственников",
    OGRN: "ОГРН",
    Phone: "Контактный телефон",
  },
};

export const UKContacts: {
  [key: string]: string;
} = {
  Name: "Полное наименование компании",
  Address: "Адрес",
  Phone: "Телефон",
  Site: "Сайт",
  Email: "Электронная почта",
};
