interface IIconProps {
  path: string;
  alt?: string;
  [key: string]: any;
}

const Icon = ({ path, alt, ...props }: IIconProps) => (
  <img src={path} alt={alt} {...props} />
);

export { Icon };
