import { IApiModule, AuthRequest } from "../types";

export default {
  login: ({ email, password }: AuthRequest) => ({
    method: "POST",
    url: "api/auth/login",
    data: {
      email,
      password,
    },
  }),
  logout: () => ({
    method: "POST",
    url: "api/auth/logout",
  }),
  getUser: () => ({
    method: "GET",
    url: "api/user",
  }),
  getData: () => ({
    method: "GET",
    url: "api/user/company/get-data",
  }),
  setAddress: (FiasId) => ({
    method: "POST",
    url: "api/user/active-address",
    data: {
      FiasId,
    },
  }),
  getAddress: () => ({
    method: "GET",
    url: "api/user/active-address",
  }),
} as IApiModule;
