import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TemplateGroups } from "../../../types";
import { api } from "../../../services/api";
import { setAddTemplateGroupModal, setDeleteGroupModal, setEditTemplateGroupModal } from "./modal";

interface IGroupsState {
  groups: {
    data: Array<TemplateGroups.GroupQuestionsResponse>;
    loading: boolean;
    error: string;
  };
  createGroup: {
    loading: boolean;
    error: string;
  };
  deleteGroup: {
    loading: boolean;
    error: string;
  };
  updateGroup: {
    loading: boolean;
    error: string;
  };
}

const initialState: IGroupsState = {
  groups: {
    data: [],
    loading: false,
    error: "",
  },
  createGroup: {
    loading: false,
    error: "",
  },
  deleteGroup: {
    loading: false,
    error: "",
  },
  updateGroup: {
    loading: false,
    error: "",
  },
};

const fetchTemplateGroups = createAsyncThunk(
  "template-groups/groups/get",
  async (data: undefined, { rejectWithValue }) => {
    try {
      const response = await api.templateGroups.getGroups();

      return response.data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchTemplateGroupCreate = createAsyncThunk(
  "template-groups/groups/create",
  async (data: TemplateGroups.GroupQuestionsCreate, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.templateGroups.addGroup(data);
      dispatch(setAddTemplateGroupModal(false));

      return response.data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchTemplateGroupUpdate = createAsyncThunk(
  "template-groups/groups/update",
  async (data: TemplateGroups.GroupQuestionsCreate & { id: number }, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.templateGroups.updateGroup({
        id: data.id,
        data: { Title: data.Title },
      });
      dispatch(setEditTemplateGroupModal(false));

      return response.data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchTemplateGroupDelete = createAsyncThunk(
  "template-groups/groups/delete",
  async (data: { id: number }, { rejectWithValue, dispatch }) => {
    try {
      await api.templateGroups.deleteGroup({ id: data.id });
      dispatch(setDeleteGroupModal(false));

      return data.id;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const groupsTemplate = createSlice({
  name: "groups-template",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // @fetchTemplateGroups - request
    builder.addCase(fetchTemplateGroups.pending, (state: IGroupsState) => {
      state.groups.loading = true;
      state.groups.error = "";
    });
    builder.addCase(fetchTemplateGroups.fulfilled, (state: IGroupsState, action: PayloadAction<any>) => {
      state.groups.loading = false;
      state.groups.data = action.payload;
    });
    builder.addCase(fetchTemplateGroups.rejected, (state: IGroupsState, action: PayloadAction<any>) => {
      state.groups.loading = false;

      if (action.payload) {
        state.groups.error = action.payload;
      }
    });

    // @fetchTemplateGroupCreate - request
    builder.addCase(fetchTemplateGroupCreate.pending, (state: IGroupsState) => {
      state.createGroup.loading = true;
      state.createGroup.error = "";
    });
    builder.addCase(fetchTemplateGroupCreate.fulfilled, (state: IGroupsState, action: PayloadAction<any>) => {
      state.createGroup.loading = false;

      state.groups.data = [action.payload, ...state.groups.data];
    });
    builder.addCase(fetchTemplateGroupCreate.rejected, (state: IGroupsState, action: PayloadAction<any>) => {
      state.createGroup.loading = false;

      if (action.payload) {
        state.createGroup.error = action.payload;
      }
    });

    // @fetchTemplateGroupUpdate - request
    builder.addCase(fetchTemplateGroupUpdate.pending, (state: IGroupsState) => {
      state.updateGroup.loading = true;
      state.updateGroup.error = "";
    });
    builder.addCase(fetchTemplateGroupUpdate.fulfilled, (state: IGroupsState, action: PayloadAction<any>) => {
      state.updateGroup.loading = false;

      state.groups.data = state.groups.data.map((group: TemplateGroups.GroupQuestionsResponse) =>
        group.Id === action.payload.Id ? { ...action.payload } : group
      );
    });
    builder.addCase(fetchTemplateGroupUpdate.rejected, (state: IGroupsState, action: PayloadAction<any>) => {
      state.updateGroup.loading = false;

      if (action.payload) {
        state.updateGroup.error = action.payload;
      }
    });

    // @fetchTemplateGroupDelete - request
    builder.addCase(fetchTemplateGroupDelete.pending, (state: IGroupsState) => {
      state.deleteGroup.loading = true;
      state.deleteGroup.error = "";
    });
    builder.addCase(fetchTemplateGroupDelete.fulfilled, (state: IGroupsState, action: PayloadAction<any>) => {
      state.deleteGroup.loading = false;

      state.groups.data = state.groups.data.filter(
        (group: TemplateGroups.GroupQuestionsResponse) => group.Id !== action.payload
      );
    });
    builder.addCase(fetchTemplateGroupDelete.rejected, (state: IGroupsState, action: PayloadAction<any>) => {
      state.deleteGroup.loading = false;

      if (action.payload) {
        state.deleteGroup.error = action.payload;
      }
    });
  },
});

export { fetchTemplateGroups, fetchTemplateGroupCreate, fetchTemplateGroupDelete, fetchTemplateGroupUpdate };
export default groupsTemplate.reducer;
