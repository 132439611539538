import { useMemo } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { Content } from "@/components/content";
import { Layout } from "@/components/layout";
import { paths } from "@/consts/routes";

const InstitutionLayout: React.FC = () => {
  const { pathname } = useLocation();
  const urlParams = useParams();

  const style: React.CSSProperties = useMemo(() => {
    return !pathname.startsWith(paths.institution().fullPath().analytics) &&
      pathname !== paths.institution(urlParams.sntId).fullPath().sntItemSummary
      ? { backgroundColor: "white" }
      : { padding: "0" };
  }, [pathname, urlParams.sntId]);

  return (
    <Layout>
      <Content style={style}>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default InstitutionLayout;
