import { forwardRef } from "react";
import { InputLabel, MenuItem, Select } from "@mui/material";

export const Location = forwardRef(
  (
    { onChange, name, onBlur, error, defaultValue, loading, data }: any,
    ref
  ) => {
    return (
      <>
        <InputLabel htmlFor="MeettingType">
          Место проведения собрания
        </InputLabel>
        <Select
          name={name}
          id="MeettingType"
          fullWidth
          disabled={loading}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          error={error}
          defaultValue={defaultValue}
        >
          {data.map((item: any) => (
            <MenuItem key={item.key} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </>
    );
  }
);
