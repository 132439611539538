import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { statuses } from "../../../consts/statuses";
import { api } from "../../../services/api";
import { Profile } from "../../../types";

type AddressType = {
  FiasId: string;
  Address: string;
  GeoPoint: { [key: string]: string };
};

type UserType = {
  data: {
    id: number;
    name: string;
    email: string;
    api_user_id: number;
    company_id: number;
    active_address: string;
  } | null;
  common: Profile.CompanyData | null;
  status: string | null;
  error: string;
  managmentCompany: string;
  addresses: AddressType[];
  isAuthorization: boolean;
};

type QuorumsType = {
  id: number;
  key: string;
  name: string;
};

interface AppState {
  user: UserType;
  sanctumCookie: boolean;
  quorums: {
    data: Array<QuorumsType>;
    loading: boolean;
    fetchStatus: typeof statuses[keyof typeof statuses] | "";
    error: string;
  };
  setAddress: {
    loading: boolean;
    fetchStatus: typeof statuses[keyof typeof statuses] | "";
    error: string;
  };
  activeAddress: {
    data: string;
    loading: boolean;
    fetchStatus: typeof statuses[keyof typeof statuses] | "";
    error: string;
  };
}

const initialState: AppState = {
  user: {
    data: null,
    common: null,
    status: null,
    error: "",
    managmentCompany: "",
    addresses: [],
    isAuthorization: false,
  },
  sanctumCookie: false,
  quorums: { data: [], loading: false, fetchStatus: "", error: "" },
  setAddress: { loading: false, fetchStatus: "", error: "" },
  activeAddress: {
    data: "",
    loading: false,
    fetchStatus: "",
    error: "",
  },
};

// TODO: При рефакторинге удалить fetchQuorum в Occ
const fetchQuorums = createAsyncThunk(
  "occ/get-quorum",
  async (data: undefined, { rejectWithValue }) => {
    try {
      const response = await api.occ.getQuorum();

      return response.data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchSetAddress = createAsyncThunk(
  "user/active-address/set",
  async (address: string, { rejectWithValue }) => {
    try {
      const response = await api.user.setAddress(address);

      return address;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchAddressActive = createAsyncThunk(
  "user/active-address/get",
  async (data: undefined, { rejectWithValue }) => {
    try {
      const response = await api.user.getAddress();

      return response.data.Data.ActiveAddress;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user.data = action.payload;
    },
    setAuthorization: (state, action) => {
      state.user.isAuthorization = action.payload;
    },
    setCookie: (state) => {
      state.sanctumCookie = true;
    },
    logout: (state) => {
      state.user.data = null;
      state.activeAddress = initialState.activeAddress;
    },
    setDataManagmentCompany: (state, action) => {
      state.user.managmentCompany = action.payload.Name;
      state.user.addresses = action.payload.Addresses;
    },
    setCommonData: (state, action) => {
      state.user.common = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetchQuorums - request
    builder.addCase(fetchQuorums.pending, (state: any) => {
      state.quorums.loading = true;
      state.quorums.fetchStatus = statuses.pending;
      state.quorums.error = "";
    });
    builder.addCase(fetchQuorums.fulfilled, (state: any, action) => {
      state.quorums.loading = false;
      state.quorums.fetchStatus = statuses.success;
      state.quorums.data = action.payload;
    });
    builder.addCase(fetchQuorums.rejected, (state: any, action) => {
      state.quorums.loading = false;
      state.quorums.fetchStatus = statuses.reject;

      if (action.payload) {
        state.quorums.error = action.payload;
      }
    });

    // fetchSetAddress - request
    builder.addCase(fetchSetAddress.pending, (state: any) => {
      state.setAddress.loading = true;
      state.setAddress.fetchStatus = statuses.pending;
      state.setAddress.error = "";
    });
    builder.addCase(fetchSetAddress.fulfilled, (state: any, action) => {
      state.setAddress.loading = false;
      state.setAddress.fetchStatus = statuses.success;
      state.setAddress.data = action.payload;
      state.activeAddress.data = action.payload;
    });
    builder.addCase(fetchSetAddress.rejected, (state: any, action) => {
      state.setAddress.loading = false;
      state.setAddress.fetchStatus = statuses.reject;

      if (action.payload) {
        state.setAddress.error = action.payload;
      }
    });

    // fetchAddressActive - request
    builder.addCase(fetchAddressActive.pending, (state: any) => {
      state.activeAddress.loading = true;
      state.activeAddress.fetchStatus = statuses.pending;
      state.activeAddress.error = "";
    });
    builder.addCase(fetchAddressActive.fulfilled, (state: any, action) => {
      state.activeAddress.loading = false;
      state.activeAddress.fetchStatus = statuses.success;
      state.activeAddress.data = action.payload;
    });
    builder.addCase(fetchAddressActive.rejected, (state: any, action) => {
      state.activeAddress.loading = false;
      state.activeAddress.fetchStatus = statuses.reject;

      if (action.payload) {
        state.activeAddress.error = action.payload;
      }
    });
  },
});

export { fetchQuorums, fetchSetAddress, fetchAddressActive };
export const {
  logout,
  setUser,
  setAuthorization,
  setCookie,
  setDataManagmentCompany,
  setCommonData,
} = appSlice.actions;
export default appSlice.reducer;
