import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Typography, Stack, Skeleton, Backdrop, CircularProgress, Snackbar, Alert } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import styles from "../create.module.scss";
import { DraftItem } from "../components/draftItem/draftItem";
import { RootState, useAppDispatch } from "../../../app/store";
import { clearDraftError, fetchDeleteDraft, fetchDraft } from "../../../app/store/slices/createOccOld";
import { setSelectAddressModal } from "../../../app/store/slices/modal";
import { activeAddress } from "@/app/store/slices/user";
import { AddressModal } from "../components/addressModal.tsx";
import { paths } from "../../../consts/routes";
import { useNavigate } from "react-router";

const Step0 = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data, loading, error, loadingDelete } = useSelector((state: RootState) => state.createOccOld.draft);
  const address = useSelector(activeAddress);
  const modalState = useSelector((state: RootState) => state.modal.selectAddress);
  const hasRegistry: boolean = useSelector((state: RootState) => state.registry.houses.hasHousesData);

  const addressStorage = localStorage.getItem("address");

  let FiasId = "";

  if (address) {
    FiasId = addressStorage && JSON.parse(addressStorage).FiasId;
  }

  const handleDelete = (occId: number) => {
    dispatch(fetchDeleteDraft({ OccId: occId, getDraft: fetchDraft }));
  };

  const handleCloseError = () => {
    dispatch(clearDraftError());
  };

  const handleCloseModal = () => {
    dispatch(setSelectAddressModal(false));
  };

  const createOCC = () => {
    dispatch(setSelectAddressModal(true));
  };

  const handleNavigateToTemplate = () => {
    navigate(paths.managementTemplate);
  };

  useEffect(() => {
    dispatch(fetchDraft());
  }, [address]);

  useEffect(() => {
    return () => {
      dispatch(setSelectAddressModal(false));
    };
  }, []);

  return (
    <section className={styles.root}>
      <div className={styles.control}>
        <Button variant="contained" className={styles.button} onClick={createOCC}>
          <AddIcon sx={{ marginRight: "10px" }} />
          Перейти к созданию собрания
        </Button>
        <Button variant="contained" className={styles.button} onClick={handleNavigateToTemplate}>
          Управление шаблонами
        </Button>
      </div>
      <div className={styles.draft}>
        <Typography variant="h4" fontFamily="GolosMedium" className={styles.draft_title}>
          Черновики
        </Typography>
        {loading ? (
          <>
            <Skeleton height={64} />
            <Skeleton height={64} />
          </>
        ) : (
          <Stack spacing={2}>
            {data.map((data) => (
              <DraftItem key={data.Id} data={data} onDelete={handleDelete} />
            ))}
          </Stack>
        )}
        <Backdrop
          open={loadingDelete}
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            position: "absolute",
            backgroundColor: "rgb(46 57 152 / 33%)",
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Snackbar
        open={!!error}
        autoHideDuration={3000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseError} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
      {address && <AddressModal open={modalState.open} onClose={handleCloseModal} selected={address} />}
    </section>
  );
};

export { Step0 };
