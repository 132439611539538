import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../services/api";
import { TemplateQuestions } from "../../../types/template-questions";
import { setAddTemplateQuestionModal, setDeleteTemplateQuestionModal, setEditTemplateQuestionModal } from "./modal";

interface IQuestionsState {
  groupQuestions: {
    data: { [key: string]: Array<TemplateQuestions.QuestionResponse> };
    loading: boolean;
    error: string;
  };
  createQuestion: {
    loading: boolean;
    error: "";
  };
  deleteQuestion: {
    loading: boolean;
    error: "";
  };
  updateQuestion: {
    loading: boolean;
    error: "";
  };
}

const initialState: IQuestionsState = {
  groupQuestions: {
    data: {},
    loading: false,
    error: "",
  },
  createQuestion: {
    loading: false,
    error: "",
  },
  deleteQuestion: {
    loading: false,
    error: "",
  },
  updateQuestion: {
    loading: false,
    error: "",
  },
};

const fetchTemplateQuestions = createAsyncThunk(
  "template-questions/questions/get",
  async (data: TemplateQuestions.RequestListQuestions, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();
      const questions = state.templateQuestions.groupQuestions.data;

      if (Object.keys(questions).some((key: string) => key === String(data.GroupId))) {
        return state.templateQuestions.groupQuestions.data;
      }

      const response = await api.templateQuestions.getQuestions({
        GroupId: data.GroupId,
        Title: data.Title,
      });

      return { data: response.data.Data, groupId: data.GroupId };
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchCreateTemplateQuestion = createAsyncThunk(
  "template-questions/questions/create",
  async (data: TemplateQuestions.RequestBodyCreate, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.templateQuestions.addQuestion(data);
      dispatch(setAddTemplateQuestionModal(false));

      return response.data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchUpdateTemplateQuestion = createAsyncThunk(
  "template-questions/questions/update",
  async (data: TemplateQuestions.RequestBodyCreate & { id: number }, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.templateQuestions.updateQuestions({
        id: data.id,
        data: { Title: data.Title, Value: data.Value, Order: data.Order, QuorumId: data.QuorumId },
      });

      dispatch(setEditTemplateQuestionModal(false));

      return response.data.Data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const fetchDeleteTemplateQuestion = createAsyncThunk(
  "template-questions/questions/delete",
  async (data: { id: number; groupId: number }, { rejectWithValue, dispatch }) => {
    try {
      await api.templateQuestions.deleteQuestion({ id: data.id });
      dispatch(setDeleteTemplateQuestionModal(false));

      return { id: data.id, groupId: data.groupId };
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.Data?.Message);
    }
  }
);

const questionsTemplate = createSlice({
  name: "template-questions",
  initialState,
  reducers: {
    clearErrorDeleteQuestion: (state: IQuestionsState) => {
      state.deleteQuestion.error = "";
    },
  },
  extraReducers: (builder) => {
    // @fetchTemplateQuestions - request
    builder.addCase(fetchTemplateQuestions.pending, (state: IQuestionsState) => {
      state.groupQuestions.loading = true;
      state.groupQuestions.error = "";
    });
    builder.addCase(fetchTemplateQuestions.fulfilled, (state: IQuestionsState, action: PayloadAction<any>) => {
      state.groupQuestions.loading = false;
      state.groupQuestions.data[action.payload.groupId] = action.payload.data;
    });
    builder.addCase(fetchTemplateQuestions.rejected, (state: IQuestionsState, action: PayloadAction<any>) => {
      state.groupQuestions.loading = false;

      if (action.payload) {
        state.groupQuestions.error = action.payload;
      }
    });

    // @fetchCreateTemplateQuestion - request
    builder.addCase(fetchCreateTemplateQuestion.pending, (state: IQuestionsState) => {
      state.createQuestion.loading = true;
      state.createQuestion.error = "";
    });
    builder.addCase(fetchCreateTemplateQuestion.fulfilled, (state: IQuestionsState, action: PayloadAction<any>) => {
      state.createQuestion.loading = false;

      state.groupQuestions.data[action.payload.GroupId] = [
        ...state.groupQuestions.data[action.payload.GroupId],
        action.payload,
      ];
    });
    builder.addCase(fetchCreateTemplateQuestion.rejected, (state: IQuestionsState, action: PayloadAction<any>) => {
      state.createQuestion.loading = false;

      if (action.payload) {
        state.createQuestion.error = action.payload;
      }
    });

    // @fetchDeleteTemplateQuestion - request
    builder.addCase(fetchDeleteTemplateQuestion.pending, (state: IQuestionsState) => {
      state.deleteQuestion.loading = true;
      state.deleteQuestion.error = "";
    });
    builder.addCase(fetchDeleteTemplateQuestion.fulfilled, (state: IQuestionsState, action: PayloadAction<any>) => {
      state.deleteQuestion.loading = false;

      state.groupQuestions.data[action.payload.groupId] = state.groupQuestions.data[action.payload.groupId].filter(
        (question: TemplateQuestions.QuestionResponse) => question.Id !== action.payload.id
      );
    });
    builder.addCase(fetchDeleteTemplateQuestion.rejected, (state: IQuestionsState, action: PayloadAction<any>) => {
      state.deleteQuestion.loading = false;

      if (action.payload) {
        state.deleteQuestion.error = action.payload;
      }
    });

    // @fetchUpdateTemplateQuestion - request
    builder.addCase(fetchUpdateTemplateQuestion.pending, (state: IQuestionsState) => {
      state.updateQuestion.loading = true;
      state.updateQuestion.error = "";
    });
    builder.addCase(fetchUpdateTemplateQuestion.fulfilled, (state: IQuestionsState, action: PayloadAction<any>) => {
      state.updateQuestion.loading = false;

      const groupId = action.payload.GroupId;

      state.groupQuestions.data[groupId] = [
        ...state.groupQuestions.data[groupId].filter(
          (question: TemplateQuestions.QuestionResponse) => question.Id !== action.payload.Id
        ),
        { ...action.payload },
      ];
    });
    builder.addCase(fetchUpdateTemplateQuestion.rejected, (state: IQuestionsState, action: PayloadAction<any>) => {
      state.updateQuestion.loading = false;

      if (action.payload) {
        state.updateQuestion.error = action.payload;
      }
    });
  },
});

export {
  fetchTemplateQuestions,
  fetchCreateTemplateQuestion,
  fetchDeleteTemplateQuestion,
  fetchUpdateTemplateQuestion,
};
export const { clearErrorDeleteQuestion } = questionsTemplate.actions;
export default questionsTemplate.reducer;
