import { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { closeIcon } from "@/consts/icons";
import styles from "./styles.module.scss";

interface ISystemMessageProps {
  name: string;
  children: JSX.Element;
  isOpen: boolean;
  date?: string;
  onClose?: () => void;
}

const SystemMessage: React.FC<ISystemMessageProps> = ({ name, children, isOpen, date, onClose }) => {
  const storageName = "hidden--system-messages";

  const nodeRef = useRef<HTMLDivElement>(null);

  function hideMessage() {
    const stored = localStorage.getItem(storageName);
    const list = stored ? (JSON.parse(stored) as Array<string>) : [];
    list.push(name);
    localStorage.setItem(storageName, JSON.stringify(list));

    if (onClose) {
      onClose();
    }
  }

  const storedMessages = localStorage.getItem(storageName);
  if (storedMessages && JSON.parse(storedMessages).includes(name)) {
    return null;
  }

  return (
    <CSSTransition in={isOpen} nodeRef={nodeRef} timeout={500} classNames={{ ...styles }} unmountOnExit>
      <div ref={nodeRef} className={styles.base}>
        <div>
          <div className={styles.message}>
            <span className={styles.close} role="button" onClick={hideMessage}>
              {closeIcon("#898D93")}
            </span>
            <div className={styles.header}>
              {messageIcon}
              <div className={styles.header_details}>
                <span className="text--primary sf-text-semibold color--text-primary">Сообщение:</span>
                <span className={styles.stick}></span>
                <span className="text--primary sf-text-semibold color--text-primary">от Локоло</span>
                {date && <span className="text--primary sf-text-regular color--text-secondary">{date}</span>}
              </div>
            </div>

            <div className={styles.content}>{children}</div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default SystemMessage;

const messageIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M7 10C7 9.44771 7.44772 9 8 9H16C16.5523 9 17 9.44771 17 10C17 10.5523 16.5523 11 16 11H8C7.44772 11 7 10.5523 7 10Z"
      fill="#226DFF"
    />
    <path
      d="M8 14C7.44772 14 7 14.4477 7 15C7 15.5523 7.44772 16 8 16H12C12.5523 16 13 15.5523 13 15C13 14.4477 12.5523 14 12 14H8Z"
      fill="#226DFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1C5.92487 1 1 5.92487 1 12V23H12C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 21H12C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12V21Z"
      fill="#226DFF"
    />
  </svg>
);
