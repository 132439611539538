import { Content } from "../../components/content";
import { Layout } from "../../components/layout";
import { Finish } from "../../features/createOcc/steps/finish";
import { VariantEnum } from "../../types/enum";

const CreateOcc = () => {
  return (
    <>
      <Layout variant={VariantEnum.create}>
        <Content>
          <Finish />
        </Content>
      </Layout>
    </>
  );
};

export default CreateOcc;
