import { TemplateGroups } from "../../../types";
import { IApiModule } from "../types";

export default {
  deleteGroup: ({ id }: { id: number }) => ({
    method: "DELETE",
    url: `api/occ/templates/groups/${id}`,
  }),
  getGroups: () => ({
    method: "GET",
    url: "api/occ/templates/groups",
  }),
  addGroup: (data: TemplateGroups.GroupQuestionsCreate) => ({
    method: "POST",
    url: "api/occ/templates/groups",
    data,
  }),
  updateGroup: ({ id, data }: { id: number; data: TemplateGroups.GroupQuestionsCreate }) => ({
    method: "PATCH",
    url: `api/occ/templates/groups/${id}`,
    data,
  }),
} as IApiModule;
