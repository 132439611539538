import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { Step0 } from "./steps/step0";
import { Steps } from "./steps";
import styles from "./create.module.scss";

const CreateOccComponent = () => {
  const { step } = useParams<"step">();
  let content = <Step0 />;

  if (step && !isNaN(Number(step))) {
    content = <Steps step={Number(step)} />;
  }

  return (
    <>
      <section className={styles.root}>
        {!step ? (
          <>
            <Typography variant="h2" className={styles.title}>
              Создание общего собрания собственников
            </Typography>
            <Typography variant="subtitle1" className={styles.subtitle}>
              Чтобы создать и опубликовать сообщение о проведении собрания вам
              необходимо последовательно пройти 5 простых шагов
            </Typography>
          </>
        ) : (
          <Typography variant="h2" className={`${styles.title} ${styles.step}`}>
            Создание общего собрания собственников
          </Typography>
        )}
      </section>
      {content}
    </>
  );
};

export { CreateOccComponent };
